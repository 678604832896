import React, { useState } from 'react'
import './QuestionTab.css'
import { InfoTab } from 'react-ui-scaffold'
import { RecoSpecsI, Question } from '../questionsConfig'

interface QuestionTabProps {
	tabID: string
	questionInfo: any
	specs: any
	setSpecs(specs: RecoSpecsI): void
	updateResponseMap: (response: string) => void
	showDisclaimer(value: boolean): void
	shiftActiveTab(): void
}

export default function QuestionTab({
	tabID,
	questionInfo,
	updateResponseMap,
	specs,
	setSpecs,
	showDisclaimer,
	shiftActiveTab,
}: QuestionTabProps) {
	const [selectedOption, setSelectedOption] = useState('')
	const { question, options } = questionInfo

	const updateSpecs = (response: string, updates: any) => {
		let radiusUpdate = updates.radiusValue || 0
		let hollowUpdate = updates.hollowValue || 0

		// revert the changes of the currently selected option
		if (selectedOption) {
			const oldUpdate = options[selectedOption]
			radiusUpdate -= oldUpdate.radiusValue || 0
			hollowUpdate -= oldUpdate.hollowValue || 0
		}

		setSpecs({
			radiusValue: specs.radiusValue + radiusUpdate,
			hollowValue: specs.hollowValue + hollowUpdate,
		})
		setSelectedOption(response)
		updateResponseMap(response)
		shiftActiveTab()
	}

	return (
		<InfoTab className="QuestionTab" tabID={tabID} key={tabID}>
			<span>{question}</span>
			{Object.entries(options).map(([option, updates]) => {
				const isSelected = option === selectedOption
				return (
					<div
						key={option}
						className={`option hemi ${isSelected ? 'selected' : ''}`}
						onClick={() => updateSpecs(option, updates)}
					>
						<input type="radio" className="input radio" checked={isSelected} />
						{option}
					</div>
				)
			})}
			{tabID === Question.BiteGlide && (
				<button
					className="button neutral submit"
					onClick={() => showDisclaimer(true)}
				>
					Get Recommended Specs
				</button>
			)}
		</InfoTab>
	)
}
