import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'
import Notification from '../../@types/Notification'
import { State } from '../store'

interface NotificationState {
	list: Notification[]
	loading?: boolean
}

export const fetchNotifications = createAsyncThunk(
	'notifications/fetch',
	async () => {
		const fetchRes = await beam.get('/api/notifications').catch((err) => {
			return Promise.reject(err)
		})

		return {
			list: fetchRes.response,
		}
	}
)

export const readNotifications = createAsyncThunk(
	'notifications/update',
	async () => {
		await beam.post('/api/notifications').catch((err) => {
			return Promise.reject(err)
		})
	}
)

// For testing only
export const addNotification = createAsyncThunk(
	'notifications/add',
	async (notification: Notification, thunk: any) => {
		const newNotifications = [...thunk.getState().notifications.list]

		const postRes = await beam
			.post('/test/notifications', { ...notification })
			.catch(() => {
				return Promise.reject('Unable to create notifications!')
			})

		const newNotif = postRes.response
		newNotifications.push({
			playerEmail: newNotif.playerEmail,
			title: newNotif.title,
			body: newNotif.body,
			actionURL: newNotif.actionURL,
			type: newNotif.type,
			actionText: newNotif.actionText,
			createdDate: newNotif.createdDate,
			readDate: newNotif.readDate,
			notificationID: newNotif.notificationID,
		})

		return {
			list: newNotifications,
		}
	}
)

export const clearNotification = createAsyncThunk(
	'notification/delete',
	async (notificationID: number, thunk: any) => {
		const deletedNotification = await beam
			.post('/api/clearNotification', { notificationID })
			.catch((err) => {
				return Promise.reject(err)
			})
		const remainingNotifications = [
			...thunk.getState().notifications.list,
		].filter(
			(notif) =>
				notif.notificationID !== deletedNotification.response.notificationID
		)

		return {
			list: remainingNotifications,
		}
	}
)

export const clearAllNotifications = createAsyncThunk(
	'notifications/delete',
	async () => {
		await beam.post('/api/clearAllNotifications').catch((err) => {
			return Promise.reject(err)
		})

		return {
			list: [],
		}
	}
)

const notificationSlice = createSlice({
	name: 'orders',
	initialState: {
		list: [],
		loading: true,
	},
	reducers: {
		//clear notifications
		clearNotifications: (state: NotificationState) => {
			state.list = []
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchNotifications.pending, (state: NotificationState) => {
			state.loading = true
		})
		builder.addCase(fetchNotifications.rejected, (state: NotificationState) => {
			state.loading = false
		})
		builder.addCase(
			fetchNotifications.fulfilled,
			(
				state: NotificationState,
				{ payload }: { payload: NotificationState }
			) => {
				state.loading = false
				state.list = payload.list
			}
		)
		builder.addCase(
			addNotification.fulfilled,
			(
				state: NotificationState,
				{ payload }: { payload: NotificationState }
			) => {
				state.loading = false
				state.list = payload.list
			}
		)
		builder.addCase(
			clearNotification.fulfilled,
			(
				state: NotificationState,
				{ payload }: { payload: NotificationState }
			) => {
				state.loading = false
				state.list = payload.list
			}
		)
		builder.addCase(
			clearAllNotifications.fulfilled,
			(
				state: NotificationState,
				{ payload }: { payload: NotificationState }
			) => {
				state.loading = false
				state.list = payload.list
			}
		)
	},
})

export const unreadNotificationsSelector = createSelector(
	(s: State) => s.notifications.list,
	(notifs: Notification[]) => notifs.filter((n: any) => !n.readDate)
)

export const { clearNotifications } = notificationSlice.actions
export default notificationSlice.reducer
