import React, { useEffect, useMemo, useState } from 'react'
import './CustomSpecs.css'
import { useDispatch, useSelector } from 'react-redux'
import { CartItem, updateCartItem } from '../../../Redux/Slices/cartSlice'
import { SpecTab } from '../ChooseSpecsPopup'
import {
	PresetOption,
	presetSelector,
	SpecType,
} from '../../../Redux/Slices/optionsSlice'

interface CustomSpecsProps {
	setVisible: (visible: boolean) => void
	cartItem: CartItem
	showRecoSpecs: (recoSpecsVisible: boolean) => void
	currentSpecType: string
}

export default function CustomSpecs({
	cartItem,
	setVisible,
	showRecoSpecs,
	currentSpecType,
}: CustomSpecsProps) {
	const [advancedNotes, setAdvancedNotes] = useState('')
	const dispatch = useDispatch()
	const presetList = useSelector(presetSelector)

	useEffect(() => {
		setAdvancedNotes(cartItem.advancedNotes || '')
	}, [cartItem])

	const defaultPreset = useMemo(() => {
		return presetList.find((preset: PresetOption) => preset.default)
	}, [presetList])

	const submitNotes = () => {
		if (advancedNotes) {
			dispatch(
				updateCartItem({
					id: cartItem.id,
					updates: {
						advancedNotes,
						specType: SpecType.Custom,
						presetID: null,
					},
				})
			)
		} else if (currentSpecType == SpecTab.Custom) {
			const { name, hollowValue, radiusValue, pitchValue } = defaultPreset!
			dispatch(
				updateCartItem({
					id: cartItem.id,
					updates: {
						advancedNotes,
						specType: name,
						hollowValue,
						radiusValue,
						pitchValue,
						presetID: null,
					},
				})
			)
		}
		setVisible(false)
	}

	return (
		<div className="CustomSpecs">
			<p className="instructionText">
				Please type your custom specs in the text field below. Feel free to add
				any additional comments.
			</p>

			<div className="reqContainer">
				<textarea
					className="input wide"
					placeholder="Type your spec requirements here"
					onChange={(e: any) => setAdvancedNotes(e.target.value)}
					value={advancedNotes}
				></textarea>
			</div>

			<div className="options">
				<button
					className="button neutral hollow RecoSpecsButton"
					onClick={() => showRecoSpecs(true)}
				>
					Recommend Specs
				</button>
				<button className="button DoneButton" onClick={submitNotes}>
					Done
				</button>
			</div>
		</div>
	)
}
