import React from 'react'
import Footer from '../../Components/Footer/Footer'
import LoggedInOnly from '../../Components/LoggedInOnly/LoggedInOnly'
import { Page } from 'react-ui-scaffold'
import Banner from './Support/Banner/Banner'
import './Dashboard.css'
import UpcomingOrdersWidget from './Support/UpcomingOrdersWidget/UpcomingOrdersWidget'
import PresetsWidget from './Support/PresetsWidget/PresetsWidget'
// import MostFrequent from './Support/MostFrequent/MostFrequent'
// import SharpenSoon from './Support/SharpenSoon/SharpenSoon'
import NotificationsWidget from './Support/NotificationsWidget/NotificationsWidget'
import MainContainer from '../../Components/MainContainer/MainContainer'

export default function Dashboard() {
	return (
		<Page className="Dashboard">
			<Banner />

			<LoggedInOnly>
				<MainContainer>
					<div className="userDash">
						<div className="DashColumn">
							<UpcomingOrdersWidget />
							<PresetsWidget />
						</div>
						<div className="DashColumn">
							<NotificationsWidget />
						</div>
					</div>
				</MainContainer>
			</LoggedInOnly>

			<Footer />
		</Page>
	)
}
