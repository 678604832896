import React from 'react'
import './PostOrder.css'

import PostOrderStep from './PostOrderStep/PostOrderStep'

import Footer from '../../Components/Footer/Footer'
import { useSelector } from 'react-redux'

import instructions2a from './../../Assets/photos/instructions-2a.jpg'
import instructions2b from './../../Assets/photos/instructions-2b.jpg'
import instructions2c from './../../Assets/photos/instructions-2c.png'
import instructions3a from './../../Assets/photos/instructions-3a.gif'
import instructions3b from './../../Assets/photos/instructions-3b.jpg'
import instructions3c from './../../Assets/photos/instructions-3c.png'
import instructions4a from './../../Assets/photos/instructions-4a.gif'
import instructions4b from './../../Assets/photos/instructions-4b.gif'
import instructions5a from './../../Assets/photos/instructions-5a.gif'
import instructions5b from './../../Assets/photos/instructions-5b.png'
import InstructionImage from './PostOrderStep/InstructionImage'
import removeBlade from './../../Assets/photos/bladesize/remove-blade.gif'

import { State } from '../../Redux/store'

const instructionsImages = [
	[removeBlade],
	[instructions2a, instructions2b, instructions2c],
	[instructions3a, instructions3b, instructions3c],
	[instructions4a, instructions4b],
	[instructions5a, instructions5b],
]

function PostOrder() {
	const screenSize = useSelector((s: State) => s.view.screenSize)

	return (
		<>
			<div className="PostOrder">
				<h1 className="mainHeading hemi">Drop-off/Pick-up Instructions</h1>

				{screenSize === 'mobile' && <hr />}
				<PostOrderStep title="Carefully Remove Blades from Skates" number="1">
					<InstructionImage
						image={instructionsImages[0][0]}
						width="220px"
						height="220px"
						round={true}
					/>
					<div
						className={`PostOrderMultiItem MarginLeft${
							screenSize == 'mobile' ? '' : ''
						}`}
					>
						<div
							className={screenSize == 'mobile' ? 'MarginTop ' : 'MarginBottom'}
						>
							Use blade removal tool and gloves to remove blades.{' '}
							<span className="instructionsBold instructionsRed">
								Caution: Blades are Sharp!
							</span>
						</div>
						<div
							className={`instructionsBold ${
								screenSize == 'mobile' ? 'MarginTop ' : ''
							}`}
						>
							We accept blades only: Do not leave skates with us.
						</div>
					</div>
				</PostOrderStep>

				<PostOrderStep
					title="Clearly Identify Each Set of Blades with Order Codes"
					number="2"
				>
					<InstructionImage
						image={instructionsImages[1][0]}
						width="220px"
						height="210px"
					/>
					<div
						className={`MarginLeft ${
							screenSize == 'mobile' ? 'MarginTop MarginRight' : ''
						}`}
					>
						Please wrap each set of blades together with tape to keep them
						organized (painter{"'"}s tape is best).
					</div>
				</PostOrderStep>

				<PostOrderStep>
					<div
						className={`PostOrderMultiItem MarginRight ${
							screenSize == 'mobile' ? ' NoTopMargin MarginLeft' : ''
						}`}
					>
						<div
							className={`MarginBottom ${
								screenSize == 'mobile' ? ' MarginTop' : ''
							}`}
						>
							Next, using pieces of tape, please label each set of blades{"'"}{' '}
							with their respective Order Codes{' '}
							<span className="instructionsBold">
								(Order Codes can be found on your Order Confirmation Page)
							</span>
							.
						</div>
						<div className={`${screenSize == 'mobile' ? ' MarginBottom' : ''}`}>
							As a back-up, you can also write your Hollow, Radius, and Pitch
							specs on the tape labels.
						</div>
					</div>

					<InstructionImage
						image={instructionsImages[1][1]}
						width="220px"
						height="210px"
						className={`${screenSize == 'mobile' ? 'OrderMinusOne' : ''}`}
					/>
					<div
						className={`PostOrderMultiItem ${
							screenSize == 'mobile'
								? 'FullWidth AlignSelfCenter'
								: 'MarginLeftOneRem'
						}`}
					>
						<div>Examples of labels</div>
						<InstructionImage
							image={instructionsImages[1][2]}
							width={screenSize == 'mobile' ? '' : '220px'}
						/>
					</div>
				</PostOrderStep>

				<div
					className={`instructionsBold ${
						screenSize == 'mobile' ? 'SpacingReset' : ''
					}`}
				>
					If you do not provide your Order Code your blades will be serviced
					using your last specs on file.
				</div>

				<PostOrderStep
					title="Put Your Blades in Clearly-Labelled Blade Pouch"
					number="3"
				>
					<InstructionImage
						image={instructionsImages[2][0]}
						width="220px"
						round={true}
					/>
					<div
						className={`PostOrderMultiItem MarginLeft ${
							screenSize == 'mobile' ? ' NoTopMargin MarginRight' : ''
						}`}
					>
						<div
							className={`MarginBottom ${
								screenSize == 'mobile' ? ' MarginTop ' : ''
							}`}
						>
							Please place your labelled blades inside a blade pouch.{' '}
							<span className="instructionsBold">
								If you do not have a blade pouch, you can purchase one at the
								Skatescribe front desk.
							</span>
						</div>
						<div>
							Next, print your Order Confirmation Page and place it inside the
							blade pouch.
							<span className="instructionsBold">
								{' '}
								If you do not have access to a printer, please be sure your
								blades are labelled correctly.
							</span>
						</div>
					</div>
				</PostOrderStep>

				<PostOrderStep>
					<div
						className={`PostOrderMultiItem MarginRight ${
							screenSize == 'mobile' ? ' NoTopMargin MarginLeft' : ''
						}`}
					>
						Lastly, create a pouch label with your Full Name, Phone Number and
						Email Address, and place it in the blade pouch{"'"}s clear pocket.
					</div>
					<InstructionImage
						image={instructionsImages[2][1]}
						width="220px"
						height="220px"
						round={true}
						className={`${
							screenSize == 'mobile' ? 'OrderMinusOne MarginBottom' : ''
						}`}
					/>
					<div
						className={`PostOrderMultiItem ${
							screenSize == 'mobile'
								? 'FullWidth AlignSelfCenter MarginTop'
								: 'MarginLeftOneRem'
						}`}
					>
						<div>Example of label</div>
						<InstructionImage image={instructionsImages[2][2]} height="195px" />
					</div>
				</PostOrderStep>

				<PostOrderStep title="Drop Blades Off at Skatescribe" number="4">
					<InstructionImage
						image={instructionsImages[3][0]}
						width="225px"
						round={true}
					/>
					<InstructionImage
						image={instructionsImages[3][1]}
						width="225px"
						round={true}
						className={`${
							screenSize == 'mobile'
								? ' MarginTop MarginBottom'
								: 'MarginLeftOneRem'
						}`}
					/>

					<div className="PostOrderMultiItem MarginLeft">
						<div className="MarginBottom">
							<span className="instructionsBold">
								Please double check that you have followed Steps 1, 2, and 3
								correctly.
							</span>
						</div>
						<div>
							Drop your blades off at the Skatescribe counter or deposit them in
							the Blade Dropoff box slot.
						</div>
						{screenSize == 'mobile' && (
							<div className="instructionsBold MarginTop">
								You will receive a confirmation email when blades are received,
								and again when the blades are ready for pickup.
							</div>
						)}
					</div>
				</PostOrderStep>

				{screenSize != 'mobile' && (
					<div className="instructionsBold">
						You will receive a confirmation email when blades are received, and
						again when the blades are ready for pickup.
					</div>
				)}

				<PostOrderStep title=" Pick Blades Up at Skatescribe" number="5">
					<InstructionImage
						image={instructionsImages[4][0]}
						width="225px"
						height="225px"
						round={true}
						className={`${screenSize == 'mobile' ? 'MarginBottom' : ''}`}
					/>
					{screenSize != 'mobile' ? (
						<InstructionImage
							image={instructionsImages[4][1]}
							height="225px"
							className="MarginLeftOneRem"
						/>
					) : (
						''
					)}

					<div
						className={`PostOrderMultiItem ${
							screenSize == 'mobile' ? '' : 'MarginLeft'
						}`}
					>
						<div
							className={`MarginBottom ${
								screenSize == 'mobile' ? ' MarginRight' : ''
							}`}
						>
							Once you{"'"}ve received the confirmation email that your blades
							are ready for pickup, visit the Skatescribe warehouse and pick
							them up at the front counter.
						</div>
						{screenSize == 'mobile' && (
							<InstructionImage
								image={instructionsImages[4][1]}
								width="21.5%"
								className="MarginTop MarginBottom AlignSelfCenter"
							/>
						)}

						<div className={`${screenSize == 'mobile' ? ' MarginRight' : ''}`}>
							For your own security, you or your parent/guardian (if under 18),
							must present a valid Photo ID at the front counter.
						</div>
					</div>
				</PostOrderStep>
			</div>

			<Footer />
		</>
	)
}

export default PostOrder
