import React from 'react'
import './HollowOptions.css'
import { useDispatch, useSelector } from 'react-redux'
import hollowSVG from '../../../../Assets/graphics/parameter-hollow.svg'
import { hollowSelector, SpecType } from '../../../../Redux/Slices/optionsSlice'
import Slider from '../../../../Components/Slider/Slider'
import { CartItem, updateCartItem } from '../../../../Redux/Slices/cartSlice'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

interface HollowOptionsProps {
	cartItem: CartItem
}

export default function HollowOptions({ cartItem }: HollowOptionsProps) {
	const hollow = useSelector(hollowSelector)

	const dispatch = useDispatch()

	const tooltipText =
		'Using a larger hollow increases<br/>your glide and top speed, while<br/>using a smaller hollow increases<br/>your acceleration and bite.'

	//Converts the value of the hollow (string) to a position value for the slider
	function getSliderPos(v: any) {
		const idx = hollow.findIndex((b: any) => b === v)
		return Math.max(idx, 0)
	}

	//Converts a slider position to a hollow value from the list
	function getHollowValue(pos: any) {
		return hollow[pos]
	}

	function sliderHandler(newValue: any) {
		updateHandler(getHollowValue(newValue))
	}

	function updateHandler(newValue: any) {
		dispatch(
			updateCartItem({
				id: cartItem.id,
				updates: {
					specType: SpecType.Advanced,
					hollowValue: newValue,
					presetID: null,
				},
			})
		)
	}
	return (
		<div className="specItem HollowOptions">
			<div className="topContainer">
				<div className="inputContainer">
					<span
						data-for="title"
						data-tip={tooltipText}
						data-iscapture="true"
						className="titleIcon"
					>
						Hollow:
						<FontAwesomeIcon className="questionIcon" icon={faQuestionCircle} />
					</span>
					<ReactTooltip
						id="title"
						effect="solid"
						place="bottom"
						multiline={true}
					/>
					<select
						className="input"
						value={cartItem.hollowValue}
						onChange={(e) => updateHandler(e.target.value)}
					>
						{hollow.map((h: any) => {
							return (
								<option value={h} key={h}>
									{h + ' "'}
								</option>
							)
						})}
					</select>
				</div>

				<div
					className="graphicContainer"
					data-for="graphic"
					data-tip={tooltipText}
					data-iscapture="true"
				>
					<img src={hollowSVG} />
					{<span className="value">{cartItem.hollowValue + '"'}</span>}
				</div>
				<ReactTooltip
					id="graphic"
					place="right"
					effect="solid"
					multiline={true}
				/>
			</div>

			<div className="sliderContainer">
				<Slider
					leftTip={'Bite'}
					rightTip={'Glide'}
					max={hollow.length - 1}
					min={0}
					marks
					value={getSliderPos(cartItem.hollowValue)}
					onChange={sliderHandler}
					minLabel={hollow[0]}
					maxLabel={hollow[hollow.length - 1]}
				/>
			</div>
		</div>
	)
}
