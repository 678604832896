import React, { ChangeEvent } from 'react'
import './PitchValue.css'
import { useSelector } from 'react-redux'
import pitchSVG from '../../../../Assets/graphics/parameter-pitch.svg'
import Slider from '../../../../Components/Slider/Slider'
import { pitchSelector } from '../../../../Redux/Slices/optionsSlice'

interface PitchValueProps {
	pitchValue: number | undefined
	setPitchValue(value?: number): void
}

export default function PitchValue({
	pitchValue,
	setPitchValue,
}: PitchValueProps) {
	const pitch = useSelector(pitchSelector)

	function slideHandler(newValue: string) {
		setPitchValue(Number(newValue))
	}

	const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setPitchValue(Number(e.target.value))
	}

	return (
		<div className="specItem PitchValue">
			<div className="topContainer">
				<div className="inputContainer">
					Pitch:
					<input
						className="input"
						type="number"
						min={pitch.range[0]}
						max={pitch.range[1]}
						value={pitchValue || pitch.range[0]}
						onChange={changeHandler}
						onKeyDown={(e) => e.preventDefault()}
					/>
				</div>

				<div className="graphicContainer">
					<span className="value">P{pitchValue}</span>
					<img src={pitchSVG} />
				</div>
			</div>

			<div className="sliderContainer">
				<Slider
					step={0.5}
					leftTip={'Backwards'}
					rightTip={'Forwards'}
					max={pitch.range[1]}
					min={pitch.range[0]}
					value={Number(pitchValue == undefined ? -10 : pitchValue)}
					onChange={slideHandler}
					minLabel={pitch.range[0]}
					maxLabel={pitch.range[1]}
				/>
			</div>
		</div>
	)
}
