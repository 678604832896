import React from 'react'
import { useHistory } from 'react-router-dom'
import './OrderList.css'
import Order from '../../../@types/Order'
import { useSelector } from 'react-redux'
import { State } from '../../../Redux/store'
import { formatCurrency } from '../../../Utils/formatUtils'

interface OrderListProps {
	orders: Order[]
}

export default function OrderList({ orders }: OrderListProps) {
	const screenSize = useSelector((s: State) => s.view.screenSize)

	return (
		<table className="OrderList">
			{screenSize === 'mobile' ? (
				<div className="header"></div>
			) : (
				<tr className="header">
					<th>Order #:</th>
					<th>Date Placed:</th>
					<th>Order Status:</th>
					<th>Order Price:</th>
					<th>Discount Code:</th>
				</tr>
			)}

			{orders.map((order, idx) => (
				<OrderListItem key={idx} order={order} />
			))}
		</table>
	)
}

function OrderListItem({ order }: { order: Order }) {
	const history = useHistory()
	const date = new Date(order.placedDate)
	const screenSize = useSelector((s: State) => s.view.screenSize)

	return (
		<tr className="OrderListItem bold">
			<td
				className="orderId"
				onClick={() => {
					history.push('/Orders/' + order.longID)
				}}
			>
				Order #{order.shortID} ({order.itemCount} item
				{order.itemCount !== 1 ? 's' : ''})
			</td>
			<td className="orderDate">
				Placed {date.toLocaleString('default', { month: 'long' })}{' '}
				{date.getDate()}
			</td>
			<td className="orderStatus">{order.status}</td>
			<td className="orderTotal">{formatCurrency(order.finalTotal)}</td>

			<td className={order.discountCode ? 'discountCode' : 'orderDate'}>
				{screenSize === 'mobile' ? (
					<span className="orderDate">Discount Code: </span>
				) : (
					''
				)}
				{order.discountCode || 'None Used'}
			</td>
		</tr>
	)
}
