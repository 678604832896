import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { State } from '../../Redux/store'

interface ProtectedRouteProps {
	path: string
	component: FC
}

export default function ProtectedRoute({
	path,
	component,
}: ProtectedRouteProps) {
	const { status } = useSelector((s: State) => s.activeUser)

	return status === 'Logged In' ? (
		<Route path={path} component={component} />
	) : (
		<Redirect to="/" />
	)
}
