import React from 'react'
import './PresetCard.css'
import { useDispatch } from 'react-redux'
import { CartItem, updateCartItem } from '../../../../Redux/Slices/cartSlice'

interface PresetCardProps {
	preset: any
	cartItem: CartItem
}

export default function PresetCard({ preset, cartItem }: PresetCardProps) {
	const dispatch = useDispatch()
	const { name, imageURL, hollowValue, radiusValue, pitchValue } = preset

	const isSelected = name === cartItem.specType

	const switchPreset = () => {
		dispatch(
			updateCartItem({
				id: cartItem.id,
				updates: {
					specType: name,
					hollowValue,
					radiusValue,
					pitchValue,
					presetID: null,
				},
			})
		)
	}

	return (
		<div
			className={`PresetCard clickAnimation ${name} ${
				isSelected && 'selected'
			}`}
			onClick={switchPreset}
		>
			<div className="title wide hemi">
				<span className="nameContainer">
					<input
						type="radio"
						className="input radio"
						readOnly
						checked={isSelected}
					/>
					{name}
				</span>
				<img className="presetImg" src={imageURL} draggable="false" />
			</div>

			<div className="contents">
				<div className="specInfo">
					<div>
						<div className="lightText">Hollow:</div>
						{`${hollowValue}"`}
					</div>
					<div>
						<div className="lightText">Radius:</div>
						{`${radiusValue}'`}
					</div>
					<div>
						<div className="lightText">Pitch:</div>
						{`P${pitchValue}`}
					</div>
				</div>
			</div>
		</div>
	)
}
