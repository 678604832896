import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Conditional from '../../Components/Conditional/Conditional'
import LocationMap from '../../Components/LocationMap/LocationMap'
import { useScreenSize } from '../../Hooks/useScreenSize'
import { updateCart } from '../../Redux/Slices/cartSlice'
import { Location } from '../../Redux/Slices/optionsSlice'
import { State } from '../../Redux/store'
import './ChooseLocation.css'

export default function ChooseLocation() {
	const dispatch = useDispatch()
	const history = useHistory()

	const locations: Location[] = useSelector((s: State) => s.options.locations)
	const selectedLocation = useSelector((s: State) => s.cart.dropOffLocation)

	const activeLocation = locations.find(
		(location: Location) => location.locationID === selectedLocation
	)

	const { screenSize } = useScreenSize()

	const addLocationSelection = (locationID: number) =>
		locationID === selectedLocation ? 'selected' : ''
	const updateLocation = (locationID: number) =>
		dispatch(updateCart({ dropOffLocation: locationID }))

	return (
		<div className="ChooseLocation">
			<h1 className="hemi title">Drop-off/Pick-up Location</h1>
			<div className="contents">
				<div className="locationSelection">
					<div className="locationOptions">
						{locations.map((location) => (
							<div
								key={location.locationID}
								className={`
										locationOption 
										${addLocationSelection(location.locationID)}
									`}
								onClick={() => updateLocation(location.locationID)}
							>
								<div className="locationInfo">
									<div className="locationTitle hemi">{location.title}</div>
									<div className="locationAddress">{location.address}</div>
								</div>

								<div className="locationTimings">
									<div className="hoursHeading">Store Hours (EST):</div>
									{location.openingHours?.map(({ days, time }, index) => (
										<div className="hours" key={index}>
											<span>{days}:</span>
											<span>{time}</span>
										</div>
									))}
								</div>

								<a
									className="button plain neutral mapsLink"
									href={activeLocation?.link}
									target="_blank"
									rel="noreferrer"
								>
									Open in Google Maps
								</a>
							</div>
						))}
					</div>

					<Conditional condition={screenSize === 'desktop'}>
						<div className="locationMapWrapper">
							<LocationMap location={activeLocation} height={'600px'} />
						</div>
					</Conditional>
				</div>
				<button
					className="button reviewButton"
					onClick={() => {
						history.push('/Checkout/Review')
					}}
				>
					Review Order
				</button>
			</div>
		</div>
	)
}
