import React from 'react'
import { InfoTab } from 'react-ui-scaffold'
import purchaseBladeImage from '../../../../Assets/graphics/purchase-placeholder.svg'
import { Product } from '../../../../Redux/Slices/productsSlice'
import { formatCurrency } from '../../../../Utils/formatUtils'
import './ModelTab.css'

interface ModelTabProps {
	tabID: string
	size: string
	options?: Product[]
	handleAdd: (product: Product) => void
}

export default function ModelTab({
	tabID,
	size,
	options = [],
	handleAdd,
}: ModelTabProps) {
	return (
		<InfoTab className="QuestionTab ModelTab" tabID={tabID}>
			<div className="Question">
				Select your&nbsp;<b>model type</b>.
			</div>
			<div className="ModelContainer">
				{options.map((option: any) => {
					const inStock = option.inStock && option.sku.inStock
					return (
						<div key={option.productID} className="ModelWrapper">
							<div
								className={`Model ${inStock && 'InStock'}`}
								onClick={() => handleAdd(option)}
							>
								<div className="ModelTitle hemi title wide">{option.name}</div>
								<div className="Size">Size {size}</div>
								<img
									src={purchaseBladeImage}
									alt="purchase-blade"
									draggable="false"
									className="PurchaseBladeImage"
								/>
								<div className="flexY PriceContainer">
									Price:&nbsp;
									<div className="Price">
										{formatCurrency(option.price)} CAD
									</div>
								</div>
							</div>
							{!inStock ? (
								<div className="InOutStockIndicator">
									Temporarily Out of Stock
								</div>
							) : null}
						</div>
					)
				})}
			</div>
		</InfoTab>
	)
}
