import React from 'react'

export default function CardForm() {
	return (
		<form id="checkout-form">
			<div className="flexY">
				<div className="wide">
					<label className="desktopOnly tablet">Card Number*</label>
					<div id="card-number" className="input wide"></div>
				</div>
			</div>
			<div className="flexY">
				<div className="half" style={{ paddingRight: '20px' }}>
					<div className="wide">
						<label className="desktopOnly tablet">Expiry Date*</label>
						<div id="card-expiry" className="input wide"></div>
					</div>
				</div>
				<div className="half">
					<div className="wide">
						<label className="desktopOnly tablet">CVV*</label>
						<div id="card-cvv" className="input wide"></div>
					</div>
				</div>
			</div>
		</form>
	)
}
