import React, { useState } from 'react'
import './AccountRow.css'
import AccountSettingPopup from '../AccountSettingPopup/AccountSettingPopup'
import { MenuButton } from 'react-ui-scaffold'
import { NotificationOptionType } from '../../../@types/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserSettingsData } from '../../../Redux/Slices/activeUserSlice'
import { State } from '../../../Redux/store'

export enum ActionType {
	AccountPopup = 'popup',
	NotificationDropdown = 'notifDropdown',
	Custom = 'custom',
}

interface AccountRowProps {
	label: string
	field: string
	user: any
	actionType?: ActionType
	customLabel?: string
	customAction?(): void
	customValue?: string | number
}

export default function AccountRow({
	label,
	field,
	user,
	actionType,
	customLabel,
	customAction,
	customValue,
}: AccountRowProps) {
	const [showPopup, setShowPopup] = useState(false)

	const renderAction = (actionType?: ActionType) => {
		switch (actionType) {
			case ActionType.NotificationDropdown:
				return (
					<MenuButton
						menuContent={NotificationSettingsMenu}
						menuProps={{ notificationOption: field }}
						style={{ padding: 0 }}
					>
						<button className="button neutral plain actionButton">
							Change
						</button>
					</MenuButton>
				)
			case ActionType.AccountPopup:
				return (
					<button
						className="button neutral plain actionButton"
						onClick={() => {
							if (customAction) customAction()
							else setShowPopup(true)
						}}
					>
						Change
					</button>
				)
			case ActionType.Custom:
				return (
					<button
						className="button neutral plain actionButton"
						onClick={() => customAction && customAction()}
					>
						{customLabel}
					</button>
				)
			default:
				return <></>
		}
	}

	return (
		<>
			<AccountSettingPopup
				visible={showPopup}
				setVisible={setShowPopup}
				title={label}
				field={field}
				user={user}
			/>

			<div className="AccountRow flexY">
				<div className="fieldContainer">
					<div className="keyName">{label}:</div>
					<div className="value">
						{customValue?.toString() || user.data[field]}
					</div>
				</div>
				{renderAction(actionType)}
			</div>
		</>
	)
}

interface NotificationSettingsMenuProps {
	notificationOption: NotificationOptionType
}

function NotificationSettingsMenu({
	notificationOption,
}: NotificationSettingsMenuProps) {
	const dispatch = useDispatch()
	const settingsOptions = useSelector((s: State) => s.options.settingsOptions)

	return (
		<div className="NotificationMenu" style={{ width: '200px' }}>
			{settingsOptions
				.find((option) => option.name === notificationOption)
				?.options.map((option: string, idx: number) => (
					<button
						key={idx}
						className="button wide"
						onClick={() => {
							dispatch(
								updateUserSettingsData({
									[notificationOption]: option,
								})
							)
						}}
					>
						{option}
					</button>
				))}
		</div>
	)
}
