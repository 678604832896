import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from 'react-ui-scaffold'
import { State } from '../../Redux/store'
import skateScribeMarker from './../../Assets/graphics/map-marker.svg'
import {
	GoogleMap,
	useLoadScript,
	Marker,
	InfoWindow,
} from '@react-google-maps/api'
import { Location } from '../../Redux/Slices/optionsSlice'

const libraries: (
	| 'places'
	| 'drawing'
	| 'geometry'
	| 'localContext'
	| 'visualization'
)[] = ['places']

interface LocationMapProps {
	height?: string
	location?: Location
}

export default function LocationMap({ location, height }: LocationMapProps) {
	const screenSize = useSelector((s: State) => s.view.screenSize)

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
		libraries,
	})

	const [showInfoBox, setShowInfoBox] = useState<boolean>(false)

	if (loadError) return <div>Error loading maps</div>
	if (!isLoaded)
		return (
			<Loading
				// className="desktopOnly"
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					margin: 'auto',
					fontSize: '2.5rem',
				}}
			/>
		)

	if (!location) {
		return null
	}

	return (
		<div
			className="LocationMap"
			style={{ width: '100%', height: height || '600px' }}
		>
			{screenSize !== 'mobile' && (
				<GoogleMap
					mapContainerStyle={{
						height: height || '100%',
						width: '100%',
					}}
					zoom={10}
					center={{
						lat: location.lat,
						lng: location.lng,
					}}
					options={{
						zoomControl: true,
						clickableIcons: false,
						// Show less landmarks on the map
						mapTypeControl: false,
						streetViewControl: false,
						fullscreenControl: false,
						styles: [
							{
								featureType: 'poi',
								elementType: 'labels',
								stylers: [
									{
										visibility: 'off',
									},
								],
							},
						],
					}}
				>
					<Marker
						position={{ lat: location.lat, lng: location.lng }}
						icon={{
							url: skateScribeMarker,
							scaledSize: new window.google.maps.Size(50, 58),
						}}
						label={{
							text: 'Skatescribe',
							className: 'markerLabel',
						}}
						onClick={() => {
							setShowInfoBox(true)
						}}
					/>

					{showInfoBox && (
						<InfoWindow
							position={{ lat: location.lat + 0.005, lng: location.lng }}
							onCloseClick={() => {
								setShowInfoBox(false)
							}}
						>
							<div>
								<div>Skatescribe</div>
								<div>{location.address}</div>
								<b>
									<a href={location.link}>Open in Maps</a>
								</b>
							</div>
						</InfoWindow>
					)}
				</GoogleMap>
			)}
		</div>
	)
}
