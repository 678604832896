import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../../Redux/store'
import './SizeTable.css'

interface SizeTablePropsI {
	title: string
	img: string
	imgMobile: string
	altText: string
	tableRows: number
}

export default function SizeTable({
	title,
	img,
	imgMobile,
	altText,
	tableRows,
}: SizeTablePropsI) {
	const screenSize = useSelector((s: State) => s.view.screenSize)

	return (
		<div className="sizeTable">
			<div className="sizeTableHeading">{title}</div>
			<div className="sizeTableImage">
				{screenSize === 'mobile' ? (
					<img src={imgMobile} alt={altText} />
				) : (
					<img
						src={img}
						alt={altText}
						className={tableRows == 2 ? 'double' : 'triple'}
					/>
				)}
			</div>
		</div>
	)
}
