import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import LoggedInOnly from '../../../LoggedInOnly/LoggedInOnly'
import LoggedOutOnly from '../../../LoggedOutOnly/LoggedOutOnly'
import auth from '../../../../Handlers/auth'
import { useSelector } from 'react-redux'
import { State } from '../../../../Redux/store'

interface BurgerMenuProps {
	setShowSignIn(value: boolean): void
}

export default function BurgerMenu({ setShowSignIn }: BurgerMenuProps) {
	function bgClickHandler() {
		document.body.click()
	}

	const history = useHistory()
	const screenSize = useSelector((s: State) => s.view.screenSize)

	return (
		<div
			className="BurgerMenu"
			onClick={bgClickHandler}
			style={{ minWidth: '120px', width: '200px' }}
		>
			<Link to="/Shop">
				<button className="button wide">Shop</button>
			</Link>

			<LoggedInOnly>
				{screenSize === 'mobile' && (
					<Link to="/Notifications">
						<button className="button wide">Notifications</button>
					</Link>
				)}

				<Link to="/MyAccount">
					<button className="button wide">My Account</button>
				</Link>

				<Link to="/MyOrders">
					<button className="button wide">My Orders</button>
				</Link>

				<Link to="/MyPresets">
					<button className="button wide">My Presets</button>
				</Link>

				<button
					className="button wide negative"
					onClick={() => {
						auth.logout()
						history.push('/')
						setShowSignIn(false)
					}}
				>
					Sign Out
				</button>
			</LoggedInOnly>

			<LoggedOutOnly>
				<Link to="/Sign In">
					<button
						className="button wide neutral"
						onClick={() => {
							setShowSignIn(true)
						}}
					>
						<b>Log In / Sign Up</b>
					</button>
				</Link>
			</LoggedOutOnly>
		</div>
	)
}
