import React from 'react'
import MobileCartCard from '../Support/MobileCartCard/MobileCartCard'
import './MobileCart.css'

interface MobileCartProps {
	cart: any
}

export default function MobileCart({ cart }: MobileCartProps) {
	return (
		<div className="MobileCart">
			{cart.contents.map((c: any, id: number) => {
				return <MobileCartCard cartItem={c} key={id} />
			})}
		</div>
	)
}
