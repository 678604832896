import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import useAddSharpening from '../../../Hooks/useAddSharpening'
import { formatCurrency } from '../../../Utils/formatUtils'
import './CartFooter.css'

interface CartFooterProps {
	cart: any
	setPurchaseBladePopupOpen(value: boolean): void
	disabled: boolean
}

export default function CartFooter({
	cart,
	setPurchaseBladePopupOpen,
	disabled,
}: CartFooterProps) {
	const history = useHistory()
	const addSharpening = useAddSharpening()
	const cartTotalMemo = useMemo(() => formatCurrency(cart.total), [cart.total])
	const [message, setMessage] = useState('')

	function checkout() {
		const valid = cart.contents.every((c: any) => c.specType)

		if (valid) {
			history.push('/Checkout')
		} else {
			setMessage('Please choose all your specs!')
		}
	}

	return (
		<div className="CartFooter flex">
			<div className="half">
				<div className="buttonBox">
					<button className="button plain neutral" onClick={addSharpening}>
						<b>Add Sharpening</b>
					</button>
				</div>
				<div className="buttonBox">
					<button
						className="button plain neutral"
						onClick={() => setPurchaseBladePopupOpen(true)}
					>
						<b>Purchase Blades</b>
					</button>
				</div>
			</div>

			<div className="half toRight">
				<button
					className="button checkoutButton"
					onClick={checkout}
					disabled={disabled}
				>
					Checkout ({cartTotalMemo})
				</button>

				<div className="buttonMessage">{message}</div>
			</div>
		</div>
	)
}
