import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../../Redux/store'

export interface InstructionImageProps {
	image: string
	round?: boolean
	width?: string
	height?: string
	className?: string
}

export default function InstructionImage({
	image,
	round,
	width,
	height,
	className,
}: InstructionImageProps) {
	const screenSize = useSelector((s: State) => s.view.screenSize)
	return (
		<img
			src={image}
			width={screenSize == 'mobile' ? '70%' : width}
			height={screenSize == 'mobile' ? '' : height}
			className={round ? 'RoundInstImg ' + className : className}
		/>
	)
}
