import React, { useEffect, useMemo, useState } from 'react'
import Popup from '../../Components/Popup/Popup'
import { ControlledTabContainer } from 'react-ui-scaffold'
import './PurchaseBladePopup.css'
import HolderTab from './PurchaseBladeTabs/HolderTab/HolderTab'
import SizeTab from './PurchaseBladeTabs/SizeTab/SizeTab'
import ModelTab from './PurchaseBladeTabs/ModelTab/ModelTab'
import { useDispatch, useSelector } from 'react-redux'
import { Product } from '../../Redux/Slices/productsSlice'
import {
	addToCart,
	setIsNewBladeAddedfromPopup,
	updateCartItem,
} from '../../Redux/Slices/cartSlice'
import { State } from '../../Redux/store'

interface PurchaseBladePopupProps {
	visible: boolean
	setVisible(value: boolean): void
	itemToReplace: any
}

export default function PurchaseBladePopup({
	visible,
	setVisible,
	itemToReplace,
}: PurchaseBladePopupProps) {
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState('Holder')
	const [holder, setHolder] = useState('')
	const [holderError, setHolderError] = useState('')
	const [size, setSize] = useState('')
	const [sizeError, setSizeError] = useState('')

	const products = useSelector((s: State) => s.products.list)
	const holderSizeModelMap = useMemo(() => {
		const map: any = {}
		products.map((product) => {
			const brand = product.brand as string
			if (product.type == 'Purchase' && brand) {
				if (!map[brand]) {
					map[brand] = {
						sizeMap: {},
					}
				}
				product.skus.map((sku) => {
					if (!map[brand as string].sizeMap[sku.name]) {
						map[brand].sizeMap[sku.name] = []
					}
					map[brand].sizeMap[sku.name].push({ ...product, sku })
				})
			}
		})
		return map
	}, [products])

	const sizeTabDisabled = !holder
	const modelTabDisabled = !size

	useEffect(() => {
		setActiveTab('Holder')
	}, [visible])

	useEffect(() => {
		if (itemToReplace) {
			const { brand, skuName } = itemToReplace
			if (holderSizeModelMap?.[brand]) {
				setHolder(brand)
				setActiveTab('Size')
				if (skuName) {
					if (holderSizeModelMap[brand].sizeMap[skuName]) {
						setSize(skuName)
						setActiveTab('Model')
					}
				}
			}
		}
	}, [itemToReplace, holderSizeModelMap])

	function tabClickHandler(tab: string) {
		setActiveTab(tab)
		setHolderError('')
		setSizeError('')
	}

	function exitHandler() {
		setVisible(false)
	}

	function handleAdd(product: Product) {
		const sku = product.skus.find((sku) => sku.name == size)
		const inStock = product.inStock && sku?.inStock
		if (!inStock) {
			return
		}

		const cartItem = {
			type: product.type,
			productID: product.productID,
			quantity: 1,
			productSkuID: sku?.productSkuID,
			name: `${product.name} - ${sku?.name}`,
		}
		if (itemToReplace) {
			dispatch(
				updateCartItem({
					id: itemToReplace.id,
					updates: { ...cartItem, error: null },
				})
			)
		} else {
			dispatch(
				addToCart({
					items: [cartItem],
					replaceCart: false,
				})
			)
			dispatch(setIsNewBladeAddedfromPopup(true))
		}
		setVisible(false)
	}

	return (
		<Popup
			title="Purchase Blades"
			visible={visible}
			setVisible={setVisible}
			className="PurchaseBladePopup"
			onExit={exitHandler}
		>
			<div className="tabControls">
				<div
					className={`tab ${activeTab === 'Holder' && 'active'}`}
					onClick={() => tabClickHandler('Holder')}
				>
					<div className="tabText">
						Holder
						<div className="tabValue">{holder}</div>
					</div>
				</div>
				<div
					className={`tab ${activeTab === 'Size' && 'active'} ${
						sizeTabDisabled && 'TabDisabled'
					}`}
					onClick={() => {
						if (sizeTabDisabled) {
							setHolderError('Please select your holder type.')
						} else {
							tabClickHandler('Size')
						}
					}}
				>
					<div className="tabText">
						Size
						<div className="tabValue">{size}</div>
					</div>
				</div>
				<div
					className={`tab ${activeTab === 'Model' && 'active'} ${
						modelTabDisabled && 'TabDisabled'
					}`}
					onClick={() => {
						if (sizeTabDisabled) {
							setHolderError('Please select your holder type.')
						} else if (modelTabDisabled) {
							setSizeError('Please select your blade size.')
						} else {
							tabClickHandler('Model')
						}
					}}
				>
					<div className="tabText">Model</div>
				</div>
			</div>

			<ControlledTabContainer activeTab={activeTab}>
				<HolderTab
					tabID="Holder"
					options={Object.keys(holderSizeModelMap)}
					value={holder}
					setResponse={(response: string) => {
						setHolder(response)
						setSize('')
						setActiveTab('Size')
						setHolderError('')
					}}
					error={holderError}
				/>
				<SizeTab
					tabID="Size"
					options={
						holder ? Object.keys(holderSizeModelMap[holder].sizeMap) : []
					}
					value={size}
					setResponse={(response: string) => {
						setSize(response)
						setActiveTab('Model')
					}}
					error={sizeError}
				/>
				<ModelTab
					tabID="Model"
					options={size ? holderSizeModelMap[holder].sizeMap[size] : []}
					size={size}
					handleAdd={handleAdd}
				/>
			</ControlledTabContainer>
		</Popup>
	)
}
