import React from 'react'
import './NotificationSettingsPopup.css'
import Popup from '../../Components/Popup/Popup'
import { NotificationOptionType } from '../../@types/Notification'
import AccountRow, {
	ActionType,
} from '../../Pages/MyAccount/AccountRow/AccountRow'
import NotificationsClear from '../../Pages/Notifications/NotificationsClear/NotificationsClear'

interface NotificationSettingsPopupProps {
	visible: boolean
	setVisible?: any
	user: any
}

export default function NotificationSettingsPopup({
	visible,
	setVisible,
	user,
}: NotificationSettingsPopupProps) {
	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={'Notification Settings'}
			className={'NotificationSettingsPopup'}
		>
			<AccountRow
				label="Receive Notifications by"
				field={NotificationOptionType.Method}
				user={user}
				actionType={ActionType.NotificationDropdown}
			/>
			<AccountRow
				label="Show Notifications"
				field={NotificationOptionType.Preview}
				user={user}
				actionType={ActionType.NotificationDropdown}
			/>
			<div className="clearAllContainer">
				<NotificationsClear />
			</div>
		</Popup>
	)
}
