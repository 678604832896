import React, { ChangeEvent } from 'react'
import './HollowValue.css'
import { useSelector } from 'react-redux'
import hollowSVG from '../../../../Assets/graphics/parameter-hollow.svg'
import { hollowSelector } from '../../../../Redux/Slices/optionsSlice'
import Slider from '../../../../Components/Slider/Slider'

interface HollowValueProps {
	hollowValue: string
	setHollowValue(value: string): void
}

export default function HollowValue({
	hollowValue,
	setHollowValue,
}: HollowValueProps) {
	const hollow = useSelector(hollowSelector)

	//Converts the value of the hollow (string) to a position value for the slider
	function getSliderPos(newValue: string) {
		const idx = hollow.findIndex((value: string) => value == newValue)
		return Math.max(idx, 0)
	}

	//Converts a slider position to a hollow value from the list
	const getHollowValue = (pos: number) => {
		return hollow[pos]
	}

	const slideHandler = (newValue: number) => {
		setHollowValue(getHollowValue(newValue))
	}

	const selectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
		setHollowValue(e.target.value)
	}

	return (
		<div className="specItem HollowValue">
			<div className="topContainer">
				<div className="inputContainer">
					Hollow:{' '}
					<select
						className="input"
						value={hollowValue}
						onChange={(e) => selectHandler(e)}
					>
						<option value={''}> -- </option>
						{hollow.map((h: any) => {
							return (
								<option value={h} key={h}>
									{h + ' "'}
								</option>
							)
						})}
					</select>
				</div>

				<div className="graphicContainer">
					<img src={hollowSVG} />
					<span className="value">{hollowValue ? `${hollowValue}"` : '"'}</span>
				</div>
			</div>

			<div className="sliderContainer">
				<Slider
					leftTip={'Bite'}
					rightTip={'Glide'}
					max={hollow.length - 1}
					min={0}
					marks
					value={getSliderPos(hollowValue)}
					onChange={slideHandler}
					minLabel={hollow[0]}
					maxLabel={hollow[hollow.length - 1]}
				/>
			</div>
		</div>
	)
}
