import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../Redux/store'
import './MainContainer.css'

interface MainContainerProps {
	children?: React.ReactNode
	className?: string
	style?: React.CSSProperties
}

interface SpaceMap {
	mobile: number
	tablet: number
	desktop: number
}

export default function MainContainer({
	children,
	className,
	style,
}: MainContainerProps) {
	const screenSize = useSelector((s: State) => s.view.screenSize)

	const space: SpaceMap = {
		mobile: 40,
		tablet: 100,
		desktop: 250,
	}

	return (
		<div className="MainContainer flexX">
			<div
				className={'internalContainer ' + className}
				style={{
					width: `calc(100% - ${space[screenSize as keyof typeof space]}px) `,
					...style,
				}}
			>
				{children}
			</div>
		</div>
	)
}
