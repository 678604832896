import React from 'react'
import ReactSlider from 'react-slider'
import './Slider.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import skatescribeIcon from './../../Assets/photos/slider-icon.png'
interface SliderDivision {
	title: string
	width: number | string
}

interface SliderProps {
	min: number
	max: number
	// eslint-disable-next-line no-unused-vars
	onChange?: ((value: any, index: any) => void) | undefined
	value: number
	minLabel: string | number
	maxLabel: string | number
	divisions?: SliderDivision[]
	leftTip: string
	rightTip: string
	marks?: number | boolean | readonly number[]
	step?: number
}

export default function Slider({
	min,
	max,
	onChange,
	value,
	minLabel,
	maxLabel,
	divisions = [],
	leftTip,
	rightTip,
	marks,
	step,
}: SliderProps) {
	return (
		<div className="Slider">
			<div className="boundaryLabels">
				<div>
					<FontAwesomeIcon icon={faCaretLeft} /> {leftTip}
				</div>
				<div>
					{rightTip} <FontAwesomeIcon icon={faCaretRight} />
				</div>
			</div>

			<ReactSlider
				step={step}
				marks={marks}
				markClassName="sliderMark"
				min={min}
				max={max}
				thumbClassName="sliderThumb"
				trackClassName="sliderTrack"
				onChange={onChange}
				value={value}
				renderThumb={(props) => (
					<div {...props}>
						{' '}
						<img
							src={skatescribeIcon}
							className="sliderIcon"
							alt="slider maker icon"
						/>{' '}
					</div>
				)}
			/>

			<div className="boundaryLabels">
				<div>{minLabel}</div>
				<div>{maxLabel}</div>
			</div>

			<div className="divisionsContainer flex">
				{divisions.map((d, idx) => {
					return (
						<div className="division" style={{ width: d.width }} key={idx}>
							<div className="divisionBorder">
								<span className="divisionTitle">{d.title}</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
