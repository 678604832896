import React, { useEffect, useState } from 'react'
import { FreeButton, Loading } from 'react-ui-scaffold'
import MainContainer from '../../../../Components/MainContainer/MainContainer'
import './CollectPaymentInfo.css'
import Conditional from '../../../../Components/Conditional/Conditional'
import SavedCardCard from './SavedCardCard'
import CardForm from './CardForm'
import Card from '../../../../@types/Card'
import { useSelector } from 'react-redux'
import { State } from '../../../../Redux/store'
import { debouncedBambora } from '../../../../Utils/generalUtils'

export default function BillingPaymentInfo({
	processPayment,
	loggedIn,
	cards = [],
	setMessage,
}: any) {
	const [completedMap, setCompletedMap] = useState({
		['card-number']: false,
		expiry: false,
		cvv: false,
	})
	const cardsLoading = useSelector((s: State) => s.cards.loading)
	const [isLoading, setIsLoading] = useState(false)
	const [bamboraInstance, setBamboraInstance] = useState<any>()
	const [saveCC, setSaveCC] = useState(false)
	const [selectedCard, setSelectedCard] = useState<Card>()
	if (bamboraInstance) {
		bamboraInstance.on(
			'complete',
			function ({ field, complete }: { field: string; complete: boolean }) {
				setCompletedMap({
					...completedMap,
					[field]: complete,
				})
			}
		)
	}

	useEffect(() => {
		const customCheckout = (window as any).customcheckout()
		const style = {
			base: {
				color: '#000',
				fontSize: '16px',
			},
		}
		const options = {
			style,
			classes: {},
		}
		const cardNumber = customCheckout.create('card-number', {
			...options,
			placeholder: 'eg. 123456789101112',
		})
		cardNumber.mount('#card-number')
		const cardCVV = customCheckout.create('cvv', {
			...options,
			placeholder: 'eg. 123',
		})
		cardCVV.mount('#card-cvv')
		const cardExp = customCheckout.create('expiry', {
			...options,
			placeholder: 'MM/YY',
		})
		cardExp.mount('#card-expiry')
		setBamboraInstance(customCheckout)

		return () => {
			cardNumber.unmount()
			cardCVV.unmount()
			cardExp.unmount()
		}
	}, [])

	const paymentHandler = (env: any) => {
		try {
			env ? env.preventDefault() : null
			if (isLoading) {
				return
			}
			if (selectedCard?.card_id) {
				setIsLoading(true)
				return processPayment({ cardID: selectedCard?.card_id }, () =>
					setIsLoading(false)
				)
			}
			const result = Object.values(completedMap).find(
				(complete: boolean) => !complete
			)
			if (result !== false) {
				setIsLoading(true)
				bamboraInstance.createToken((e: any) =>
					debouncedBambora(async () => {
						processPayment({ tokenizedCC: e.token, saveCC }, () =>
							setIsLoading(false)
						)
					})
				)
			} else {
				let errorMessage = 'Please fill out all fields'
				if (!completedMap['card-number']) {
					errorMessage = 'Please enter a valid card number'
				} else if (!completedMap.expiry) {
					errorMessage = 'Please enter a valid expiry date'
				}
				setMessage(errorMessage)
			}
		} catch (err: any) {
			setIsLoading(false)
		}
	}

	return (
		<div className="CollectPaymentInfo">
			<MainContainer style={{ maxWidth: '700px', marginTop: 10 }}>
				<Conditional
					condition={loggedIn && cards.length}
					contents={
						<div style={{ paddingBottom: '20px' }}>
							<label className="hemi">Saved Cards</label>
							{cards.map((card: Card) => {
								return (
									<SavedCardCard
										key={card.card_id}
										card={card}
										selectedCard={selectedCard}
										setSelectedCard={setSelectedCard}
									/>
								)
							})}
						</div>
					}
					fallback={null}
				/>
				<Conditional condition={loggedIn && cardsLoading}>
					<Loading
						style={{
							fontSize: '40px',
							position: 'relative',
							textAlign: 'center',
							width: '100%',
						}}
					/>
				</Conditional>
				<Conditional
					condition={loggedIn}
					contents={
						<div
							className={`SaveableCardForm ${!selectedCard ? 'Selected' : ''}`}
						>
							<div className="flexY">
								<input
									type="radio"
									className="input"
									checked={!selectedCard}
									style={{ marginRight: 10 }}
								/>
								<label className="hemi">New Card</label>
							</div>
							<div style={{ paddingTop: '20px' }}>
								<CardForm />
							</div>
							<div
								style={{ paddingTop: '20px', width: 'max-content' }}
								onClick={() => setSaveCC(!saveCC)}
							>
								<input type="checkbox" className="input" checked={saveCC} />
								<label>Save this card</label>
							</div>
						</div>
					}
					fallback={
						<div>
							<label className="hemi">Payment Info</label>
							<CardForm />
						</div>
					}
				/>
				<div className="payment-button-container">
					<FreeButton
						className="payment-button"
						onClick={paymentHandler}
						loading={isLoading}
					>
						Check Out
					</FreeButton>
				</div>
			</MainContainer>
		</div>
	)
}
