import React, { useState, useEffect } from 'react'
import './Notifications.css'
import { useSelector, useDispatch } from 'react-redux'
import NotificationList from './NotificationList/NotificationList'
import {
	fetchNotifications,
	readNotifications,
} from '../../Redux/Slices/notificationSlice'
import NotificationSettingsPopup from '../../Popups/NotificationSettingsPopup/NotificationSettingsPopup'
import { State } from '../../Redux/store'

function Notifications() {
	const [showPopup, setShowPopup] = useState(false)
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const user = useSelector((s: State) => s.activeUser)
	const dispatch = useDispatch()

	useEffect(() => {
		const fetchAndReadNotifications = async () => {
			await dispatch(fetchNotifications())
			await dispatch(readNotifications())
		}
		fetchAndReadNotifications()
	}, [])

	const notificationList = useSelector((s: State) => s.notifications).list

	return (
		<>
			<NotificationSettingsPopup
				visible={showPopup}
				setVisible={setShowPopup}
				user={user}
			/>
			<div className="Notifications">
				<div className="titleBar">
					{screenSize === 'desktop' && <span />}
					<span className="hemi title">Notifications</span>
					<button
						className="button neutral plain settings"
						onClick={() => setShowPopup(true)}
					>
						{`${
							screenSize === 'desktop' ? 'Notification Settings' : 'Settings'
						}`}
					</button>
				</div>
				<div className="listContainer">
					<NotificationList notificationList={notificationList} />
				</div>
			</div>
		</>
	)
}

export default Notifications
