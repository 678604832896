import React, { useState } from 'react'
import { Page } from 'react-ui-scaffold'
import './BladeSize.css'
import Option1 from './Support/Option1/Option1'
import Option2 from './Support/Option2/Option2'
import Option3 from './Support/Option3/Option3'
import Footer from '../../Components/Footer/Footer'
import Conditional from '../../Components/Conditional/Conditional'

export default function BladeSize() {
	const [isO1OnMobile, setisO1OnMobile] = useState<boolean>(false)

	return (
		<Page className="BladeSize">
			<div className="BladeSizeWrapper">
				<h1 className="hemi BSTitle">Find Blade Size</h1>
				<Option1 setisO1OnMobile={setisO1OnMobile} />
				<Conditional condition={!isO1OnMobile}>
					<Option2 />
					<Option3 />
				</Conditional>
			</div>
			<div className="BSFooterWrapper">
				<Footer />
			</div>
		</Page>
	)
}
