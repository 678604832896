import React from 'react'
import HollowOptions from '../HollowOptions/HollowOptions'
import './SpecSliders.css'
import RadiusOptions from '../RadiusOptions/RadiusOptions'
import PitchOptions from '../PitchOptions/PitchOptions'
import { CartItem } from '../../../../Redux/Slices/cartSlice'

interface SpecSlidersProps {
	cartItem: CartItem
}

export default function SpecSliders({ cartItem }: SpecSlidersProps) {
	return (
		<div
			className="SpecSliders"
			style={
				cartItem.advancedNotes
					? {
							opacity: '50%',
							filter: 'brightness(98%)',
					}
					: {}
			}
		>
			<HollowOptions cartItem={cartItem} />
			<RadiusOptions cartItem={cartItem} />
			<PitchOptions cartItem={cartItem} />
		</div>
	)
}
