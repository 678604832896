import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeFromCart } from '../../../../Redux/Slices/cartSlice'

interface RemoveButtonProps {
	row: any
}

export default function RemoveButton({ row }: RemoveButtonProps) {
	const dispatch = useDispatch()

	function remove() {
		dispatch(removeFromCart(row.id))
	}

	return (
		<button
			className="button plain"
			onClick={remove}
			style={{
				color: 'var(--neutralAccentColor)',
				fontWeight: 'bold',
			}}
		>
			Remove{' '}
			<span style={{ paddingLeft: '3px' }}>
				<FontAwesomeIcon icon={faTrash} />
			</span>
		</button>
	)
}
