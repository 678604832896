import { createContext } from 'react'
import { SpecTab } from './ChooseSpecsPopup'

interface RecoSpecsContextProps {
	chooseSpecsExitHandler(): void
	cartItemId: string
}
export const RecoSpecsContext = createContext<RecoSpecsContextProps>(
	{} as RecoSpecsContextProps
)

interface PresetsContextProps {
	setNewlyCreatedPreset(value: boolean): void
	setActiveTab(tab: SpecTab): void
}
export const PresetsContext = createContext<PresetsContextProps>(
	{} as PresetsContextProps
)
