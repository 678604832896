import React from 'react'
import CartTable from '../CartTable/CartTable'
import { formatCurrency } from '../../../Utils/formatUtils'
import QuantityCell from '../Support/QuantityCell/QuantityCell'
import RemoveButton from '../Support/RemoveButton/RemoveButton'
import ServiceCard from '../Support/ServiceCard/ServiceCard'
import SpecsCard from '../Support/SpecsCard/SpecsCard'
import './DesktopCart.css'
import { countCartContents } from '../../../Utils/generalUtils'

function getRelativeWidth(w: number) {
	return (100 * w) / (200 * 5) + '%'
}

interface DesktopCartProps {
	cart: any
}

export default function DesktopCart({ cart }: DesktopCartProps) {
	const textStyle = () => ({
		color: 'var(--lightTextColor)',
		fontWeight: '500',
		textAlign: 'center',
	})

	const headers = {
		type: {
			title: `Items (${countCartContents(cart.contents)})`,
			width: getRelativeWidth(200),
			component: ServiceCard,
			cellStyle: textStyle,
		},
		specs: {
			title: 'Specs',
			width: getRelativeWidth(250),
			component: SpecsCard,
			cellStyle: () => ({
				paddingLeft: 20,
				display: 'flex',
				justifyContent: 'center',
			}),
		},
		quantity: {
			title: 'Quantity',
			width: getRelativeWidth(200),
			component: QuantityCell,
			cellStyle: textStyle,
		},
		price: {
			title: 'Price',
			width: getRelativeWidth(200),
			cellStyle: textStyle,
			format: formatCurrency,
		},
		removeButton: {
			title: '',
			component: RemoveButton,
			width: getRelativeWidth(200),
			cellStyle: textStyle,
			headerStyle: { color: 'var(--negativeAccentColor' },
		},
	}

	return (
		<div className="DesktopCart">
			<CartTable
				primaryKey={'id'}
				data={cart.contents}
				headers={headers}
				headerStyle={{
					fontFamily: 'hemi_head_bd_it',
					fontSize: '18pt',
					textAlign: 'center',
				}}
				rowClassName="cartRow"
			/>
		</div>
	)
}
