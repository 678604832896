import React from 'react'
import './NotificationList.css'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Notification, { NotificationType } from '../../../@types/Notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import notificationCaution from '../../../Assets/graphics/notification-caution.svg'
import notificationCheckmark from '../../../Assets/graphics/notification-checkmark.svg'
import notificationDropoff from '../../../Assets/graphics/notification-dropoff.svg'
import notificationWelcome from '../../../Assets/graphics/notification-welcome.svg'
import notificationComplete from '../../../Assets/graphics/notification-complete.svg'
import { clearNotification } from '../../../Redux/Slices/notificationSlice'
import NotificationsClear from '../NotificationsClear/NotificationsClear'
import { State } from '../../../Redux/store'

interface NotificationListProps {
	notificationList?: Notification[]
	limit?: number
}

function NotificationList({ notificationList, limit }: NotificationListProps) {
	const history = useHistory()
	const dispatch = useDispatch()
	const notifications = notificationList?.length
		? notificationList
		: useSelector((s: State) => s.notifications).list
	const sortedNotifications = [...notifications].sort(
		(a: Notification, b: Notification) => {
			return new Date(b.createdDate) > new Date(a.createdDate) ? 1 : -1
		}
	)

	const notificationIcons = {
		[NotificationType.Caution]: notificationCaution,
		[NotificationType.Dropoff]: notificationDropoff,
		[NotificationType.Checkmark]: notificationCheckmark,
		[NotificationType.Welcome]: notificationWelcome,
		[NotificationType.Complete]: notificationComplete,
	}

	return (
		<div className="NotificationList">
			{sortedNotifications
				.slice(0, limit)
				.map((notif: Notification, idx: number) => (
					<span
						key={idx}
						className={`notificationItem ${!notif.readDate && 'unread'} ${
							notif.actionURL && 'urlItem'
						}`}
						onClick={() => {
							if (notif.actionURL) {
								notif.actionURL[0] === '/'
									? history.push(notif.actionURL)
									: (window.location.href = notif.actionURL)
							}
						}}
					>
						<span className="notificationLabel">
							<img
								src={
									Object.values(NotificationType).includes(notif.type)
										? notificationIcons[notif.type]
										: notificationWelcome
								}
								className="notificationIcon"
							/>
							{notif.title}
						</span>
						<span className="notificationActionContainer">
							{`${getDateDiff(new Date(notif.createdDate), new Date())}d`}
							<button
								className="clearButton noMobile"
								onClick={(event) => {
									event.stopPropagation()
									dispatch(clearNotification(notif.notificationID))
								}}
							>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</span>
					</span>
				))}
			{notifications?.length ? (
				<div className="clearAllContainer">
					<NotificationsClear />
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

const getDateDiff = (startDate: Date, endDate: Date) => {
	const startUTC = Date.UTC(
		startDate.getFullYear(),
		startDate.getMonth(),
		startDate.getDate()
	)
	const endUTC = Date.UTC(
		endDate.getFullYear(),
		endDate.getMonth(),
		endDate.getDate()
	)
	return Math.floor((endUTC - startUTC) / (1000 * 60 * 60 * 24))
}

export default NotificationList
