import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'

export interface ProductSku {
	productSkuID: number
	productID: number
	name: string
	inStock: boolean
}

export interface Product {
	productID: number
	name: string
	brand?: string
	type: string
	default: boolean
	price: number
	status: string
	inStock: boolean
	skus: ProductSku[]
}

export const fetchAllProducts = createAsyncThunk(
	'products/fetchAllProducts',
	async () => {
		const productRes = await beam.get('/api/fetchAllProducts')
		return { products: productRes.response.data }
	}
)

export const fetchSkusByProduct = createAsyncThunk(
	'products/fetchSkusByProduct',
	async () => {
		const productSkuRes = await beam.get('/api/fetchSkusByProduct')
		return { skus: productSkuRes.response.data }
	}
)

const productList: Product[] = []
const productsSlice = createSlice({
	name: 'products',
	reducers: {},
	initialState: {
		list: productList,
		defaultProductId: '',
	},
	extraReducers: (builder) => {
		builder.addCase(
			fetchAllProducts.fulfilled,
			(state: any, { payload }: any) => {
				const { products = [] }: { products: Product[] } = payload
				state.list = products
				const defaultProduct = products.find((product: any) => product.default)
				state.defaultProductId = defaultProduct?.productID
			}
		)
	},
})

export default productsSlice.reducer
