import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './Counter.css'

interface CounterProps {
	value: number
	setValue(value: number): void
	min: number
	max: number
}

export default function Counter({ value, setValue, min, max }: CounterProps) {
	function decrement() {
		if (min !== undefined && value - 1 < min) {
			return
		}
		setValue(value - 1)
	}

	function increment() {
		if (max !== undefined && value + 1 > max) {
			return
		}
		setValue(value + 1)
	}

	return (
		<span className="Counter">
			<button className="button plain minusButton" onClick={decrement}>
				<FontAwesomeIcon icon={faMinus} />
			</button>

			<button className="button plain number">{value}</button>

			<button className="button plain plusButton" onClick={increment}>
				<FontAwesomeIcon icon={faPlus} />
			</button>
		</span>
	)
}
