import React, { useEffect, useState } from 'react'
import './MyPresetsTab.css'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { UserPreset } from '../../../Redux/Slices/presetsSlice'
import TabPresetsList from './PresetsList/TabPresetsList'
import { CartItem, updateCartItem } from '../../../Redux/Slices/cartSlice'
import { State } from '../../../Redux/store'

interface MyPresetsProps {
	setVisible: (visible: boolean) => void
	cartItem: CartItem
	newlyCreatedPreset?: boolean
	setNewlyCreatedPreset?: (visible: boolean) => void
}

export default function MyPresets({
	setVisible,
	cartItem,
	newlyCreatedPreset,
	setNewlyCreatedPreset,
}: MyPresetsProps) {
	const presets = useSelector((s: State) => s.presets.list)
	const [filteredPresets, setFilteredPresets] = useState<UserPreset[]>([])
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [selectedPreset, setSelectedPreset] = useState<number | undefined>(
		cartItem.presetID || undefined
	)
	const dispatch = useDispatch()

	useEffect(() => {
		let tempPresetsList = [] as UserPreset[]
		if (!searchQuery) {
			tempPresetsList = presets
		} else {
			tempPresetsList = presets.filter((preset: UserPreset) => {
				return preset.presetName
					.toLowerCase()
					.includes(searchQuery.toLowerCase())
			})
		}

		setFilteredPresets(
			[...tempPresetsList]
				.sort((a, b) => (a?.createdDate! > b?.createdDate! ? -1 : 1))
				.slice(0, 4)
		)
	}, [presets, searchQuery])

	useEffect(() => {
		if (newlyCreatedPreset) {
			setNewlyCreatedPreset && setNewlyCreatedPreset(false)
			const newPreset = filteredPresets[0]
			dispatch(
				updateCartItem({
					id: cartItem.id,
					updates: {
						specType: newPreset.presetName,
						hollowValue: newPreset.hollowValue,
						radiusValue: newPreset.radiusValue,
						pitchValue: newPreset.pitchValue,
						presetID: newPreset.presetID,
					},
				})
			)
			setSelectedPreset(newPreset.presetID)
		}
	}, [filteredPresets])

	return (
		<div className="MyPresetsTab">
			<div className="presetsContainer">
				<div className="PresetsSearchWrapper">
					<FontAwesomeIcon className="PresetsSearchIcon" icon={faSearch} />
					<input
						className="input"
						type="text"
						placeholder="Search Preset"
						onChange={(e) => {
							setSearchQuery(e.target.value)
						}}
					/>
				</div>
				<div className="PresetsList">
					<TabPresetsList
						presets={filteredPresets}
						selectedPreset={selectedPreset}
						setSelectedPreset={setSelectedPreset}
						cartItem={cartItem}
					/>
				</div>
			</div>

			<button className="button neutral" onClick={() => setVisible(false)}>
				Done
			</button>
		</div>
	)
}
