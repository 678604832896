import React, { Component } from 'react'
import ErrorOverlay from './ErrorOverlay/ErrorOverlay'

interface ErrorBoundaryState {
	hasError: boolean
	appError: string
}

interface ErrorBoundaryProps {
	children: React.ReactNode
}

export default class ErrorBoundary extends Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props)
		this.state = {
			hasError: false,
			appError: '',
		}
	}

	static getDerivedStateFromError(appError: string) {
		return {
			hasError: true,
			appError,
		}
	}

	render() {
		const { hasError, appError } = this.state
		return hasError ? <ErrorOverlay appError={appError} /> : this.props.children
	}
}
