import React from 'react'
import './Option.css'

interface OptionsPropsI {
	optionNumber: number
	title: string
	children: React.ReactNode
}

export default function Option({
	optionNumber,
	title,
	children,
}: OptionsPropsI) {
	return (
		<div className="optionsSection">
			<h2 className="hemi optionTitle">
				<span>Option {optionNumber}:</span> {title}
			</h2>
			<div className="optionContent">{children}</div>
		</div>
	)
}
