import React, { useEffect, useMemo, useState } from 'react'
import './MyAccount.css'
import { useSelector, useDispatch } from 'react-redux'
import Footer from '../../Components/Footer/Footer'
import MainContainer from '../../Components/MainContainer/MainContainer'
import AccountRow, { ActionType } from './AccountRow/AccountRow'
import { NotificationOptionType } from '../../@types/Notification'
import {
	defaultImageUrlSelector,
	ImageOption,
} from '../../Redux/Slices/optionsSlice'
import { updatePlayerImage } from '../../Redux/Slices/activeUserSlice'
import { useHistory } from 'react-router-dom'
import VerifyEmailPopup from '../../Popups/VerifyEmailPopup/VerifyEmailPopup'
import Order from '../../@types/Order'
import CardsPopup from '../../Popups/CardsPopup/CardsPopup'
import { fetchCards } from '../../Redux/Slices/cardsSlice'
import Conditional from '../../Components/Conditional/Conditional'
import { State } from '../../Redux/store'
import ProfileImageSelector from '../../Components/ProfileImageSelector/ProfileImageSelector'

export default function MyAccount() {
	const dispatch = useDispatch()
	const history = useHistory()
	const [verifyPopupOpen, setVerifyPopupOpen] = useState(false)
	const [cardsPopupOpen, setCardsPopupOpen] = useState(false)

	useEffect(() => {
		dispatch(fetchCards())
	}, [])

	const user = useSelector((s: State) => s.activeUser)
	const profileImage = useSelector((s: State) => s.activeUser.data.imageURL)
	const profileID = useSelector((s: State) => s.activeUser.data.profileID)
	const defaultImageUrl = useSelector(defaultImageUrlSelector)
	const orders = useSelector((s: State) => s.orders.list) as Order[]
	const cards = useSelector((s: State) => s.cards.cards)
	const presets = useSelector((s: State) => s.presets.list)

	const requiresEmailVerification = useMemo(
		() => !Object.keys(user.account).length || !user.account.emailVerified,
		[user]
	)

	return (
		<div className="MyAccount">
			{requiresEmailVerification && (
				<VerifyEmailPopup
					visible={verifyPopupOpen}
					setVisible={setVerifyPopupOpen}
				/>
			)}

			<CardsPopup visible={cardsPopupOpen} setVisible={setCardsPopupOpen} />

			<MainContainer style={{ maxWidth: '1000px', paddingBottom: '50px' }}>
				<div className="header">
					<div className="spacer noMobile"></div>
					<h1 className="title hemi">My Account</h1>
					<ProfileImageSelector
						image={profileImage}
						defaultImage={defaultImageUrl}
						selectHandler={(img: ImageOption) => {
							dispatch(updatePlayerImage(img.profileImageId))
						}}
					/>
				</div>

				<div className="accountData">
					<AccountRow
						label="First Name"
						field="playerFirstName"
						user={user}
						actionType={ActionType.AccountPopup}
					/>
					<AccountRow
						label="Last Name"
						field="playerLastName"
						user={user}
						actionType={ActionType.AccountPopup}
					/>
					<AccountRow
						label="Phone Number"
						field="phone"
						user={user}
						actionType={ActionType.AccountPopup}
					/>
					<AccountRow
						label="Email"
						field="playerEmail"
						user={user}
						actionType={
							requiresEmailVerification ? ActionType.Custom : undefined
						}
						customAction={() => {
							if (requiresEmailVerification) {
								setVerifyPopupOpen(true)
							}
						}}
						customLabel={requiresEmailVerification ? 'Verify' : ''}
					/>
					<AccountRow
						label="Postal Code"
						field="postalCode"
						user={user}
						actionType={ActionType.AccountPopup}
					/>
					<AccountRow
						label="Total Orders"
						field=""
						customValue={orders.length}
						user={user}
						customLabel="View Order History >"
						customAction={() => history.push('/MyOrders')}
						actionType={ActionType.Custom}
					/>
					<AccountRow
						label="Presets"
						field=""
						customValue={presets.length}
						user={user}
						customLabel="View Presets >"
						customAction={() => history.push('/MyPresets')}
						actionType={ActionType.Custom}
					/>

					<Conditional condition={profileID} fallback={<></>}>
						<AccountRow
							label="Credit Cards"
							field=""
							customValue={cards.length}
							user={user}
							customLabel="View Cards >"
							customAction={() => setCardsPopupOpen(true)}
							actionType={ActionType.Custom}
						/>
					</Conditional>

					<AccountRow
						label="Receive Notifications by"
						field={NotificationOptionType.Method}
						user={user}
						actionType={ActionType.NotificationDropdown}
					/>
					<AccountRow
						label="Show Notifications"
						field={NotificationOptionType.Preview}
						user={user}
						actionType={ActionType.NotificationDropdown}
					/>
				</div>
			</MainContainer>

			<Footer />
		</div>
	)
}
