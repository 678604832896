import React from 'react'
import {
	faFacebook,
	faInstagram,
	faTwitter,
	faYoutube,
	IconDefinition,
} from '@fortawesome/free-brands-svg-icons'
import DesktopFooter from './DesktopFooter/DesktopFooter'
import MobileFooter from './MobileFooter/MobileFooter'
import { useSelector } from 'react-redux'
import { State } from '../../Redux/store'

const { REACT_APP_STATIC_SITE_URL } = process.env
export interface FooterProps {
	policyLinks: { text: string; link: string }[]
	infoLinks: { text: string; link: string }[]
	socialLinks: { icon: IconDefinition; link: string }[]
}

export default function Footer() {
	const screenSize = useSelector((s: State) => s.view.screenSize)

	const policyLinks = [
		{
			text: 'Terms of Service',
			link: `${REACT_APP_STATIC_SITE_URL}/terms-of-service`,
		},
		{
			text: 'Privacy Policy',
			link: `${REACT_APP_STATIC_SITE_URL}/privacy-policy`,
		},
		{
			text: 'Returns and Refund Policy',
			link: `${REACT_APP_STATIC_SITE_URL}/return-policy`,
		},
	]

	const infoLinks = [
		{ text: 'What We Do', link: `${REACT_APP_STATIC_SITE_URL}` },
		{ text: 'The Science', link: `${REACT_APP_STATIC_SITE_URL}/#science` },
		{ text: 'I.S.P.', link: `${REACT_APP_STATIC_SITE_URL}/#isp` },
		{ text: 'Benefits', link: `${REACT_APP_STATIC_SITE_URL}/#benefits` },
		{ text: 'Our Investors', link: `${REACT_APP_STATIC_SITE_URL}/#investors` },
		{ text: 'Locations', link: `${REACT_APP_STATIC_SITE_URL}/#locations` },
		{ text: 'Contact Us', link: `${REACT_APP_STATIC_SITE_URL}/#footer` },
	]

	const socialLinks = [
		{ icon: faTwitter, link: 'https://twitter.com/skatescribe?lang=en' },
		{
			icon: faYoutube,
			link: 'https://www.youtube.com/channel/UCI_EjeE61AV2yS8IeiWoFtg',
		},
		{ icon: faInstagram, link: 'https://www.instagram.com/skatescribe' },
		{
			icon: faFacebook,
			link: 'https://www.facebook.com/pages/Skatescribe/541523653039933',
		},
	]

	return screenSize === 'desktop' ? (
		<DesktopFooter
			policyLinks={policyLinks}
			infoLinks={infoLinks}
			socialLinks={socialLinks}
		/>
	) : (
		<MobileFooter
			policyLinks={policyLinks}
			infoLinks={infoLinks}
			socialLinks={socialLinks}
		/>
	)
}
