import React, { ChangeEventHandler, useState } from 'react'
import './CreateAccount.css'
import beam from '../../../../Handlers/beam'
import { FreeButton } from 'react-ui-scaffold'
import SocialLogin from '../SocialLogin/SocialLogin'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import PhoneNumberPopup from '../../../PhoneNumberPopup/PhoneNumberPopup'
import { MsgI, SignUpDataI } from '../../SignInPopup'

interface CreateAccountProps {
	changeHandler: ChangeEventHandler<HTMLInputElement>
	data: any
	loading: boolean
	setActiveTab(value: string): void
	setMessage(msg: MsgI | null): void
	setVisible(value: boolean): void
	setLoading(value: boolean): void
	setData(data: SignUpDataI): void
}

export default function CreateAccount({
	changeHandler,
	data,
	loading,
	setActiveTab,
	setMessage,
	setVisible,
	setLoading,
	setData,
}: CreateAccountProps) {
	const [agreementChecked, setAgreementChecked] = useState(false)
	const [phonePopupVisible, setPhonePopupVisible] = useState(false)

	async function create() {
		if (data.createPassword !== data.confirmPassword) {
			setMessage({
				color: 'var(--negativeAccentColor)',
				text: "Passwords don't match!",
			})

			return
		}

		setLoading(true)

		await beam
			.post('/api/accounts/create', {
				firstName: data.firstName,
				lastName: data.lastName,
				password: data.createPassword,
				email: data.createEmail,
				phone: data.phone,
			})
			.then(({ response }) => {
				setActiveTab('login')
				setMessage({
					text: 'Created account successfully!',
					color: 'var(--positiveAccentColor)',
				})
				setData({ ...data, email: response.user.account.email, password: '' })
			})
			.catch((err) => {
				console.log(err)
				setMessage({
					text: err.response?.error,
					color: 'var(--negativeAccentColor)',
				})
			})

		setLoading(false)
	}

	return (
		<div className="CreateAccount">
			<div className="story">
				<label htmlFor="Firstname">First Name</label>
				<input
					type="text"
					className="input wide"
					placeholder="FirstName"
					data-htmlFor="first name"
					name="firstName"
					value={data.firstName}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="LastName">Last Name</label>
				<input
					type="text"
					className="input wide"
					placeholder="LastName"
					data-htmlFor="last name"
					name="lastName"
					value={data.lastName}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Email</label>
				<input
					type="text"
					className="input wide"
					placeholder="example@email.com"
					data-htmlFor="new email"
					name="createEmail"
					value={data.createEmail}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Phone Number</label>
				<input
					type="text"
					className="input wide"
					placeholder="123-123-1234"
					data-htmlFor="phone"
					name="phone"
					value={data.phone}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Password</label>
				<input
					type="password"
					className="input wide"
					placeholder="Password"
					data-htmlFor="new password"
					name="createPassword"
					value={data.createPassword}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Confirm Password</label>
				<input
					type="password"
					className="input wide"
					placeholder="Password"
					data-htmlFor="new password"
					name="confirmPassword"
					value={data.confirmPassword}
					onChange={changeHandler}
				/>
			</div>

			<div className="agreementContainer">
				<Checkbox checked={agreementChecked} setChecked={setAgreementChecked} />
				<div>
					I agree to the
					<a className="button plain neutral">{' Terms of Service '}</a>
					and
					<a className="button plain neutral">{' Privacy Policy '}</a>
				</div>
			</div>

			<div className="story">
				<FreeButton
					className="button wide"
					loading={loading}
					onClick={create}
					disabled={!agreementChecked}
				>
					Create Account
				</FreeButton>
			</div>

			<div
				className="line"
				style={{ padding: '10px 0', marginTop: '30px' }}
			></div>

			<SocialLogin successHandler={() => setPhonePopupVisible(true)} />
			<PhoneNumberPopup
				visible={phonePopupVisible}
				setVisible={setPhonePopupVisible}
				closeCallback={() => setVisible(false)}
			/>
			<div
				className="story"
				style={{ textAlign: 'center', fontWeight: 'bold' }}
			>
				OR
			</div>

			<div className="story">
				<FreeButton
					className="button plain wide neutral"
					onClick={() => {
						setActiveTab('login')
					}}
				>
					<b>Have an account? Log in</b>
				</FreeButton>
			</div>
		</div>
	)
}
