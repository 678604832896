import fs1 from './../../../../Assets/photos/bladesize/find-size-1.jpg'
import fs2 from './../../../../Assets/photos/bladesize/find-size-2.jpg'
import fs3 from './../../../../Assets/photos/bladesize/find-size-3.jpg'

export const data = {
	optionNumber: 2,
	title: 'Find Size on Blade Holder',
	content: {
		section1: {
			img: fs1,
			altImg: 'Find Size on Blade Holder',
			description:
				'Trace your finger along the rivets near the heel of the skate, shown in the image. In between the first two rivets you will feel your stamped or embossed blade size.',
		},
		section2: [
			{
				title: 'CCM Blade Holder',
				img: fs2,
				altImg: 'CCM Blade Holder',
			},
			{
				title: 'Bauer Blade Holder',
				img: fs3,
				altImg: 'Bauer Blade Holder',
			},
		],
		section3: {
			text: 'These are closeups of the blade size. The 3-digit number is your blade size. The "L" is not needed, as it simply stands for "left".',
		},
	},
}
