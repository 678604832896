import React, { useMemo } from 'react'

import './PresetIcon.css'

import customSpecsIcon from '../../Assets/graphics/custom-specs.svg'
import balancedPresetIcon from '../../Assets/graphics/balanced-preset.svg'
import agilityPresetIcon from '../../Assets/graphics/agility-preset.svg'
import speedPresetIcon from '../../Assets/graphics/speed-preset.svg'
import defaultPresetIcon from '../../Assets/graphics/specs-placeholder.svg'
import purchaseBladeIcon from '../../Assets/graphics/purchase-placeholder.svg'
import { SpecType } from '../../Redux/Slices/optionsSlice'

const icons = {
	[SpecType.Custom]: customSpecsIcon,
	[SpecType.Balanced]: balancedPresetIcon,
	[SpecType.Agility]: agilityPresetIcon,
	[SpecType.Speed]: speedPresetIcon,
	[SpecType.Advanced]: customSpecsIcon,
}

export default function PresetIcon({
	iconName,
	type,
}: {
	iconName: SpecType
	type: String
}) {
	const icon = useMemo(() => {
		if (type === 'Purchase') {
			return purchaseBladeIcon
		}
		return icons[iconName] || defaultPresetIcon
	}, [])
	return <img className="specTypeIcon" src={icon} alt={`${iconName} icon`} />
}
