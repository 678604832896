import React from 'react'

interface ConditionalProps {
	condition: any
	children?: React.ReactNode
	fallback?: React.ReactNode
	contents?: string | number | React.ReactNode
}

export default function Conditional({
	condition,
	children,
	fallback,
	contents,
}: ConditionalProps) {
	if (!condition) {
		return <>{fallback}</>
	}

	return (
		<>
			{children}
			{contents}
		</>
	)
}
