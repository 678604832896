import React from 'react'
import Conditional from '../../../../Components/Conditional/Conditional'
import { formatCurrency } from '../../../../Utils/formatUtils'
import { FreeButton } from 'react-ui-scaffold'
import { useMemo } from 'react'
interface BillingSidebarProps {
	cart: any
	checkoutHandler(): void
}

export default function BillingSidebar({
	cart,
	checkoutHandler,
}: BillingSidebarProps) {
	const pricesMemo = useMemo(() => {
		return {
			total: formatCurrency(cart.total),
			tax: formatCurrency(cart.tax),
			shipping: formatCurrency(cart.shippingCost),
			finalTotal: formatCurrency(cart.finalTotal),
			discount: cart.discount && formatCurrency(cart.discount),
		}
	}, [cart.total, cart.tax, cart.shippingCost, cart.finalTotal, cart.discount])

	return (
		<>
			<div className="billingSide BillingSidebar fullOnSmallScreens">
				<label className="hemi wide">Price</label>

				<div className="subStory">
					<div className="wide flexY">
						<div className="half">Items:</div>
						<div className="half right">{pricesMemo.total}</div>
					</div>

					<div className="subStory wide flexY">
						<div className="half">Tax:</div>
						<div className="half right">{pricesMemo.tax}</div>
					</div>

					{Boolean(pricesMemo.discount) && (
						<div className="subStory wide flexY">
							<div className="half">Discount:</div>
							<div className="half right">-{pricesMemo.discount}</div>
						</div>
					)}

					<Conditional condition={cart.shippingCost}>
						<div className="subStory wide flexY">
							<div className="half">Shipping:</div>
							<div className="half right">{pricesMemo.shipping}</div>
						</div>
					</Conditional>

					<div className="line"></div>

					<div className="subStory wide flexY">
						<div className="half">
							<b>Final Total: </b>
						</div>
						<div className="half right">
							<span className="red"> {pricesMemo.finalTotal}</span>
						</div>
					</div>

					<div className="subStory">
						<FreeButton className="button wide" onClick={checkoutHandler}>
							Check Out
						</FreeButton>
					</div>
				</div>
			</div>
		</>
	)
}
