import React, { useEffect, useState } from 'react'
import Popup from '../../Components/Popup/Popup'
import { ControlledTabContainer } from 'react-ui-scaffold'
import './RecommendedSpecsPopup.css'
import { questionsConfig, Question } from './questionsConfig'
import QuestionTab from './QuestionTab/QuestionTab'
import DisclaimerPopup from '../DisclaimerPopup/DisclaimerPopup'
import { presetSelector, SpecType } from '../../Redux/Slices/optionsSlice'
import { useSelector } from 'react-redux'
import { getFraction } from '../../Utils/generalUtils'

interface RecommendedSpecsProps {
	visible: boolean
	setVisible?: any
}

export default function RecommendedSpecsPopup({
	visible,
	setVisible,
}: RecommendedSpecsProps) {
	const [activeTab, setActiveTab] = useState<Question>(Question.Height)

	const balancedSpecs = useSelector(presetSelector).find(
		(preset) => preset.name === SpecType.Balanced
	)
	const baseSpecs = {
		hollowValue: getFraction(balancedSpecs?.hollowValue!),
		radiusValue: balancedSpecs?.radiusValue as number,
	}
	const [recoSpecs, setRecoSpecs] = useState(baseSpecs)

	const [showDiscalimer, setShowDisclaimer] = useState(false)

	const [responseMap, setResponseMap] = useState<Object>({})
	const updateResponseMap = (questionType: string, response: string) => {
		setResponseMap({ ...responseMap, [questionType]: response })
	}

	useEffect(() => {
		setActiveTab(Question.Height)
	}, [visible])

	const tabClickHandler = (tab: Question) => setActiveTab(tab)
	const exitHandler = () => setVisible(false)
	const shiftActiveTab = (questionType: string) => {
		if (questionType == Question.Height) {
			setActiveTab(Question.Weight)
		} else if (questionType == Question.Weight) {
			setActiveTab(Question.BiteGlide)
		}
	}

	return (
		<>
			<DisclaimerPopup
				visible={showDiscalimer}
				setVisible={setShowDisclaimer}
				recoSpecs={recoSpecs}
				responses={responseMap}
				recoSpecsExitHandler={exitHandler}
			/>
			<Popup
				title="Recommended Specs"
				visible={visible}
				setVisible={setVisible}
				className="RecommendedSpecsPopup"
				onExit={exitHandler}
			>
				<div className="tabControls">
					{Object.keys(questionsConfig).map((questionType) => (
						<div
							key={questionType}
							className={`tab ${activeTab === questionType && 'active'}`}
							onClick={() => tabClickHandler(questionType as Question)}
						>
							<div className="tabText">{questionType}</div>
						</div>
					))}
				</div>

				<ControlledTabContainer activeTab={activeTab}>
					{Object.entries(questionsConfig).map(
						([questionType, questionInfo]) => (
							<QuestionTab
								key={questionType}
								tabID={questionType}
								questionInfo={questionInfo}
								specs={recoSpecs}
								setSpecs={setRecoSpecs}
								shiftActiveTab={() => shiftActiveTab(questionType)}
								updateResponseMap={(response: string) => {
									updateResponseMap(questionType, response)
								}}
								showDisclaimer={setShowDisclaimer}
							/>
						)
					)}
				</ControlledTabContainer>
			</Popup>
		</>
	)
}
