import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../Redux/store'

interface LoggedOutOnlyProps {
	children: React.ReactNode
}

export default function LoggedOutOnly({ children }: LoggedOutOnlyProps) {
	const { status } = useSelector((s: State) => s.activeUser)

	if (status === 'Logged In') {
		return null
	}

	return <>{children}</>
}
