import React, { useState } from 'react'
import Popup from '../../Components/Popup/Popup'
import { ControlledTabContainer, InfoTab } from 'react-ui-scaffold'
import AccountPerks from './AccountPerks/AccountPerks'
import CreateAccountMin from './CreateAccountMin/CreateAccountMin'
import notificationWelcome from '../../Assets/graphics/notification-welcome.svg'
import './AccountPromptPopup.css'

export enum PromptTab {
	AccountPerks = 'accountPerks',
	CreateAccount = 'createAccount',
	CreationSuccess = 'createSuccess',
}

const titleMap = {
	[PromptTab.AccountPerks]: 'Would You Like to Create an Account',
	[PromptTab.CreateAccount]: 'Create an Account',
	[PromptTab.CreationSuccess]: 'Account Created!',
}

interface AccountPromptPopupProps {
	visible: boolean
	setVisible?: any
}

export default function AccountPromptPopup({
	visible,
	setVisible,
}: AccountPromptPopupProps) {
	const [activeTab, setActiveTab] = useState<PromptTab>(PromptTab.AccountPerks)
	const [message, setMessage] = useState('')

	return (
		<Popup
			title={titleMap[activeTab]}
			visible={visible}
			setVisible={setVisible}
			className="AccountPromptPopup"
		>
			<div
				className="story"
				style={{
					color: 'var(--negativeAccentColor)',
					textAlign: 'center',
				}}
			>
				{message}
			</div>
			<div className="line"></div>
			<ControlledTabContainer activeTab={activeTab}>
				<InfoTab tabID={PromptTab.AccountPerks}>
					<AccountPerks setVisible={setVisible} setActiveTab={setActiveTab} />
				</InfoTab>
				<InfoTab tabID={PromptTab.CreateAccount}>
					<CreateAccountMin
						setActiveTab={setActiveTab}
						setMessage={setMessage}
					/>
				</InfoTab>
				<InfoTab tabID={PromptTab.CreationSuccess}>
					<div className="creationSuccessTab">
						<span className="welcomeContainer">
							<img src={notificationWelcome} />
							Thank you for joining Skatescribe!
						</span>
						<button className="button" onClick={() => setVisible(false)}>
							Close
						</button>
					</div>
				</InfoTab>
			</ControlledTabContainer>
		</Popup>
	)
}
