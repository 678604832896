import React, { useMemo } from 'react'
import {
	faCcVisa,
	faCcMastercard,
	faCcDiscover,
	faCcAmex,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from '../../../../@types/Card'

export interface SavedCardCardProps {
	card: Card
	selectedCard?: Card
	setSelectedCard(card: Card): void
}

export default function SavedCardCard({
	card,
	selectedCard,
	setSelectedCard,
}: SavedCardCardProps) {
	const ccIcon = useMemo(() => {
		switch (card.card_type) {
			case 'VI':
				return faCcVisa
			case 'MC':
				return faCcMastercard
			case 'NN':
				return faCcDiscover
			case 'AM':
				return faCcAmex
			default:
				return null
		}
	}, [card.card_type])
	const selected = card.card_id == selectedCard?.card_id
	return (
		<div
			className={`SavedCardCard flexY ${selected ? 'SelectedCard' : ''}`}
			onClick={() => setSelectedCard(card)}
		>
			<input
				type="radio"
				className="input"
				checked={selected}
				style={{ marginRight: 10 }}
			/>
			<div className="CardDetails">
				{ccIcon && (
					<FontAwesomeIcon size="2x" icon={ccIcon} className="ccIcon" />
				)}
				ending in *{card.number.slice(-4)}
			</div>
		</div>
	)
}
