import React from 'react'
import './MobileCartCard.css'
import { formatCurrency } from '../../../../Utils/formatUtils'
import SpecsCard from '../SpecsCard/SpecsCard'
import RemoveButton from '../RemoveButton/RemoveButton'
import { useMemo } from 'react'
import ServiceCard from '../ServiceCard/ServiceCard'
import QuantityCell from '../QuantityCell/QuantityCell'

interface MobileCartCardProps {
	cartItem: any
}

export default function MobileCartCard({ cartItem }: MobileCartCardProps) {
	const itemPriceMemo = useMemo(
		() => formatCurrency(cartItem.price),
		[cartItem.price]
	)

	return (
		<div className="MobileCartCard flex">
			<ServiceCard row={cartItem} mobileView={true} />
			<SpecsCard row={cartItem} />
			<div className="quantityPriceRow">
				<QuantityCell cell={cartItem.quantity} row={cartItem} />
				<div className="servicePrice">Price: {itemPriceMemo}</div>
			</div>
			<div className="removeRow">
				<RemoveButton row={cartItem} />
			</div>
		</div>
	)
}
