import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Page } from 'react-ui-scaffold'
import serviceISP from '../../Assets/graphics/service-isp.svg'
import servicePurchase from '../../Assets/graphics/service-purchase.svg'
import usePrepareOrder from '../../Hooks/usePrepareOrder'
import BladeWarningPopup from '../../Popups/BladeWarningPopup/BladeWarningPopup'
import { State } from '../../Redux/store'
import './Shop.css'
import { LOCAL_STORAGE } from '../../Utils/generalUtils'

export default function Shop() {
	const prepareOrder = usePrepareOrder()
	const history = useHistory()
	const linkToPurchase = () => {
		history.push({
			pathname: '/Cart',
			search: '?showPurchase=true',
		})
	}
	const [showBladeWarning, setShowBladeWarning] = useState(false)
	const user = useSelector((s: State) => s.activeUser.status)

	useEffect(() => {
		if (
			user !== 'Logged In' &&
			!localStorage.getItem(LOCAL_STORAGE.BLADE_WARNING_POPUP)
		) {
			setShowBladeWarning(true)
			localStorage.setItem(LOCAL_STORAGE.BLADE_WARNING_POPUP, 'true')
		}
	}, [user])

	return (
		<Page className="Shop">
			<BladeWarningPopup
				visible={showBladeWarning}
				setVisible={setShowBladeWarning}
			/>
			<h1 className="hemi title">Shop</h1>
			<h2 className="subtitle">Please choose a service.</h2>
			<div className="serviceOptions">
				<div className="serviceContainer" onClick={() => prepareOrder()}>
					<img src={serviceISP} draggable="false" />
					<h1 className="hemi">
						Integrated Sharpening &amp; Profiling (I.S.P)
					</h1>
					<p className="serviceInfo">
						Get your blades sharpened and profiled with laser technology.
					</p>
				</div>
				<div className="serviceContainer" onClick={linkToPurchase}>
					<img src={servicePurchase} draggable="false" />
					<h1 className="hemi">Purchase New Blades</h1>
					<p className="serviceInfo">
						Purchase new blades from us and get them sharpened and profiled.
					</p>
				</div>
			</div>
		</Page>
	)
}
