import d1 from './../../../../Assets/photos/bladesize/size-chart-desktop-ccm1.png'
import d2 from './../../../../Assets/photos/bladesize/size-chart-desktop-ccm2.png'
import d3 from './../../../../Assets/photos/bladesize/size-chart-desktop-b1.png'
import d4 from './../../../../Assets/photos/bladesize/size-chart-desktop-b2.png'
import m1 from './../../../../Assets/photos/bladesize/size-chart-mobile-ccm1.png'
import m2 from './../../../../Assets/photos/bladesize/size-chart-mobile-ccm2.png'
import m3 from './../../../../Assets/photos/bladesize/size-chart-mobile-b1.png'
import m4 from './../../../../Assets/photos/bladesize/size-chart-mobile-b2.png'

export const data = {
	optionNumber: 1,
	title: 'Find Size on Sizing Chart',
	charts: [
		{
			title: 'CCM Junior Sizing Chart (sizes 1.0 - 5.5)',
			img: d1,
			imgMobile: m1,
			altText: 'CCM Junior Sizing Chart (sizes 1.0 - 5.5)',
			tableRows: 2,
		},
		{
			title: 'CCM Senior Sizing Chart (sizes 6.0 - 13.0)',
			img: d2,
			imgMobile: m2,
			altText: 'CCM Senior Sizing Chart (sizes 6.0 - 13.0)',
			tableRows: 2,
		},
		{
			title: 'Bauer Junior Sizing Chart (sizes 1 - 5.5)',
			img: d3,
			imgMobile: m3,
			altText: 'Bauer Junior Sizing Chart (sizes 1 - 5.5)',
			tableRows: 3,
		},
		{
			title: 'Bauer Senior Sizing Chart (sizes 6 - 12)',
			img: d4,
			imgMobile: m4,
			altText: 'Bauer Senior Sizing Chart (sizes 6 - 12)',
			tableRows: 3,
		},
	],
}
