import React from 'react'
import './ProfileImageSelector.css'
import { MenuButton } from 'react-ui-scaffold'
import { useSelector } from 'react-redux'
import { ImageOption, imageSelector } from '../../Redux/Slices/optionsSlice'

interface ProfileImageSelectorProps {
	image?: string | number
	defaultImage?: string | number
	selectHandler(img: ImageOption): void
}

export default function ProfileImageSelector({
	image,
	defaultImage,
	selectHandler,
}: ProfileImageSelectorProps) {
	return (
		<MenuButton
			menuContent={<ProfileImageMenu selectHandler={selectHandler} />}
			className="ProfileImageSelector"
		>
			<div className="profileImageContainer">
				<span className="changeText">Change</span>
				<img
					className="profileImage"
					src={`${process.env.REACT_APP_SERVER_URL}${image || defaultImage}`}
				/>
			</div>
		</MenuButton>
	)
}

function ProfileImageMenu({
	selectHandler,
}: {
	selectHandler(img: ImageOption): void
}) {
	const imageOptions = useSelector(imageSelector)

	return (
		<div className="ProfileImageMenu">
			{imageOptions.map((img: ImageOption, idx: number) => (
				<img
					key={idx}
					src={`${process.env.REACT_APP_SERVER_URL}${img.uri}`}
					className="imageOption"
					onClick={() => selectHandler(img)}
				/>
			))}
		</div>
	)
}
