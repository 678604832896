import React from 'react'
import './MobileFooter.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FooterProps } from '../Footer'
import { Link } from 'react-router-dom'

export default function MobileFooter({
	policyLinks,
	infoLinks,
	socialLinks,
}: FooterProps) {
	return (
		<div className="MobileFooter">
			<div className="links">
				<div className="left infoContainer">
					{infoLinks.map(({ text, link }, idx) => (
						<a key={idx} href={link} target="_blank" rel="noopener noreferrer">
							{text}
						</a>
					))}
				</div>

				<div className="right">
					{policyLinks.map(({ text, link }, idx) => (
						<a key={idx} href={link} target="_blank" rel="noopener noreferrer">
							{text}
						</a>
					))}
					<div className="socialContainer">
						{socialLinks.map(({ icon, link }, idx) => (
							<a
								key={idx}
								href={link}
								target="_blank"
								rel="noopener noreferrer"
								className="social"
							>
								<FontAwesomeIcon icon={icon} />
							</a>
						))}
					</div>

					<Link to="/Shop">
						<button className="button negative">Shop</button>
					</Link>
				</div>
			</div>

			<span className="copyright">
				© 2020 Skatescribe Corporation. All rights reserved.
			</span>
		</div>
	)
}
