import React, { useEffect, useState } from 'react'
import './Main.css'
import { Route, Switch } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import Navbar from '../../Components/Navbar/Navbar'
import leftBG from '../../Assets/graphics/background-left.svg'
import rightBG from '../../Assets/graphics/background-right.svg'
import Cart from '../Cart/Cart'
import Shop from '../Shop/Shop'
import Checkout from '../Checkout/Checkout'
import OrderDetails from '../OrderDetails/OrderDetails'
import MyAccount from '../MyAccount/MyAccount'
import Notifications from '../Notifications/Notifications'
import PostOrder from '../PostOrder/PostOrder'
import MyOrders from '../MyOrders/MyOrders'
import MyPresets from '../MyPresets/MyPresets'
import ProtectedRoute from '../../Components/ProtectedRoute/ProtectedRoute'
import ErrorBoundary from '../../Components/ErrorBoundary/ErrorBoundary'
import BladeSize from '../BladeSize/BladeSize'
import BladeWarningPopup from '../../Popups/BladeWarningPopup/BladeWarningPopup'
import { useSelector } from 'react-redux'
import { State } from '../../Redux/store'

export default function Main() {
	const [showBladeWarning, setShowBladeWarning] = useState(false)
	const userStatus = useSelector((s: State) => s.activeUser.status)

	useEffect(() => {
		if (
			userStatus === 'Logged In' &&
			localStorage.getItem('loginActions') === 'true'
		) {
			setShowBladeWarning(true)
		}
	}, [userStatus])

	return (
		<div className={'Main'}>
			<BladeWarningPopup
				visible={showBladeWarning}
				setVisible={setShowBladeWarning}
			/>

			<Navbar />

			<ErrorBoundary>
				<Switch>
					<Route path="/Shop" component={Shop} />
					<Route path="/Cart" component={Cart} />
					<Route path="/Checkout" component={Checkout} />
					<Route path="/Orders/:orderID" component={OrderDetails} />
					<ProtectedRoute path="/MyAccount" component={MyAccount} />
					<ProtectedRoute path="/MyOrders" component={MyOrders} />
					<ProtectedRoute path="/MyPresets" component={MyPresets} />
					<ProtectedRoute path="/Notifications" component={Notifications} />
					<Route path="/PostOrder" component={PostOrder} />
					<Route path="/FindBladeSize" component={BladeSize} />
					<Route path="/" component={Dashboard} />
				</Switch>
			</ErrorBoundary>

			<img src={leftBG as string} className="bgImage leftBG noMobile" />
			<img src={rightBG as string} className="bgImage rightBG noMobile" />
		</div>
	)
}
