import React from 'react'
import './PresetsList.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	updateCurrentPreset,
	UserPreset,
} from '../../../../../Redux/Slices/presetsSlice'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import useAddPresetToCart from '../../../../../Hooks/useAddPresetToCart'
import { useDispatch } from 'react-redux'

export default function PresetItem({
	preset,
	setShowIndPopup,
}: {
	preset: UserPreset
	setShowIndPopup(value: boolean): void
}) {
	const dispatch = useDispatch()
	const addPresetToCart = useAddPresetToCart()

	return (
		<div className="PresetsItem">
			<img
				className="PresetImage"
				src={`${process.env.REACT_APP_SERVER_URL}${preset.imageID}`}
			/>
			<div className="PresetsItemContent">
				<div
					className="presetName"
					onClick={() => {
						dispatch(updateCurrentPreset(preset))
						setShowIndPopup(true)
					}}
				>
					{preset.presetName}
				</div>
				<div className="PresetAddToCart">
					<FontAwesomeIcon icon={faCartPlus} />
					<button
						className="button neutral plain"
						onClick={() => {
							addPresetToCart(preset)
						}}
					>
						Add to Cart
					</button>
				</div>
			</div>
		</div>
	)
}
