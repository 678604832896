export enum Question {
	Height = 'Height',
	Weight = 'Weight',
	BiteGlide = 'Bite/Glide',
}

export interface RecoSpecsI {
	radiusValue: number
	hollowValue: number
}

export const questionsConfig = {
	[Question.Height]: {
		question: 'What is your height?',
		options: {
			['Over 6\'4"']: { radiusValue: 1.0 },
			["6'4\" - 6'"]: { radiusValue: 0.5 },
			['5\'11" - 5\'7"']: {},
			['5\'6" - 5\'2"']: { radiusValue: -0.25 },
			['5\'1" - 4\'8"']: { radiusValue: -0.5 },
			['Under 4\'7"']: { radiusValue: -0.75 },
		},
	},
	[Question.Weight]: {
		question: 'How much do you weigh?',
		options: {
			['Over 220lbs']: { radiusValue: 1.5 },
			['220lbs - 180lbs']: { radiusValue: 0.75 },
			['180lbs - 140lbs']: {},
			['140lbs - 100lbs']: { radiusValue: -0.75 },
			['Under 100lbs']: { radiusValue: -1.5 },
		},
	},
	[Question.BiteGlide]: {
		question: 'Do you prefer Bite (more grip) or Slide (less grip)?',
		options: {
			['Lots of Bite']: { hollowValue: -1 / 8 },
			['Bite']: { hollowValue: -1 / 16 },
			['Mixed']: {},
			['Glide']: { hollowValue: 1 / 16 },
			['Lots of Glide']: { hollowValue: 1 / 8 },
		},
	},
}
