import React from 'react'
import './CartTable.css'
import QuickTableRow from './Support/CartTableRow/CartTableRow'

interface QuickTableProps {
	data: any[]
	headers: any
	defaultWidth?: number
	headerStyle: object
	primaryKey: string
	className?: string
	rowClassName: string
	hover?: boolean
}

export default function CartTable({
	data,
	headers,
	primaryKey,
	defaultWidth = 100,
	headerStyle,
	className,
	rowClassName,
	hover,
}: QuickTableProps) {
	const hoverClass = hover ? ' hover ' : ''

	return (
		<div className={'CartTable ' + className}>
			<div className="tableHeaders">
				{Object.keys(headers).map((h) => {
					const wd = headers[h].width || defaultWidth

					return (
						<span
							key={h}
							style={{ ...headerStyle, ...headers[h].headerStyle, width: wd }}
							className="cellContainer headerCell"
						>
							<span className="cell">{headers[h].title}</span>
						</span>
					)
				})}
			</div>

			<div className="tableBody">
				{data.map((r, idx) => {
					return (
						<QuickTableRow
							index={idx}
							className={rowClassName + hoverClass}
							headers={headers}
							key={r[primaryKey]}
							row={r}
							defaultWidth={defaultWidth}
						/>
					)
				})}
			</div>
		</div>
	)
}
