import React, { useState, useEffect } from 'react'
import Popup from '../../Components/Popup/Popup'
import { ControlledTabContainer, InfoTab } from 'react-ui-scaffold'
import BasicSpecs from './BasicSpecs/BasicSpecs'
import MyPresetsTab from './MyPresetsTab/MyPresetsTab'
import './ChooseSpecsPopup.css'
import AdvancedSpecs from './AdvancedSpecs/AdvancedSpecs'
import CustomSpecs from './CustomSpecs/CustomSpecs'
import { useSelector } from 'react-redux'
import { SpecType } from '../../Redux/Slices/optionsSlice'
import { State } from '../../Redux/store'
import useValidateCartItem from '../../Hooks/useValidateCartItem'
import RecommendedSpecsPopup from '../RecommendedSpecsPopup/RecommendedSpecsPopup'
import { PresetsContext, RecoSpecsContext } from './chooseSpecsContexts'
import { CartItem } from '../../Redux/Slices/cartSlice'

export enum SpecTab {
	MyPresets = 'My Presets',
	Basic = 'Basic',
	Advanced = 'Advanced',
	Custom = 'Custom',
}

interface ChooseSpecsProps {
	visible: boolean
	setVisible(value: boolean): void
	cartItem: CartItem
}

export default function ChooseSpecs({
	visible,
	setVisible,
	cartItem,
}: ChooseSpecsProps) {
	const [activeTab, setActiveTab] = useState<SpecTab>(SpecTab.Basic)
	const [showRecoSpecs, setShowRecoSpecs] = useState(false)
	const [newlyCreatedPreset, setNewlyCreatedPreset] = useState(false)
	const { status } = useSelector((s: State) => s.activeUser)

	const validateCartItem = useValidateCartItem(cartItem.id)

	const isTabDisabled = (tab: SpecTab) => {
		return tab === SpecTab.MyPresets && status !== 'Logged In'
	}

	useEffect(() => {
		if (Object.values(SpecType).includes(cartItem.specType)) {
			const basicPresets = [SpecType.Agility, SpecType.Balanced, SpecType.Speed]
			basicPresets.includes(cartItem.specType)
				? setActiveTab(SpecTab.Basic)
				: setActiveTab(cartItem.specType as unknown as SpecTab)
		} else {
			setActiveTab(SpecTab.MyPresets)
		}
	}, [visible])

	function exitHandler() {
		if (cartItem.specType === SpecType.Advanced) {
			validateCartItem()
		}
		setVisible(false)
	}

	return (
		<Popup
			title="Choose Sharpening Specs"
			visible={visible}
			setVisible={setVisible}
			className="ChooseSpecsPopup"
			onExit={exitHandler}
		>
			<RecoSpecsContext.Provider
				value={{
					chooseSpecsExitHandler: () => setVisible(false),
					cartItemId: cartItem.id,
				}}
			>
				<RecommendedSpecsPopup
					visible={showRecoSpecs}
					setVisible={setShowRecoSpecs}
				/>
			</RecoSpecsContext.Provider>

			<div className="tabControls">
				{Object.values(SpecTab).map((tab) => (
					<div
						key={tab}
						className={`
									tab 
									${activeTab === tab && 'active'}
									${isTabDisabled(tab) && 'disabled'}
								`}
						onClick={() => setActiveTab(tab)}
					>
						<div className="tabText">{tab}</div>
					</div>
				))}
			</div>

			<ControlledTabContainer activeTab={activeTab}>
				<InfoTab tabID={SpecTab.MyPresets}>
					<MyPresetsTab
						cartItem={cartItem}
						setVisible={setVisible}
						newlyCreatedPreset={newlyCreatedPreset}
						setNewlyCreatedPreset={setNewlyCreatedPreset}
					/>
				</InfoTab>

				<InfoTab tabID={SpecTab.Basic}>
					<BasicSpecs
						cartItem={cartItem}
						setVisible={setVisible}
						showRecoSpecs={setShowRecoSpecs}
					/>
				</InfoTab>

				<InfoTab tabID={SpecTab.Advanced}>
					<PresetsContext.Provider
						value={{
							setNewlyCreatedPreset,
							setActiveTab,
						}}
					>
						<AdvancedSpecs
							cartItem={cartItem}
							setVisible={setVisible}
							showRecoSpecs={setShowRecoSpecs}
						/>
					</PresetsContext.Provider>
				</InfoTab>

				<InfoTab tabID={SpecTab.Custom}>
					<CustomSpecs
						cartItem={cartItem}
						setVisible={setVisible}
						showRecoSpecs={setShowRecoSpecs}
						currentSpecType={cartItem.specType}
					/>
				</InfoTab>
			</ControlledTabContainer>
		</Popup>
	)
}
