import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '../../Components/Popup/Popup'
import { deleteCard } from '../../Redux/Slices/cardsSlice'
import { FreeButton } from 'react-ui-scaffold'
import './CardsPopup.css'
import AddCardForm from './Support/AddCardForm'
import { State } from '../../Redux/store'
import SavedCard from './Support/SavedCard'
import Card from '../../@types/Card'

interface CardsPopupProps {
	visible: boolean
	setVisible(value: boolean): void
}

export default function CardsPopup({ visible, setVisible }: CardsPopupProps) {
	const cards = useSelector((s: State) => s.cards.cards)
	const dispatch = useDispatch()
	const [view, setView] = useState('all')
	const [toDelete, setToDelete] = useState<Card>()
	const [showCardExists, setShowCardExists] = useState(false)

	useEffect(() => {
		setView('all')
	}, [visible])

	const handleDeleteCard = (cardID: number) => {
		dispatch(deleteCard({ cardID, callback: () => setView('deleteSuccess') }))
	}

	const renderCurrentView = () => {
		switch (view) {
			case 'all': {
				return (
					<>
						<div className="flexY wide" style={{ justifyContent: 'center' }}>
							<div className="wide" style={{ justifyContent: 'center' }}>
								{cards.map((card: any) => {
									return (
										<SavedCard
											key={card.card_id}
											card={card}
											handleDelete={() => {
												setToDelete(card)
												setView('confirmDelete')
											}}
										/>
									)
								})}
							</div>
							<FreeButton className="button" onClick={() => setView('addCard')}>
								Add Card
							</FreeButton>
						</div>
					</>
				)
			}
			case 'confirmDelete': {
				return (
					<div className="flexY">
						<div className="DecisionButton">
							<FreeButton
								className="button hollow wide"
								onClick={() => setView('all')}
							>
								Cancel
							</FreeButton>
						</div>
						<div className="DecisionButton">
							<FreeButton
								className="button wide"
								onClick={() => handleDeleteCard(Number(toDelete?.card_id))}
							>
								Yes, Delete Card
							</FreeButton>
						</div>
					</div>
				)
			}
			case 'deleteSuccess': {
				return (
					<div className="flexY" style={{ justifyContent: 'center' }}>
						<div
							style={{ width: '100%', textAlign: 'center', padding: '20px 0' }}
						>
							This card has been deleted
						</div>
						<FreeButton className="button" onClick={() => setView('all')}>
							Back
						</FreeButton>
					</div>
				)
			}
			case 'addCard': {
				return <AddCardForm setView={setView} />
			}
			case 'addSuccess': {
				return (
					<div className="flexY" style={{ justifyContent: 'center' }}>
						<div
							style={{ width: '100%', textAlign: 'center', padding: '20px 0' }}
						>
							This card has been added
						</div>
						<FreeButton className="button" onClick={() => setView('all')}>
							Back
						</FreeButton>
					</div>
				)
			}
		}
	}
	return (
		<div>
			<Popup
				visible={showCardExists}
				setVisible={setShowCardExists}
				title="You've already saved this card"
				className="ExistingCardPopup"
			>
				<div className="line"></div>

				<div className="story" style={{ textAlign: 'center' }}>
					{
						"Since you've already saved this card, you cannot save it again. Please Select the card and try again."
					}
				</div>
				<div className="BackButtonWrapper">
					<FreeButton
						className="button"
						onClick={() => setShowCardExists(false)}
					>
						Back
					</FreeButton>
				</div>
			</Popup>
			<Popup
				title="Credit Cards"
				visible={visible}
				setVisible={setVisible}
				className="CardsPopup"
			>
				<div>{renderCurrentView()}</div>
			</Popup>
		</div>
	)
}
