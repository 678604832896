import React from 'react'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FreeButton } from 'react-ui-scaffold'
import auth from '../../../../Handlers/auth'

interface SocialLoginProps {
	successHandler(): void
}

export default function SocialLogin({ successHandler }: SocialLoginProps) {
	function googleHandler() {
		auth.loginWithGoogle().then(() => {
			successHandler()
		})
	}

	function facebookHandler() {
		auth.loginWithFacebook().then(() => {
			successHandler()
		})
	}

	return (
		<div>
			<div className="story">
				<FreeButton className="button wide" onClick={googleHandler}>
					<span className="authIcon">
						<FontAwesomeIcon icon={faGoogle} />
					</span>
					Continue with Google
				</FreeButton>
			</div>

			<div className="story" style={{ display: 'none' }}>
				<FreeButton className="button wide" onClick={facebookHandler}>
					<span className="authIcon facebook">
						<FontAwesomeIcon icon={faFacebook} />
					</span>
					Continue with Facebook
				</FreeButton>
			</div>
		</div>
	)
}
