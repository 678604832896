import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import { deleteUserPreset } from '../../../../Redux/Slices/presetsSlice'
import { State } from '../../../../Redux/store'
import { PresetPopupViews } from '../../PresetPopup'

// Interface for props.
interface DeletePresetPropsI {
	visible: boolean
	setVisible(value: boolean): void
	setView(view: PresetPopupViews): void
}

export default function DeletePreset({
	visible,
	setVisible,
	setView,
}: DeletePresetPropsI) {
	const dispatch = useDispatch()
	const preset = useSelector((s: State) => s.presets.currentPreset)
	const [errMsg, setErrMsg] = useState('')

	return (
		<Popup
			title="Delete Preset?"
			visible={visible}
			setVisible={setVisible}
			className="PresetAddToCartPopup"
		>
			<div
				className="flexY EditPresetPopupWrapper"
				style={{ justifyContent: 'center' }}
			>
				<div className="wide EditPresetContentWrapper">
					<div className="EditPresettPopupTitle">
						<div className="EditPresettPopupName">
							<span style={{ fontWeight: 500 }}>
								Are you sure you&lsquo;d like to delete the preset{' '}
							</span>
							{preset?.presetName}?
						</div>
					</div>
					<div className="negativeColor alignTextCenter">{errMsg}</div>
				</div>
				<div className="EditPresetPopupBtnWrapper">
					<div
						className="PresetPopupCancelBtn"
						onClick={() => {
							setView(PresetPopupViews.view)
						}}
					>
						Cancel
					</div>
					<FreeButton
						className="button negative"
						onClick={() => {
							try {
								dispatch(deleteUserPreset(preset!.presetID!))
								setView(PresetPopupViews.deleteSuccess)
								setErrMsg('')
							} catch (e) {
								setErrMsg('Some error occured. Please try again.')
							}
						}}
					>
						Yes, Delete
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
