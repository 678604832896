import React, { useContext, useState } from 'react'
import './AdvancedSpecs.css'
import SpecSliders from './SpecSliders/SpecSliders'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../Redux/store'
import { CartItem, updateCartItem } from '../../../Redux/Slices/cartSlice'
import { SpecType } from '../../../Redux/Slices/optionsSlice'
import useValidateCartItem from '../../../Hooks/useValidateCartItem'
import SignInPopup, { MsgI } from '../../SignInPopup/SignInPopup'
import SpecsHistoryPopup from '../../SpecsHistoryPopup/SpecsHistoryPopup'
import PresetPopup, { PresetPopupViews } from '../../PresetsPopup/PresetPopup'
import { PresetsContext } from '../chooseSpecsContexts'

interface AdvancedSpecsProps {
	cartItem: CartItem
	setVisible: (visible: boolean) => void
	showRecoSpecs: (recoSpecsVisible: boolean) => void
}

export default function AdvancedSpecs({
	cartItem,
	setVisible,
	showRecoSpecs,
}: AdvancedSpecsProps) {
	const dispatch = useDispatch()
	const validateCartItem = useValidateCartItem(cartItem.id)

	const { status } = useSelector((s: State) => s.activeUser)
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const { setNewlyCreatedPreset, setActiveTab } = useContext(PresetsContext)

	const [loginPopupVisible, setLoginPopupVisible] = useState(false)
	const [loginPopupMessage, setLoginPopupMessage] = useState<MsgI>()
	const [showSpecsHistory, setShowSpecsHistory] = useState(false)
	const [showPresetPopup, setShowPresetPopup] = useState(false)

	const handleSavePreset = () => {
		if (status !== 'Logged In') {
			setLoginPopupMessage({ text: 'Please sign in to save a preset' })
			return setLoginPopupVisible(true)
		}
		setShowPresetPopup(true)
	}

	const handleChooseFromHistory = () => {
		if (status !== 'Logged In') {
			setLoginPopupMessage({
				text: 'Please sign in to access your shopping history',
			})
			return setLoginPopupVisible(true)
		}
		setShowSpecsHistory(true)
	}

	return (
		<div className="chooseTab AdvancedSpecs flexX">
			<SignInPopup
				visible={loginPopupVisible}
				setVisible={setLoginPopupVisible}
				defaultMessage={loginPopupMessage}
			/>
			<SpecsHistoryPopup
				visible={showSpecsHistory}
				setVisible={setShowSpecsHistory}
				cartItemId={cartItem.id}
			/>
			<PresetPopup
				visible={showPresetPopup}
				setVisible={setShowPresetPopup}
				currentView={PresetPopupViews.add}
				setNewlyCreated={setNewlyCreatedPreset}
				setActiveTab={setActiveTab}
				newPreset={cartItem}
			/>

			<SpecSliders cartItem={cartItem} />
			<div className="options">
				<button
					className={`button ${screenSize !== 'mobile' && 'second'}`}
					onClick={() => {
						dispatch(
							updateCartItem({
								id: cartItem.id,
								updates: {
									specType: SpecType.Advanced,
								},
							})
						)
						validateCartItem()
						setVisible(false)
					}}
				>
					Done
				</button>

				<button
					className={`
						button neutral hollow first 
						${screenSize !== 'mobile' && 'first'}
					`}
					onClick={() => handleSavePreset()}
				>
					Save Settings as Preset
				</button>

				<button
					className={`
						button neutral hollow 
						${screenSize !== 'mobile' && 'third'}
					`}
					onClick={() => handleChooseFromHistory()}
				>
					Choose from history
				</button>

				<button
					className={`
						button neutral hollow 
						${screenSize !== 'mobile' && 'fourth'}
					`}
					onClick={() => showRecoSpecs(true)}
				>
					Recommend Specs
				</button>
			</div>
		</div>
	)
}
