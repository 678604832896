import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { addToCart, updateCartItem } from '../Redux/Slices/cartSlice'
import { UserPreset } from '../Redux/Slices/presetsSlice'
import { State } from '../Redux/store'

export default function useAddPresetToCart() {
	const history = useHistory()
	const dispatch = useDispatch()
	const cart = useSelector((s: State) => s.cart.contents)
	const [preset, setPreset] = useState<UserPreset>()
	const [shouldUpdateCart, setShouldUpdateCart] = useState<boolean>(false)
	const [shouldRedirect, setShouldRedirect] = useState<boolean>(true)

	useEffect(() => {
		if (cart && shouldUpdateCart) {
			setShouldUpdateCart(false)
			const lastItem = cart[cart.length - 1] as any
			const id = lastItem.id
			updateCart(id)
			shouldRedirect && history.push('/Cart')
			setShouldRedirect(true)
		}
	}, [cart])

	const addPresetToCart = (preset: UserPreset, redirect?: boolean) => {
		const cartItems = [
			{
				type: 'Profile and Sharpen',
				productID: 1,
				quantity: 1,
			},
		]
		dispatch(
			addToCart({
				items: cartItems,
				useLastConfig: false,
			})
		)

		setPreset(preset)
		setShouldUpdateCart(true)
		if (redirect != undefined) {
			setShouldRedirect(redirect)
		}
	}

	const updateCart = (cartItemId: string) => {
		dispatch(
			updateCartItem({
				id: cartItemId,
				updates: {
					specType: preset!.presetName,
					hollowValue: preset!.hollowValue,
					radiusValue: preset!.radiusValue,
					pitchValue: preset!.pitchValue,
					presetID: preset!.presetID,
				},
			})
		)
	}

	return addPresetToCart
}
