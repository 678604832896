import React, { useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import { PresetPopupViews } from '../../PresetPopup'
import HollowValue from '../HollowValue/HollowValue'
import RadiusValue from '../RadiusValue/RadiusValue'
import PitchValue from '../PitchValue/PitchValue'
import { State } from '../../../../Redux/store'
import { useDispatch, useSelector } from 'react-redux'
import {
	setCurrentPresetNull,
	updateCurrentPreset,
} from '../../../../Redux/Slices/presetsSlice'

// Interface for props.
interface PresetSpecsI {
	visible: boolean
	setVisible(value: boolean): void
	setView(view: PresetPopupViews): void
}

export default function PresetSpecs({
	visible,
	setVisible,
	setView,
}: PresetSpecsI) {
	const dispatch = useDispatch()

	const currentPreset = useSelector(
		(state: State) => state.presets.currentPreset
	)
	const [newPresetHollowValue, setNewPresetHollowValue] = useState<string>(
		currentPreset?.hollowValue || ''
	)
	const [newPresetPitchValue, setNewPresetPitchValue] = useState<
		number | undefined
	>(currentPreset?.pitchValue)
	const [newPresetRadiusValue, setNewPresetRadiusValue] = useState<
		number | undefined
	>(currentPreset?.radiusValue)

	const handleDone = () => {
		if (currentPreset) {
			dispatch(
				updateCurrentPreset({
					hollowValue: newPresetHollowValue,
					pitchValue: newPresetPitchValue,
					radiusValue: newPresetRadiusValue,
				})
			)
		} else {
			dispatch(
				updateCurrentPreset({
					hollowValue: newPresetHollowValue,
					pitchValue: newPresetPitchValue,
					radiusValue: newPresetRadiusValue,
					presetName: '',
					imageID: '',
				})
			)
		}
		setView(PresetPopupViews.add)
	}

	return (
		<Popup
			title="Choose Preset Specs"
			visible={visible}
			setVisible={() => {
				setVisible(false)
				dispatch(setCurrentPresetNull())
			}}
			className="PresetEditPopup"
		>
			<div
				className="flexY EditPresetPopupWrapper"
				style={{ justifyContent: 'center' }}
			>
				<div className="wide EditPresetContentWrapper">
					<div className="EditPresettPopupData">
						<div className="DataItemWrapper">
							<HollowValue
								hollowValue={newPresetHollowValue}
								setHollowValue={setNewPresetHollowValue}
							/>
						</div>
					</div>

					<div className="EditPresettPopupData">
						<div className="DataItemWrapper">
							<RadiusValue
								radiusValue={newPresetRadiusValue}
								setRadiusValue={setNewPresetRadiusValue}
							/>
						</div>
					</div>

					<div className="EditPresettPopupData">
						<div className="DataItemWrapper">
							<PitchValue
								pitchValue={newPresetPitchValue}
								setPitchValue={setNewPresetPitchValue}
							/>
						</div>
					</div>
				</div>
				<div className="EditPresetPopupBtnWrapper">
					<div
						className="PresetPopupCancelBtn"
						onClick={() => {
							setView(PresetPopupViews.add)
						}}
					>
						Cancel
					</div>
					<FreeButton
						className="button"
						onClick={() => {
							handleDone()
						}}
					>
						Done
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
