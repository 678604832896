import beam from '../../../../Handlers/beam'
import { DiscountInterface } from '../../../../Utils/interfaces'

export const verifyDiscountCode = async (
	discountCode: string,
	cartContents: any[]
) => {
	const invalidCode = {
		isValid: false,
		discountData: null,
		discountAmount: 0,
		error: '',
	}
	const code = discountCode.trim()
	if (code.length == 0) return invalidCode
	const { response } = await beam
		.post('/api/verifyDiscountCode', { discountCode: code })
		.catch((e) => e)
	if (response.error) return { ...invalidCode, error: response.error }
	const {
		discountAmount = 0,
		error = '',
	}: { discountAmount?: number; error?: string } = await beam
		.post('/api/computeDiscountAmount', {
			discountID: response.data.discountID,
			cartContents,
		})
		.then(({ response }) => {
			return { discountAmount: response.data }
		})
		.catch(({ response }) => {
			return { error: response.error }
		})

	if (error) {
		return { ...invalidCode, error }
	}

	if (!discountAmount) {
		return {
			...invalidCode,
			error: "Discount code doesn't apply to any items in your cart",
		}
	}

	return {
		isValid: true,
		discountData: response.data as DiscountInterface,
		discountAmount,
		error: '',
	}
}
