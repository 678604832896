import React from 'react'
import { useHistory } from 'react-router-dom'
import PresetIcon from '../../../Components/PresetIcon/PresetIcon'
import { formatSpecType } from '../../../Utils/formatUtils'
import './OrderCards.css'
import Order from '../../../@types/Order'

interface OrderCardsProps {
	orders: Order[]
}

export default function OrderCards({ orders }: OrderCardsProps) {
	return (
		<div className="OrderCards">
			{orders.map((order) => (
				<OrderCard order={order} key={order.orderID} />
			))}
		</div>
	)
}

function OrderCard({ order }: { order: any }) {
	const history = useHistory()
	const date = new Date(order.placedDate)

	return (
		<div
			className="OrderCard"
			onClick={() => {
				history.push('/Orders/' + order.longID)
			}}
		>
			<div className="orderDate">
				Placed {date.toLocaleString('default', { month: 'long' })}{' '}
				{date.getDate()}
			</div>
			<div className="cardBody">
				<div className="topText bold">
					Order #{order.shortID} ({order.itemCount} item
					{order.itemCount !== 1 ? 's' : ''})
				</div>
				<div className="line"></div>

				{order.content.slice(0, 2).map((item: any, index: number) => {
					return (
						<div className="item" key={index}>
							<div className={`itemImageContainer ${item.type}`}>
								<PresetIcon iconName={item.specType} type={item.type} />
							</div>
							<div className="itemText">
								{item.type == 'Purchase'
									? item.productName
									: formatSpecType(item.specType)}{' '}
								(x{item.quantity})
							</div>
						</div>
					)
				})}

				{order.content.length > 2 && (
					<div className="extras">+{order.content.length - 2} More</div>
				)}

				<div className="bottom">
					<div className="line"></div>
					<div className="redText">{order.status}</div>
				</div>
			</div>
		</div>
	)
}
