import React, { useState } from 'react'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import beam from '../../../Handlers/beam'
import { FreeButton } from 'react-ui-scaffold'
import SocialLogin from '../../SignInPopup/Support/SocialLogin/SocialLogin'
import { PromptTab } from '../AccountPromptPopup'

interface CreateAccountMinProps {
	setActiveTab(tab: PromptTab): void
	setMessage(msg: string): void
}

export default function CreateAccountMin({
	setActiveTab,
	setMessage,
}: CreateAccountMinProps) {
	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		createEmail: '',
		createPassword: '',
		confirmPassword: '',
		createName: '',
		phone: '',
	})
	const [agreementChecked, setAgreementChecked] = useState(false)

	async function create() {
		if (data.createPassword !== data.confirmPassword) {
			setMessage("Passwords don't match!")
			return
		}

		await beam
			.post('/api/accounts/create', {
				firstName: data.firstName,
				lastName: data.lastName,
				password: data.createPassword,
				email: data.createEmail,
				phone: data.phone,
			})
			.then(({ response }) => {
				setActiveTab(PromptTab.CreationSuccess)
				setData({ ...data, email: response.user.account.email, password: '' })
			})
			.catch((err) => {
				console.log(err)
				setMessage(err.response?.error)
			})
	}

	function changeHandler(e: any) {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	return (
		<div className="CreateAccountMin">
			<div className="story">
				<label htmlFor="Firstname">First Name</label>
				<input
					type="text"
					className="input wide"
					placeholder="John"
					data-htmlFor="first name"
					name="firstName"
					value={data.firstName}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="LastName">Last Name</label>
				<input
					type="text"
					className="input wide"
					placeholder="Doe"
					data-htmlFor="last name"
					name="lastName"
					value={data.lastName}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Email</label>
				<input
					type="text"
					className="input wide"
					placeholder="example@email.com"
					data-htmlFor="new email"
					name="createEmail"
					value={data.createEmail}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Phone Number</label>
				<input
					type="text"
					className="input wide"
					placeholder="123-123-1234"
					data-htmlFor="phone"
					name="phone"
					value={data.phone}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Password</label>
				<input
					type="password"
					className="input wide"
					placeholder="Password"
					data-htmlFor="new password"
					name="createPassword"
					value={data.createPassword}
					onChange={changeHandler}
				/>
			</div>

			<div className="story">
				<label htmlFor="Email">Confirm Password</label>
				<input
					type="password"
					className="input wide"
					placeholder="Password"
					data-htmlFor="new password"
					name="confirmPassword"
					value={data.confirmPassword}
					onChange={changeHandler}
				/>
			</div>

			<div className="agreementContainer">
				<Checkbox checked={agreementChecked} setChecked={setAgreementChecked} />
				<div>
					I agree to the
					<a className="button plain neutral">{' Terms of Service '}</a>
					and
					<a className="button plain neutral">{' Privacy Policy '}</a>
				</div>
			</div>

			<div className="story">
				<FreeButton
					className="button wide"
					onClick={create}
					disabled={!agreementChecked}
				>
					Create Account
				</FreeButton>
			</div>

			<div
				className="story"
				style={{ textAlign: 'center', fontWeight: 'bold' }}
			>
				OR
			</div>

			<SocialLogin
				successHandler={() => {
					setActiveTab(PromptTab.CreationSuccess)
				}}
			/>
		</div>
	)
}
