import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import useAddPresetToCart from '../../../../Hooks/useAddPresetToCart'
import { State } from '../../../../Redux/store'
import { PresetPopupViews } from '../../PresetPopup'

// Interface for props.
interface ViewPresetPropsI {
	visible: boolean
	setVisible(value: boolean): void
	setView(view: PresetPopupViews): void
}

export default function ViewPreset({
	visible,
	setVisible,
	setView,
}: ViewPresetPropsI) {
	const addPresettoCart = useAddPresetToCart()
	const cart = useSelector((s: State) => s.cart.contents)
	const currentPreset = useSelector((s: State) => s.presets.currentPreset)
	const [isPresetAdded, setIsPresetAdded] = useState<boolean>(false)

	useEffect(() => {
		if (isPresetAdded) {
			setView(PresetPopupViews.addToCart)
			setIsPresetAdded(false)
		}
	}, [cart])

	return (
		<Popup
			title={currentPreset?.presetName!}
			visible={visible}
			setVisible={setVisible}
			className="PresetViewPopup"
		>
			<div>
				<div
					className="flexY wide PresetPopupWrapper"
					style={{ justifyContent: 'center' }}
				>
					<div className="wide ViewPresetContentWrapper">
						<img
							className="PresetImageInPopup"
							src={`${process.env.REACT_APP_SERVER_URL}${String(
								currentPreset?.imageID
							)}`}
						/>
						<div className="PopupPresetData">
							<div>
								Hollow:{' '}
								<span className="DarkColor">
									{currentPreset?.hollowValue}&quot;
								</span>
							</div>
							<div>
								Radius:{' '}
								<span className="DarkColor">
									{currentPreset?.radiusValue}&lsquo;
								</span>
							</div>
							<div>
								Pitch:{' '}
								<span className="DarkColor">P{currentPreset?.pitchValue}</span>
							</div>
						</div>
						<div
							className="PresetEditButton"
							onClick={() => setView(PresetPopupViews.edit)}
						>
							Edit <FontAwesomeIcon icon={faPen} />
						</div>
					</div>
					<div className="ViewPresetPopupActionButtons">
						<FreeButton
							className="button"
							onClick={() => {
								addPresettoCart(
									{
										hollowValue: currentPreset?.hollowValue!,
										radiusValue: currentPreset?.radiusValue!,
										pitchValue: currentPreset?.pitchValue!,
										imageID: currentPreset?.imageID!,
										presetName: currentPreset?.presetName!,
										presetID: currentPreset?.presetID!,
									},
									false
								)
								setIsPresetAdded(true)
							}}
						>
							Add to Cart
						</FreeButton>
						<div
							className="DeleteCardButton"
							onClick={() => setView(PresetPopupViews.confirmDelete)}
						>
							Delete Preset
						</div>
					</div>
				</div>
			</div>
		</Popup>
	)
}
