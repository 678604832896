import React, { useEffect, useState } from 'react'
import { UserPreset } from '../../../../../Redux/Slices/presetsSlice'
import './PresetsList.css'
import PresetItem from './PresetItem'
import { useSelector } from 'react-redux'
import { State } from '../../../../../Redux/store'

export default function PresetsList({
	presets,
	setShowIndPopup,
}: {
	presets: UserPreset[]
	setShowIndPopup(value: boolean): void
}) {
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const [filteredPresets, setfilteredPresets] = useState([] as UserPreset[])
	useEffect(() => {
		const oldDate = new Date()
		oldDate.setFullYear(0)
		if (screenSize == 'mobile') {
			setfilteredPresets(
				[...presets]
					.sort((a, b) =>
						(a?.lastUsed || oldDate.toISOString()) >
						(b?.lastUsed || oldDate.toISOString())
							? -1
							: 1
					)
					.slice(0, 2)
			)
		} else {
			setfilteredPresets(
				[...presets]
					.sort((a, b) =>
						(a?.lastUsed || oldDate.toISOString()) >
						(b?.lastUsed || oldDate.toISOString())
							? -1
							: 1
					)
					.slice(0, 4)
			)
		}
	}, [screenSize, presets])

	return (
		<div className="PresetsList">
			{filteredPresets.map((preset) => (
				<PresetItem
					key={preset.presetID}
					preset={preset}
					setShowIndPopup={setShowIndPopup}
				/>
			))}
		</div>
	)
}
