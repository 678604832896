import React, { useState } from 'react'
import './BladeWarningPopup.css'
import Popup from '../../Components/Popup/Popup'
import { useSelector } from 'react-redux'
import { State } from '../../Redux/store'
import LoggedOutOnly from '../../Components/LoggedOutOnly/LoggedOutOnly'
import { MultiSelect } from 'react-multi-select-component'
import Conditional from '../../Components/Conditional/Conditional'
import beam from '../../Handlers/beam'
import { useEffect } from 'react'

interface BladeWarningPopupProps {
	visible: boolean
	setVisible: any
}

interface Option {
	value: any
	label: string
	key?: string
	disabled?: boolean
}

const warningMessage =
	'Skatescribe is currently servicing and selling Bauer Tuuk and CCM XS blades. Support for more blades types coming soon!'

export default function BladeWarningPopup({
	visible,
	setVisible,
}: BladeWarningPopupProps) {
	const user = useSelector((s: State) => s.activeUser)
	const { notSupportedBlades } = useSelector((s: State) => s.blades)

	// State to keep track of Selected Blades ids from the multiselect.
	const [selectedBlades, setSelectedBlades] = useState<Option[]>([])
	const [userEmail, setUserEmail] = useState<string>()

	// Error message state.
	const [errorMessage, setErrorMessage] = useState<string>('')
	// Success message state.
	const [successMessage, setSuccessMessage] = useState<string>('')

	const exitHandler = () => setVisible(false)

	useEffect(() => {
		setSelectedBlades(
			notSupportedBlades.map((blade) => ({
				label: blade.name,
				value: blade.id,
			}))
		)
	}, [notSupportedBlades])

	useEffect(() => {
		setUserEmail(user.data.playerEmail)
	}, [user])

	const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		// If none of the blades are selected, then don't send the email.
		if (selectedBlades.length === 0) {
			setErrorMessage('Please select at least one blade type.')
			setSuccessMessage('')
			return
		}

		// Get all the values from the multiselect.
		const selectedBladesIds = selectedBlades.map((blade: any) => blade.value)

		// Submit
		await beam
			.post('/api/subscribetoAlerts', {
				email: userEmail,
				bladeAlertsIds: selectedBladesIds,
			})
			.then(({ response }) => {
				if (response.error) {
					setErrorMessage(response.error)
				} else {
					setSuccessMessage('Successfully subscribed to alerts.')
					setErrorMessage('')
				}
			})
			.catch((err) => {
				setErrorMessage(err.response?.error || 'Something went wrong.')
				setSuccessMessage('')
			})
	}

	return (
		<Popup
			title="Currently servicing Bauer Tuuk & CCM XS blades"
			visible={visible}
			setVisible={setVisible}
			className="BladeWarningPopup"
			onExit={exitHandler}
		>
			{warningMessage}

			<Conditional condition={notSupportedBlades.length !== 0}>
				<div className="newBladesAlert">
					<p className="description">
						Get notified when other blade types are available:
					</p>
					<form className="alertFormWrapper" onSubmit={submitHandler}>
						<MultiSelect
							className="multiSelect"
							options={notSupportedBlades.map((blade) => ({
								label: blade.name,
								value: blade.id,
							}))}
							value={selectedBlades}
							onChange={setSelectedBlades}
							labelledBy={'Select Blade Types'}
							disableSearch={true}
							hasSelectAll={false}
							valueRenderer={() => {
								// If none selected, show 'Select Blade Types'
								if (selectedBlades.length === 0) {
									return 'Select Blade Types'
								}
								// If all selected, show 'All'
								if (selectedBlades.length === notSupportedBlades.length) {
									return 'All'
								}
								// If selected, show totla selcted count
								return `${selectedBlades.length} blade selected`
							}}
						/>
						<LoggedOutOnly>
							<input
								className="input"
								type="email"
								value={userEmail}
								onChange={(e) => setUserEmail(e.target.value)}
								placeholder="Email"
							/>
						</LoggedOutOnly>
						<button className="button" type="submit" disabled={!userEmail}>
							Submit
						</button>
					</form>

					{/* Show error. */}
					<Conditional condition={errorMessage}>
						<span className="negativeColor">{errorMessage}</span>
					</Conditional>
					<Conditional condition={successMessage}>
						<span className="positiveColor">{successMessage}</span>
					</Conditional>
				</div>
			</Conditional>

			<button
				className="button neutral submit mobileWide"
				onClick={exitHandler}
			>
				Continue
			</button>
		</Popup>
	)
}
