import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { previewsSelector } from '../../../../Redux/Slices/activeUserSlice'
import NotificationList from '../../../Notifications/NotificationList/NotificationList'
import Conditional from '../../../../Components/Conditional/Conditional'
import { State } from '../../../../Redux/store'

function NotificationWidget() {
	const notifications = useSelector((s: State) => s.notifications.list)
	const notificationsLoading = useSelector(
		(s: State) => s.notifications.loading
	)
	const history = useHistory()
	const previewsLimit = parseInt(useSelector(previewsSelector) as string)

	return (
		<div className="NotificationWidget widget large">
			<div className="titleBar">
				<span className="hemi">Notifications</span>
				<button
					className="button neutral plain viewAll"
					onClick={() => history.push('/Notifications')}
				>
					View All
				</button>
			</div>
			<Conditional
				condition={notifications.length}
				fallback={
					<div className="emptyNote">
						{notificationsLoading ? 'Loading...' : 'No Notifications'}
					</div>
				}
			>
				<NotificationList limit={previewsLimit} />
			</Conditional>
		</div>
	)
}

export default NotificationWidget
