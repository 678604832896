import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import useAddSharpening from './useAddSharpening'
import { State } from '../Redux/store'

export default function usePrepareOrder() {
	const history = useHistory()
	const addSharpening = useAddSharpening()
	const cartCount = useSelector((s: State) => s.cart.contents.length)

	return function () {
		if (cartCount === 0) {
			addSharpening()
		}
		history.push('/Cart')
	}
}
