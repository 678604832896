import React from 'react'
import './BasicSpecs.css'
import PresetCard from './PresetCard/PresetCard'
import { presetSelector } from '../../../Redux/Slices/optionsSlice'
import { useSelector } from 'react-redux'
import { CartItem } from '../../../Redux/Slices/cartSlice'

interface BasicSpecsProps {
	setVisible: (visible: boolean) => void
	cartItem: CartItem
	showRecoSpecs: (recoSpecsVisible: boolean) => void
}

export default function BasicSpecs({
	setVisible,
	cartItem,
	showRecoSpecs,
}: BasicSpecsProps) {
	const presetList = useSelector(presetSelector)

	return (
		<div className="BasicSpecs">
			<div className="presetsContainer">
				{presetList.map((preset: any) => (
					<PresetCard
						key={preset.basicPresetID}
						preset={preset}
						cartItem={cartItem}
					/>
				))}
			</div>

			<div className="options">
				<button
					className="button neutral hollow"
					onClick={() => showRecoSpecs(true)}
				>
					Recommend Specs
				</button>
				<button className="button neutral" onClick={() => setVisible(false)}>
					Done
				</button>
			</div>
		</div>
	)
}
