import fsph from './../../../../Assets/photos/bladesize/find-size-placeholder.jpg'
import rmvblade from './../../../../Assets/photos/bladesize/remove-blade.gif'

export const data = {
	optionNumber: 3,
	title: 'Find Size on Previous Blades',
	content: {
		section1: {
			text: 'If you cannot see your blade size on your blade holder, you can remove a previous blade to see its size.',
		},
		section2: [
			{
				title: 'CCM Blade',
				img: fsph,
				altImg: 'CCM Blade Holder',
			},
			{
				title: 'Bauer Blade',
				img: fsph,
				altImg: 'Bauer Blade Holder',
			},
		],
		section3: {
			text: 'When removing a blade, please be sure to wear gloves, as the blades are sharp.',
			gif: {
				img: rmvblade,
				altImg: 'Remove a blade - GIF',
			},
		},
	},
}
