import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Conditional from '../../../../Components/Conditional/Conditional'
import { updateDiscount } from '../../../../Redux/Slices/cartSlice'
import { State } from '../../../../Redux/store'
import { verifyDiscountCode } from '../common/utils'

interface BillingContactInfoProps {
	cart: any
	changeHandler: any
	discountCode: string
	setDiscountCode: (code: string) => void
	setMessage: (message: string) => void
}

export default function BillingContactInfo({
	cart,
	changeHandler,
	discountCode,
	setDiscountCode,
	setMessage,
}: BillingContactInfoProps) {
	const user = useSelector((s: State) => s.activeUser)
	const dispatch = useDispatch()

	// On Blur Event. Get the discount code and verify it.
	const discountChangeHandler = async () => {
		const { isValid, discountAmount, discountData, error } =
			await verifyDiscountCode(discountCode, cart.contents)
		if (!isValid) {
			setMessage(error)
			return dispatch(
				updateDiscount({
					discountData: null,
				})
			)
		}
		setMessage('')

		// Apply discount code to cart.
		dispatch(
			updateDiscount({
				discountData,
				discountAmount,
			})
		)
	}

	return (
		<div className="story">
			<label className="hemi">Contact Info</label>
			<div className="flexY">
				<div className="subStory wide">
					<label>First Name</label>
					<input
						type="text"
						className="input wide"
						onChange={changeHandler}
						name="contactFirstName"
						value={cart.contactFirstName}
					/>
				</div>

				<div className="subStory wide">
					<label>Last Name</label>
					<input
						type="text"
						className="input wide"
						onChange={changeHandler}
						name="contactLastName"
						value={cart.contactLastName}
					/>
				</div>

				<div
					className="subStory half fullOnSmallScreens"
					style={{ paddingRight: '20px' }}
				>
					<label>Phone</label>
					<input
						type="text"
						className="input wide"
						onChange={changeHandler}
						name="contactPhone"
						value={cart.contactPhone}
					/>
				</div>

				<div className="subStory half">
					<label>Email</label>
					<Conditional
						condition={user.status === 'Logged In'}
						contents={<span className="wide">{user.account.email}</span>}
						fallback={
							<input
								type="text"
								className="input wide"
								onChange={changeHandler}
								name="contactEmail"
								value={cart.contactEmail}
							/>
						}
					/>
				</div>

				<div className="subStory wide">
					<label>Postal Code</label>
					<input
						type="text"
						className="input wide"
						onChange={changeHandler}
						name="contactPostalCode"
						value={cart.contactPostalCode}
					/>
				</div>
			</div>
			<div className="discountWrapper">
				<label className="hemi ">Discount Code</label>
				<div className="flexY">
					<div className="subStory wide">
						<input
							type="text"
							className="input wide"
							value={discountCode}
							onChange={(e) => setDiscountCode(e.target.value)}
							onBlur={discountChangeHandler}
							name="discountCode"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
