import React, { useEffect, useState } from 'react'
import ChooseSpecsPopup from '../../../../Popups/ChooseSpecsPopup/ChooseSpecsPopup'
import './SpecsCard.css'
import { useDispatch, useSelector } from 'react-redux'
import {
	defaultImageUrlSelector,
	presetImageSelector,
	PresetOption,
	presetSelector,
	PresetSpecs,
	SpecType,
} from '../../../../Redux/Slices/optionsSlice'
import { State } from '../../../../Redux/store'
import {
	CartItem,
	setIsNewBladeAddedfromPopup,
	setNewBladePurchaseId,
	setNewSharpeningAddedfromStaticSite,
	setNewSharpeningId,
} from '../../../../Redux/Slices/cartSlice'

interface SpecsCardProps {
	row: CartItem
}

export default function SpecsCard({ row }: SpecsCardProps) {
	const presetList = useSelector(presetSelector)
	const presetImages = useSelector(presetImageSelector)
	const presets = useSelector((s: State) => s.presets.list)
	const newBladePurchaseId = useSelector(
		(s: State) => s.cart.newBladePurchaseId
	)
	const isNewPladeAddedFromPopup = useSelector(
		(s: State) => s.cart.newBladeAddedfromPopup
	)
	const newSharpeningId = useSelector((s: State) => s.cart.newSharpeningId)
	const isNewSharpeningfromStaticSite = useSelector(
		(s: State) => s.cart.newSharpeningAddedfromStaticSite
	)
	const defaultImageUrl = useSelector(defaultImageUrlSelector)

	const screenSize = useSelector((s: State) => s.view.screenSize)

	const dispatch = useDispatch()

	let selectedPreset = presetList.find(
		(preset: PresetOption) => preset.name === row.specType
	)
	if (!selectedPreset) {
		selectedPreset = {
			name: row.specType as PresetSpecs,
			imageURL: row.presetID
				? process.env.REACT_APP_SERVER_URL! +
					(presets.find((p) => p.presetID == row.presetID)?.imageID ||
						defaultImageUrl)
					: presetImages[SpecType.Custom],
		}
	}
	const { name } = selectedPreset
	const isBasic = [
		SpecType.Agility,
		SpecType.Balanced,
		SpecType.Speed,
	].includes(name)

	const imageURL = selectedPreset.imageURL || presetImages[SpecType.Custom]

	const [showChooseSpecs, setShowChooseSpecs] = useState(false)

	useEffect(() => {
		if (
			newBladePurchaseId &&
			row.id === newBladePurchaseId &&
			isNewPladeAddedFromPopup
		) {
			setTimeout(() => {
				setShowChooseSpecs(true)
			}, 500)
			dispatch(setNewBladePurchaseId(null))
			dispatch(setIsNewBladeAddedfromPopup(false))
		} else if (
			newSharpeningId &&
			row.id === newSharpeningId &&
			isNewSharpeningfromStaticSite
		) {
			setTimeout(() => {
				setShowChooseSpecs(true)
			}, 500)
			dispatch(setNewSharpeningId(null))
			dispatch(setNewSharpeningAddedfromStaticSite(false))
		}
	}, [
		newBladePurchaseId,
		isNewPladeAddedFromPopup,
		newSharpeningId,
		isNewSharpeningfromStaticSite,
	])

	return (
		<>
			<ChooseSpecsPopup
				visible={showChooseSpecs}
				setVisible={setShowChooseSpecs}
				cartItem={row}
			/>

			<div
				className={`SpecsCard ${screenSize === 'desktop' ? 'desktop' : ''}`}
				onClick={() => setShowChooseSpecs(true)}
			>
				{row.specType ? (
					<div className="presetView" onClick={() => setShowChooseSpecs(true)}>
						<div className="wide titleBox">
							{`${isBasic ? `Basic Preset: ${name}` : row.specType}
								`}
						</div>
						{row.specType === SpecType.Custom ? (
							<img src={imageURL} alt={name} draggable="false" />
						) : (
							<div className="elementsContainer">
								<div className="element">
									<span className="elementType">Hollow:</span>
									<span>{row.hollowValue}&quot;</span>
								</div>
								<div className="element">
									<span className="elementType">Radius:</span>
									<span>{row.radiusValue}&lsquo;</span>
								</div>
								<div className="element">
									<span className="elementType">Pitch:</span>
									<span>P{row.pitchValue}</span>
								</div>
							</div>
						)}
						<button className="button wide plain neutral bold">Change</button>
					</div>
				) : (
					<button
						style={{ height: '100%' }}
						className="wide button plain neutral bold"
					>
						Choose Specs
					</button>
				)}
			</div>
		</>
	)
}
