import React, { useEffect, useState } from 'react'
import './ErrorOverlay.css'
import errorBG from '../../../Assets/photos/error-background.jpg'
import beam from '../../../Handlers/beam'
import { useSelector } from 'react-redux'

export default function ErrorOverlay({ appError }: { appError: string }) {
	const [userFeedback, setUserFeedback] = useState('')
	const [errorID, setErrorID] = useState('')
	const appState = useSelector((s) => s)

	useEffect(() => {
		sendErrorReport()
	}, [])

	const sendErrorReport = async () => {
		const activeURL = window.location.href
		try {
			const errorRes = await beam.post('/api/storeError', {
				appState,
				appError,
				activeURL,
			})
			setErrorID(errorRes.response.errorID)
		} catch {
			console.error('Unable to get error report ID')
		}
	}

	const handleErrorSubmit = async () => {
		await beam.post('/api/sendErrorFeedback', {
			errorID,
			userFeedback,
		})
	}

	return (
		<div
			className="ErrorOverlay flexCenter"
			style={{
				backgroundImage: `url(${errorBG})`,
			}}
		>
			<div className="contentContainer">
				<h1 className="hemi header">Something went wrong!</h1>
				<h2>
					There was an unknown error. We&apos;re sorry about that! Please try
					again later.
				</h2>

				<div className="feedbackContainer">
					<h2 className="feedbackPrompt">
						Please give us details and/or feedback:
					</h2>
					<textarea
						className="input"
						placeholder="Type your spec requirements here"
						value={userFeedback}
						onChange={(e) => setUserFeedback(e.target.value)}
					></textarea>
					<button
						className="button neutral feedbackSubmit"
						onClick={handleErrorSubmit}
					>
						Done
					</button>
				</div>
			</div>
		</div>
	)
}
