import React from 'react'
import { useDispatch } from 'react-redux'
import { CartItem, updateCartItem } from '../../../../Redux/Slices/cartSlice'
import { UserPreset } from '../../../../Redux/Slices/presetsSlice'
import './PresetCard.css'

interface PresetCardProps {
	selectedPreset?: number
	preset: UserPreset
	setSelectedPreset?: (preset: number) => void
	cartItem: CartItem
}

export default function TabPresetCard({
	preset,
	selectedPreset,
	setSelectedPreset,
	cartItem,
}: PresetCardProps) {
	const dispatch = useDispatch()

	const switchPreset = () => {
		dispatch(
			updateCartItem({
				id: cartItem.id,
				updates: {
					specType: preset.presetName,
					hollowValue: preset.hollowValue,
					radiusValue: preset.radiusValue,
					pitchValue: preset.pitchValue,
					presetID: preset.presetID,
				},
			})
		)
	}

	const handleCustomPresetClick = () => {
		setSelectedPreset && setSelectedPreset(preset.presetID!)
		switchPreset()
	}

	return (
		<div
			className={`TabPresetCard ${
				preset.presetID == selectedPreset ? 'SelectedTabPresetCard' : ''
			}`}
			onClick={handleCustomPresetClick}
		>
			<img
				className="TabPresetImg"
				src={`${process.env.REACT_APP_SERVER_URL}${preset.imageID}`}
			/>
			<div className="TabPresetName">{preset.presetName}</div>
		</div>
	)
}
