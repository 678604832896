import React from 'react'
import Popup from '../../Components/Popup/Popup'
import './ItemOutOfStockPopup.css'
import { FreeButton } from 'react-ui-scaffold'
import { useDispatch } from 'react-redux'
import { removeFromCart } from '../../Redux/Slices/cartSlice'

interface ItemOutOfStockPopupProps {
	visible: boolean
	setVisible(value: boolean): void
	cartItem: any
	onSelectAnotherModel(): void
}

export default function ItemOutOfStockPopup({
	visible,
	setVisible,
	cartItem,
	onSelectAnotherModel,
}: ItemOutOfStockPopupProps) {
	const dispatch = useDispatch()

	const removedErroredItems = () => {
		dispatch(removeFromCart(cartItem.id))
		setVisible(false)
	}

	if (!cartItem) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title="Problem with Cart Item"
			className="ItemOutOfStockPopup"
		>
			<div className="story body">
				<div>
					We&lsquo;re sorry, the item&nbsp;
					<span className="ProductName">{cartItem.name}</span>&nbsp; is no
					longer available
				</div>
				<div className="action">
					<FreeButton className="hollow" onClick={() => removedErroredItems()}>
						Remove item from Cart
					</FreeButton>
					<FreeButton onClick={() => onSelectAnotherModel()}>
						Select Another Model
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
