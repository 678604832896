import React, { useEffect, useMemo, useState } from 'react'
import './OrderDetails.css'
import { useSelector, useDispatch } from 'react-redux'
import MainContainer from '../../Components/MainContainer/MainContainer'
import dayjs from 'dayjs'
import { formatCurrency } from '../../Utils/formatUtils'
import ReviewCard from '../../Pages/Checkout/Support/ReviewCard/ReviewCard'
import { Loading, Page } from 'react-ui-scaffold'
import beam from '../../Handlers/beam'
import useScrollToTop from '../../Hooks/useScrollToTop'
import { addToCart } from '../../Redux/Slices/cartSlice'
import { useHistory } from 'react-router-dom'
import AccountPromptPopup from '../../Popups/AccountPromptPopup/AccountPromptPopup'
import Order from '../../@types/Order'
import notificationCaution from '../../Assets/graphics/notification-caution.svg'
import SignInPopup, { MsgI } from '../../Popups/SignInPopup/SignInPopup'
import {
	faCcVisa,
	faCcMastercard,
	faCcDiscover,
	faCcAmex,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { State } from '../../Redux/store'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import OrderPrint from './OrderPrint'
import { PDFDownloadLink } from '@react-pdf/renderer'
import LocationMap from '../../Components/LocationMap/LocationMap'
import Conditional from '../../Components/Conditional/Conditional'
import { countCartContents } from '../../Utils/generalUtils'

interface OrderDetailsProps {
	match: any
}

export default function OrderDetails({ match }: OrderDetailsProps) {
	const history = useHistory()
	const dispatch = useDispatch()
	const [order, setOrder] = useState<Order>()
	const [isLoading, setIsLoading] = useState(true)
	const screenSize = useSelector((s: State) => s.view.screenSize)

	const [showAccountPrompt, setShowAccountPrompt] = useState(false)

	const [showSignIn, setShowSignIn] = useState(false)
	const [signInMessage, setSignInMessage] = useState<MsgI>()

	const user = useSelector((s: State) => s.activeUser)
	const locations = useSelector((s: State) => s.options.locations)
	const orderLocation = locations?.find((location: any) => {
		return location?.locationID === order?.dropOffLocation
	})

	const [flag, setflag] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setflag(true)
		}, 100)
		return () => clearTimeout(timer)
	}, [])

	useScrollToTop()

	useEffect(() => {
		setIsLoading(true)
		const orderEndpoint = `/api/fetch${
			user.status === 'Logged In' ? 'User' : 'Guest'
		}OrderByLongId/${match.params.orderID}`
		beam
			.post(orderEndpoint)
			.then(({ response }) => {
				setOrder(response)
				setIsLoading(false)
			})
			.catch((err) => {
				console.log(err.response?.action)
				setIsLoading(false)
				if (err.status === 401) {
					setSignInMessage({
						color: 'var(--negativeAccentColor)',
						text: err.response.error,
					})
					setShowSignIn(true)
				}
			})

		if (
			localStorage.getItem('showAccountPrompt') &&
			user.status !== 'Logged In'
		) {
			setShowAccountPrompt(true)
			localStorage.removeItem('showAccountPrompt')
		}
	}, [match.params.orderID])

	const formattedContent = useMemo(() => {
		if (order?.contents) {
			return order.contents.map((item) => {
				const toReturn = { ...item, name: item.productName }
				if (item.productSkuID) {
					return {
						...toReturn,
						name: `${item.productName} - ${item.productSkuName}`,
					}
				}
				return toReturn
			})
		}
		return []
	}, [order?.contents])

	const handleRepeatOrder = () => {
		dispatch(addToCart({ items: formattedContent, replaceCart: true }))
		history.push('/Cart')
	}

	const ccIcon = useMemo(() => {
		switch (order?.cardDetails?.card_type) {
			case 'VI':
				return faCcVisa
			case 'MC':
				return faCcMastercard
			case 'NN':
				return faCcDiscover
			case 'AM':
				return faCcAmex
			default:
				return null
		}
	}, [order?.cardDetails?.card_type])

	if (isLoading) {
		return <Page loading />
	}

	if (!order) {
		return (
			<div className="flexCenter" style={{ height: '200px' }}>
				<SignInPopup
					visible={showSignIn}
					setVisible={setShowSignIn}
					defaultMessage={signInMessage}
				/>
				<h1 className="hemi title">Order not found!</h1>
			</div>
		)
	}

	const orderItemCount = countCartContents(order.contents)

	const handleInstructionsRedirection = () => {
		window.open('/PostOrder', '_blank')
	}

	return (
		<Page className="OrderDetails">
			<AccountPromptPopup
				visible={showAccountPrompt}
				setVisible={setShowAccountPrompt}
			/>
			<MainContainer
				style={{
					maxWidth: '700px',
					paddingBottom: '200px',
					display: 'flex',
					flexDirection: 'column',
					gap: '30px',
				}}
			>
				<h1 className="title hemi">Order Confirmation</h1>

				<div className="description">
					Make sure you attach the <b> order code</b> to each set of blades
					before drop off.
					<br />
					For more information about our drop off process click the button
					below.
				</div>

				<button
					className="button neutral instrButton"
					onClick={handleInstructionsRedirection}
				>
					Drop-off/Pick-up Instructions
				</button>

				<div className="orderTitleWrapper">
					<div className="orderNumber hemi">
						{order?.flagged && (
							<img src={notificationCaution} className="iconLarge" />
						)}
						<div>Order #{order.shortID}</div>
					</div>
					{flag && (
						<PDFDownloadLink
							className="downloadButtonWrapper"
							document={<OrderPrint order={order} user={user} />}
							fileName={`Order #${order.shortID} confirmation.pdf`}
						>
							{({ loading }) =>
								loading ? (
									<Loading
										style={{
											fontSize: '20px',
										}}
									/>
								) : (
									<button className="button neutral printButton">
										<div className="printIconWrapper">
											<FontAwesomeIcon icon={faPrint} />
										</div>
										<div className="printText">Print Order Confirmation</div>
									</button>
								)
							}
						</PDFDownloadLink>
					)}
				</div>

				{order?.flagged && (
					<div className="light invalidSpecContainer">
						<span className="invalidSpecHeader">
							<img src={notificationCaution} className="iconSmall" />
							This order contains invalid specs!
						</span>
						<div className="commentsContainer">
							<h3 className="commentsHeader">Skatescribe comments:</h3>
							<p className="commentsText">
								&quot;Our machines cannot create a profile of 17 feet. Please
								call us at (905)905-9059 and ask to speak to Teddy to discuss
								any radius alternatives for you. Thank you.&quot;
							</p>
						</div>
					</div>
				)}

				<div className="orderInfoContainer">
					<div className="orderInfoItem">
						<label className="hemi">Order Details</label>
						<span className="light">
							Order Status: <b className="orderStatus">{order.status}</b>
						</span>

						<span className="light">
							Number of items: <b>{order.contents.length}</b>
						</span>
					</div>

					<div className="orderInfoItem">
						<label className="hemi">Order Status History</label>
						{order.logs
							.filter((log: any) => !log.newValue.includes('Pending Payment'))
							.map((log: any, idx: number) => (
								<span className="light" key={idx}>
									{log.newValue}:{' '}
									<b>
										{dayjs(log.changeDate).format('MMMM DD, YYYY - h:mm A')}
									</b>
								</span>
							))}
					</div>
				</div>

				<div className="orderReviewContainer">
					<label className="hemi">Payment Info</label>
					<div className="flexY">
						{ccIcon && (
							<FontAwesomeIcon size="2x" icon={ccIcon} className="ccIcon" />
						)}
						ending in *{order.cardDetails.last_four}
					</div>

					<div className="total">
						<label className="hemi">Pricing Information</label>
						<div className="pricingTotalWrapper">
							<div className="pricingItem">
								<div>Subtotal:</div>
								<div>{formatCurrency(order.total)} CAD</div>
							</div>

							<Conditional condition={order.discountTotal}>
								<div className="pricingItem">
									<div>Discount Code:</div>
									<div className="discountReview">
										<div className="reviewDiscountCode">
											{order.discountCode}
										</div>
										<div>- {formatCurrency(order.discountTotal!)} CAD</div>
									</div>
								</div>
							</Conditional>

							<div className="pricingItem">
								<div>HST:</div>
								<div>{formatCurrency(order.tax)} CAD</div>
							</div>
							<div className="pricingItem">
								<div>Final Total:</div>
								<div>{formatCurrency(order.finalTotal)} CAD</div>
							</div>
						</div>
					</div>
				</div>

				<div className="orderReviewContainer">
					<label className="hemi">
						{screenSize === 'mobile'
							? `${orderItemCount} Item${orderItemCount !== 1 ? 's' : ''}`
							: `Order Items (${orderItemCount})`}
					</label>
					{formattedContent.map((c: any) => {
						return (
							<ReviewCard
								cartItem={c}
								key={c.itemID}
								timesQuantity
								showCode={true}
							/>
						)
					})}
				</div>

				<div className="buttonContainer">
					<button className="button hollow" onClick={handleRepeatOrder}>
						Order Again
					</button>
				</div>

				<div className="orderInfoItem">
					<label className="hemi">Drop-off/Pick-up Location</label>

					<span>{orderLocation?.address}</span>

					<Conditional condition={screenSize !== 'mobile'}>
						<LocationMap location={orderLocation} />
					</Conditional>

					<a
						className="button plain neutral mapsLink"
						href={orderLocation?.link}
						target="_blank"
						rel="noreferrer"
					>
						Open in Google Maps
					</a>
				</div>
			</MainContainer>
		</Page>
	)
}
