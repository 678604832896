import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import { setCurrentPresetNull } from '../../../../Redux/Slices/presetsSlice'
import { State } from '../../../../Redux/store'

// Interface for props.
interface DeleteConfirmationPropsI {
	visible: boolean
	setVisible(value: boolean): void
}

export default function DeleteConfirmation({
	visible,
	setVisible,
}: DeleteConfirmationPropsI) {
	const preset = useSelector((s: State) => s.presets.currentPreset)
	const dispatch = useDispatch()

	return (
		<Popup
			title="Preset Deleted"
			visible={visible}
			setVisible={setVisible}
			className="PresetAddToCartPopup"
		>
			<div
				className="flexY EditPresetPopupWrapper"
				style={{ justifyContent: 'center' }}
			>
				<div className="wide EditPresetContentWrapper">
					<div className="EditPresettPopupTitle">
						<div className="EditPresettPopupName">
							<span style={{ fontWeight: 500 }}>TThe preset </span>
							{preset?.presetName}{' '}
							<span style={{ fontWeight: 500 }}> has been deleted. </span>
						</div>
					</div>
				</div>
				<div className="EditPresetPopupBtnWrapper">
					<FreeButton
						className="button neutral"
						onClick={() => {
							setVisible(false)
							dispatch(setCurrentPresetNull())
						}}
					>
						Close
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
