import React from 'react'
import './Option2.css'
import Option from '../Option/Option'
import { data } from './data'
import TwoImgs from '../Option/Support/TwoImgs/TwoImgs'

export default function Option2() {
	return (
		<Option optionNumber={data.optionNumber} title={data.title}>
			<div className="BladeHolderContent">
				<div className="BHDescription">
					<img
						className="BHDescriptionImg"
						src={data.content.section1.img}
						alt={data.content.section1.altImg}
					/>
					<div className="BHDescriptionText">
						{data.content.section1.description}
					</div>
				</div>
				<TwoImgs data={data.content.section2} />
				<div className="BHDescriptionText">{data.content.section3.text}</div>
			</div>
		</Option>
	)
}
