import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './ReviewOrder.css'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MainContainer from '../../../../Components/MainContainer/MainContainer'
import useScrollToTop from '../../../../Hooks/useScrollToTop'
import { formatCurrency } from '../../../../Utils/formatUtils'
import ReviewCard from '../ReviewCard/ReviewCard'
import { State } from '../../../../Redux/store'
import LocationMap from '../../../../Components/LocationMap/LocationMap'
import Conditional from '../../../../Components/Conditional/Conditional'
import { useScreenSize } from '../../../../Hooks/useScreenSize'
import { countCartContents } from '../../../../Utils/generalUtils'

export default function ReviewOrder() {
	const cart = useSelector((s: State) => s.cart)

	const locations = useSelector((s: State) => s.options.locations)
	const currentLocation = locations.find(
		(location: any) => location.locationID === cart.dropOffLocation
	)

	const { screenSize } = useScreenSize()

	useScrollToTop()

	// const requiresEmailVerification = useMemo(() => !Object.keys(user.account).length || !user.account.emailVerified, [user])
	const cartTotalMemo = useMemo(() => formatCurrency(cart.total), [cart.total])
	const cartTaxMemo = useMemo(() => formatCurrency(cart.tax), [cart.tax])
	const finalTotalMemo = useMemo(
		() => formatCurrency(cart.total + cart.tax),
		[cart.total, cart.tax]
	)

	const orderItemCount = countCartContents(cart.contents)

	return (
		<div>
			<MainContainer style={{ maxWidth: '1100px' }} className="ReviewOrder">
				<div>
					<h1 className="hemi title">Review Order</h1>
					<h2 className="title">Please review before placing your order</h2>
				</div>

				<div className="reviewOrderWrapper">
					<div className="itemList">
						<div className="topBar flexY">
							<h2 className="hemi">Order Items ({orderItemCount})</h2>

							<div className="right">
								<Link to="/Cart">
									<button className="button plain neutral">
										Edit <FontAwesomeIcon icon={faPen} />
									</button>
								</Link>
							</div>
						</div>
						{cart.contents.map((c: any) => {
							return <ReviewCard cartItem={c} id={c.id} key={c.id} />
						})}
					</div>

					<div className="totalAndMaps">
						<div className="total">
							<h2 className="hemi">Pricing Information</h2>
							<div className="pricingTotalWrapper">
								<div className="pricingItem">
									<div>Subtotal:</div>
									<div>{cartTotalMemo} CAD</div>
								</div>
								<div className="pricingItem">
									<div>HST:</div>
									<div>{cartTaxMemo} CAD</div>
								</div>
								<div className="pricingItem">
									<div>Final Total:</div>
									<div>{finalTotalMemo} CAD</div>
								</div>
							</div>
						</div>
						<Link to="/Checkout/Billing" className="checkoutContainer">
							<button className="button checkoutButton">
								Checkout ({finalTotalMemo})
							</button>
						</Link>
						<div className="locationContainer">
							<h2 className="hemi">Drop-off / Pick-up Location</h2>
							<div>{currentLocation?.address}</div>
							<Conditional condition={screenSize !== 'mobile'}>
								<LocationMap location={currentLocation} />
							</Conditional>
							<a
								className="button plain neutral mapsLink"
								href={currentLocation?.link}
								target="_blank"
								rel="noreferrer"
							>
								Open in Google Maps
							</a>
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	)
}
