import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import { setCurrentPresetNull } from '../../../../Redux/Slices/presetsSlice'
import { State } from '../../../../Redux/store'
import { PresetPopupViews } from '../../PresetPopup'

// Interface for props.
interface AddToCartConfirmPropsI {
	visible: boolean
	setVisible(value: boolean): void
	setView(view: PresetPopupViews): void
}

export default function AddToCartConfirm({
	visible,
	setVisible,
	setView,
}: AddToCartConfirmPropsI) {
	const history = useHistory()
	const currentPreset = useSelector(
		(state: State) => state.presets.currentPreset
	)
	const dispatch = useDispatch()

	return (
		<Popup
			title="Added to Cart!"
			visible={visible}
			setVisible={setVisible}
			className="PresetAddToCartPopup"
		>
			<div
				className="flexY EditPresetPopupWrapper"
				style={{ justifyContent: 'center' }}
			>
				<div className="wide EditPresetContentWrapper">
					<div className="EditPresettPopupTitle">
						<img
							className="PresetImageInPopup"
							src={`${process.env.REACT_APP_SERVER_URL}${currentPreset?.imageID}`}
						/>
						<div className="EditPresettPopupName">
							{currentPreset?.presetName}{' '}
							<span style={{ fontWeight: 500 }}>
								has been added to your cart!
							</span>
						</div>
					</div>
				</div>
				<div className="EditPresetPopupBtnWrapper">
					<div
						className="PresetPopupCancelBtn"
						onClick={() => {
							setView(PresetPopupViews.view)
						}}
					>
						Back
					</div>
					<FreeButton
						className="button"
						onClick={() => {
							dispatch(setCurrentPresetNull())
							history.push('/Cart')
						}}
					>
						Go to Cart
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
