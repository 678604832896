import firebase from 'firebase/app'
import 'firebase/auth'
import beam from '../Handlers/beam'

//changes error strings/codes into a more readable form
const errorMap = {
	'auth/invalid-email': 'Invalid email!',
	'auth/user-not-found': 'No account found with this email!',
}

//Google
async function loginWithGoogle() {
	try {
		const provider = new firebase.auth.GoogleAuthProvider()
		await firebase.auth().signInWithPopup(provider)

	} catch (err: any) {
		console.error(err)
		return { error: err.message }
	}

	return {}
}

//FB
async function loginWithFacebook() {
	try {
		const provider = new firebase.auth.FacebookAuthProvider()
		provider.setCustomParameters({
			display: 'popup',
		})

		await firebase.auth().signInWithPopup(provider)

	} catch (err: any) {
		console.error(err)
		return { error: err.message }
	}

	return {}
}

//Create a new account

interface CreateAccountParams {
	email: string
	password: string
	name: string
}

async function createAccount({ email, password, name }: CreateAccountParams) {
	try {
		const result = await beam.post('/api/Accounts/Create', {
			name,
			email,
			password,
		})

		return { success: true, body: result.data.message }

	} catch (err: any) {

		console.error(err)

		const errorCode = errorMap[err.code as keyof typeof errorMap]

		if (errorCode) {
			return { error: errorCode }
		}

		return { error: err.response.error }
	}
}

//Regular log in
async function login(email: string, password: string) {
	return await firebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.catch((err) => {
			const errorCode = errorMap[err.code as keyof typeof errorMap]
			if (errorCode) {
				return Promise.reject(errorCode)
			}

			console.log(err)
			return Promise.reject(err.message)
		})
}

//Reset
async function resetPassword(email: string) {
	try {
		await firebase.auth().sendPasswordResetEmail(email)
		return {}
	} catch (err: any) {
		console.error(err)

		const errorCode = errorMap[err.code as keyof typeof errorMap]

		if (errorCode) {
			return { error: errorCode }
		}

		return { error: err.message }
	}
}

function logout() {
	localStorage.clear()
	return firebase.auth().signOut()
}

async function getToken() {
	try {
		return await firebase.auth().currentUser?.getIdToken()
	} catch (err: any) {
		return ''
	}
}

export default {
	loginWithGoogle,
	loginWithFacebook,
	createAccount,
	login,
	logout,
	resetPassword,
	getToken,
}
