import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainContainer from '../../Components/MainContainer/MainContainer'
import PresetPopup, {
	PresetPopupViews,
} from '../../Popups/PresetsPopup/PresetPopup'
import { updateUserSettingsData } from '../../Redux/Slices/activeUserSlice'
import { PresetsSortType, UserPreset } from '../../Redux/Slices/presetsSlice'
import { State } from '../../Redux/store'
import './MyPresets.css'
import MyPresetsList from './Support/MyPresetsList'

export default function MyPresets() {
	const dispatch = useDispatch()
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const presets = useSelector((s: State) => s.presets.list)
	const presetsSortType = useSelector(
		(s: State) => s.activeUser.data.presetsSortType
	)
	const [filteredPresets, setFilteredPresets] = useState(presets)
	const [sortedPresets, setSortedPresets] = useState<UserPreset[]>([])
	const [searchQuery, setSearchQuery] = useState('')

	const [showPresetPopup, setShowPresetPopup] = useState(false)
	const [showIndPopup, setShowIndPopup] = useState(false)

	const sortMethods = {
		[PresetsSortType.DateAddedFirst]: (a: UserPreset, b: UserPreset) =>
			a!.createdDate! > b!.createdDate! ? 1 : -1,
		[PresetsSortType.DateAddedLast]: (a: UserPreset, b: UserPreset) =>
			a?.createdDate! > b?.createdDate! ? -1 : 1,
		[PresetsSortType.AlphabeticallyFirst]: (a: UserPreset, b: UserPreset) =>
			a?.presetName.toLowerCase() > b?.presetName.toLowerCase() ? 1 : -1,
		[PresetsSortType.AlphabeticallyLast]: (a: UserPreset, b: UserPreset) =>
			a?.presetName.toLowerCase() > b?.presetName.toLowerCase() ? -1 : 1,
	}

	useEffect(() => {
		setSortedPresets(
			[...filteredPresets].sort(sortMethods[presetsSortType as PresetsSortType])
		)
	}, [filteredPresets, presetsSortType])

	useEffect(() => {
		if (searchQuery !== '') {
			setFilteredPresets([
				...presets.filter((preset: UserPreset) =>
					preset.presetName.toLowerCase().includes(searchQuery.toLowerCase())
				),
			])
		} else {
			setFilteredPresets([...presets])
		}
	}, [searchQuery, presets])

	return (
		<MainContainer>
			<div className="MyPresets">
				<PresetPopup
					visible={showPresetPopup}
					setVisible={setShowPresetPopup}
					currentView={PresetPopupViews.add}
				/>

				<h1 className="hemi title">
					My Presets
					{screenSize === 'mobile' && `(${sortedPresets.length})`}
				</h1>
				<div className="subheader">
					{screenSize !== 'mobile' && (
						<span className="hemi orderCount">
							{`${sortedPresets.length} Preset${
								presets.length !== 1 ? 's' : ''
							}`}
						</span>
					)}

					<div className="PresetsMobileHeader">
						<button
							className="button neutral plain viewAll"
							onClick={() => setShowPresetPopup(true)}
						>
							Add Preset
						</button>
						<span className="PresetsSorter">
							{screenSize !== 'mobile' && 'Sort by: '}
							<select
								className="input sortOptions"
								value={presetsSortType}
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
									dispatch(
										updateUserSettingsData({
											presetsSortType: e.target.value,
										})
									)
								}}
							>
								{Object.values(PresetsSortType).map((sortType, idx) => (
									<option key={idx} value={sortType}>
										{sortType}
									</option>
								))}
							</select>
						</span>
					</div>
				</div>

				<div className="PresetsSearchWrapper">
					<FontAwesomeIcon className="PresetsSearchIcon" icon={faSearch} />
					<input
						className="input"
						type="text"
						placeholder="Search Preset"
						onChange={(e) => {
							setSearchQuery(e.target.value)
						}}
					/>
				</div>
				<hr />

				<div className="PresetsListContainer">
					<PresetPopup visible={showIndPopup} setVisible={setShowIndPopup} />
					<MyPresetsList
						presets={sortedPresets}
						setShowIndPopup={setShowIndPopup}
					/>
				</div>
			</div>
		</MainContainer>
	)
}
