import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { saveCard } from '../../../Redux/Slices/cardsSlice'
import { FreeButton } from 'react-ui-scaffold'
import { debouncedBambora } from '../../../Utils/generalUtils'
import { BeamResponse } from '../../../../../commonTypes/response'

interface AddCardFormProps {
	setView?: any
}

export default function AddCardForm({ setView }: AddCardFormProps) {
	const [completedMap, setCompletedMap] = useState({
		['card-number']: false,
		expiry: false,
		cvv: false,
	})
	const dispatch = useDispatch()
	const [message, setMessage] = useState('')
	const [bamboraInstance, setBamboraInstance] = useState<any>()

	useEffect(() => {
		const customCheckout = (window as any).customcheckout()
		const style = {
			base: {
				color: '#000',
				fontSize: '16px',
			},
		}
		const options = {
			style,
			classes: {},
		}
		const cardNumber = customCheckout.create('card-number', {
			...options,
			placeholder: 'eg. 123456789101112',
		})
		cardNumber.mount('#card-number')
		const cardCVV = customCheckout.create('cvv', {
			...options,
			placeholder: 'eg. 123',
		})
		cardCVV.mount('#card-cvv')
		const cardExp = customCheckout.create('expiry', {
			...options,
			placeholder: 'MM/YY',
		})
		cardExp.mount('#card-expiry')
		setBamboraInstance(customCheckout)

		return () => {
			cardNumber.unmount()
			cardCVV.unmount()
			cardExp.unmount()
		}
	}, [])

	if (bamboraInstance) {
		bamboraInstance.on(
			'complete',
			function ({ field, complete }: { field: string; complete: boolean }) {
				setCompletedMap({
					...completedMap,
					[field]: complete,
				})
			}
		)
	}

	const saveCardHandler = (env: any) => {
		env ? env.preventDefault() : null
		const result = Object.values(completedMap).find(
			(complete: boolean) => !complete
		)
		if (result !== false) {
			bamboraInstance.createToken((e: any) =>
				debouncedBambora(() => {
					dispatch(
						saveCard({
							tokenizedCC: e.token,
							callback: (response: BeamResponse) => {
								if (response.error) {
									if (response.code == 'card_already_added') {
										setMessage("You've already saved this card")
									} else if (response?.code == 'try_again') {
										setMessage('Please try again')
									} else if (response?.code == 'maximum_cards_reached') {
										setMessage('Maximum number of cards reached')
									} else if (response?.code == 'card_validation_failed') {
										setMessage('Card validation failed')
									}
								} else {
									setView('addSuccess')
								}
							},
						})
					)
				})
			)
		} else {
			let errorMessage = 'Please fill out all fields'
			if (!completedMap['card-number']) {
				errorMessage = 'Please enter a valid card number'
			} else if (!completedMap.expiry) {
				errorMessage = 'Please enter a valid expiry date'
			}
			setMessage(errorMessage)
		}
	}

	return (
		<div>
			{message && (
				<div
					style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}
				>
					{message}
				</div>
			)}
			<form id="checkout-form" onSubmit={(e) => e.preventDefault()}>
				<div className="flexY wide Split">
					<label className="desktopOnly tablet">Card Number*</label>
					<div id="card-number" className="input wide"></div>
				</div>
				<div className="flexY wide">
					<div className="Split">
						<label className="desktopOnly tablet">Expiry Date*</label>
						<div id="card-expiry" className="input wide"></div>
					</div>
					<div className="Split">
						<label className="desktopOnly tablet">CVV*</label>
						<div id="card-cvv" className="input wide"></div>
					</div>
				</div>
				<div className="flexY">
					<div className="DecisionButton">
						<FreeButton
							className="button hollow wide"
							onClick={() => setView('all')}
						>
							Cancel
						</FreeButton>
					</div>
					<div className="DecisionButton">
						<FreeButton className="button wide" onClick={saveCardHandler}>
							Add Card
						</FreeButton>
					</div>
				</div>
			</form>
		</div>
	)
}
