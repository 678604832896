import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Conditional from '../../Components/Conditional/Conditional'
import MainContainer from '../../Components/MainContainer/MainContainer'
import { Loading } from 'react-ui-scaffold'
import './Cart.css'
import CartFooter from './CartFooter/CartFooter'
import DesktopCart from './DesktopCart/DesktopCart'
import MobileCart from './MobileCart/MobileCart'
import { State } from '../../Redux/store'
import { Link, useHistory, useLocation } from 'react-router-dom'
import PurchaseBladePopup from '../../Popups/PurchaseBladePopup/PurchaseBladePopup'
import ItemOutOfStockPopup from '../../Popups/ItemOutOfStockPopup/ItemOutOfStockPopup'
import useAddSharpening from '../../Hooks/useAddSharpening'
import { setNewSharpeningAddedfromStaticSite } from '../../Redux/Slices/cartSlice'

export default function Cart() {
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const cart = useSelector((s: State) => s.cart)
	const [purchaseBladePopupOpen, setPurchaseBladePopupOpen] = useState(false)
	const [itemOutOfStockPopupOpen, setItemOutOfStockPopupOpen] = useState(false)
	const [itemToReplace, setItemToReplace] = useState(undefined)
	const { search } = useLocation()
	const params = new URLSearchParams(search)
	const purchaseQuery = 'showPurchase'
	const history = useHistory()
	const showPurchase = params.get(purchaseQuery)
	const addSharpening = useAddSharpening()
	const currentURL = useLocation()
	const defaultProductId = useSelector(
		(s: State) => s.products.defaultProductId
	)
	const dispatch = useDispatch()

	useEffect(() => {
		if (defaultProductId) {
			const queryParams = new URLSearchParams(currentURL.search)
			const ref = queryParams.get('ref')
			const STATIC_SITE_URL = process.env.REACT_APP_STATIC_SITE_URL || 'NoRef'
			const referrer = document.referrer
			if (
				referrer &&
				ref &&
				referrer.includes(STATIC_SITE_URL) &&
				ref == 'isp'
			) {
				addSharpening()
				dispatch(setNewSharpeningAddedfromStaticSite(true))
			}
			// remove the document referrer.
			if (ref) {
				queryParams.delete('ref')
			}
			history.replace(`${currentURL.pathname}?${queryParams.toString()}`)
		}
	}, [defaultProductId])

	const erroredCartItem = useMemo(() => {
		return cart.contents.find((c: any) => c.error)
	}, [cart])

	useEffect(() => {
		if (erroredCartItem) {
			setItemOutOfStockPopupOpen(true)
		}
	}, [erroredCartItem])

	useEffect(() => {
		if (!purchaseBladePopupOpen) {
			setItemToReplace(undefined)
		}
	}, [purchaseBladePopupOpen])

	useEffect(() => {
		if (showPurchase) {
			setPurchaseBladePopupOpen(true)
			history.replace({
				search: '',
			})
		}
	}, [])

	return (
		<div className="Cart">
			<h1 className="hemi cartTitle">Cart</h1>
			{(cart.loading && (
				<div className="cartLoading">
					<Loading style={{ fontSize: '40px', position: 'absolute' }} />
				</div>
			)) || (
				<Conditional
					condition={cart.contents.length}
					fallback={
						<div className="emptyCart">
							Nothing in the cart
							<div className="flexCenter controller">
								<Link to="/Shop">
									<button className="button">Shop</button>
								</Link>
							</div>
						</div>
					}
				>
					<h2 className="cartTitle">Please specify your order.</h2>

					<MainContainer className="cartBody">
						<Conditional
							condition={screenSize === 'desktop'}
							fallback={<MobileCart cart={cart} />}
							contents={<DesktopCart cart={cart} />}
						/>
						<CartFooter
							cart={cart}
							setPurchaseBladePopupOpen={setPurchaseBladePopupOpen}
							disabled={Boolean(erroredCartItem)}
						/>
					</MainContainer>
				</Conditional>
			)}
			<PurchaseBladePopup
				visible={purchaseBladePopupOpen}
				setVisible={setPurchaseBladePopupOpen}
				itemToReplace={itemToReplace}
			/>

			<ItemOutOfStockPopup
				visible={itemOutOfStockPopupOpen}
				setVisible={setItemOutOfStockPopupOpen}
				cartItem={erroredCartItem}
				onSelectAnotherModel={() => {
					setPurchaseBladePopupOpen(true)
					setItemToReplace(erroredCartItem)
				}}
			/>
		</div>
	)
}
