import React, { useState } from 'react'
import './VerifyEmailPopup.css'
import Popup from '../../Components/Popup/Popup'
import { FreeButton } from 'react-ui-scaffold'
import beam from '../../Handlers/beam'

interface VerifyEmailPopupProps {
	visible: boolean
	setVisible?: any
}

export default function VerifyEmailPopup({
	visible,
	setVisible,
}: VerifyEmailPopupProps) {
	const [hasSent, setHasSent] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const sendVerificationEmail = () => {
		setLoading(true)
		beam
			.post('/api/accounts/verifyEmail')
			.then(() => {
				setHasSent(true)
			})
			.catch((err) => {
				setErrorMessage(err.response.error)
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<div className="ReviewOrder">
			<Popup
				visible={visible}
				setVisible={setVisible}
				title={'Verify Your Email Address'}
				className={''}
			>
				<div className="flexCenter"></div>
				{errorMessage && (
					<div
						style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}
					>
						{errorMessage}
					</div>
				)}
				{hasSent ? (
					<div className="flexCenter controls">
						<FreeButton className="button positive">
							Verification Email Sent
						</FreeButton>
					</div>
				) : (
					<div className="flexCenter controls">
						<FreeButton
							className="button"
							onClick={sendVerificationEmail}
							loading={loading}
						>
							Send Verification Email
						</FreeButton>
					</div>
				)}
			</Popup>
		</div>
	)
}
