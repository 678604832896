import React from 'react'
import './NotificationsClear.css'
import { useDispatch } from 'react-redux'
import { clearAllNotifications } from '../../../Redux/Slices/notificationSlice'

export default function NotificationsClear() {
	const dispatch = useDispatch()

	return (
		<button
			className="NotificationsClear"
			onClick={() => {
				dispatch(clearAllNotifications())
			}}
		>
			Clear All Notifications
		</button>
	)
}
