import React from 'react'
import './../MyPresets.css'
import MyPresetItem from './MyPresetItem'
import { UserPreset } from '../../../Redux/Slices/presetsSlice'

export default function MyPresetsList({
	presets,
	setShowIndPopup,
}: {
	presets: UserPreset[]
	setShowIndPopup(value: boolean): void
}) {
	return (
		<div className="MyPresetsList">
			{presets.map((preset) => (
				<MyPresetItem
					key={preset.presetID}
					preset={preset}
					setShowIndPopup={setShowIndPopup}
				/>
			))}
		</div>
	)
}
