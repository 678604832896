import React, { useState } from 'react'
import './PhoneNumberPopup.css'
import { useDispatch } from 'react-redux'
import Popup from '../../Components/Popup/Popup'
import { updateUserData } from '../../Redux/Slices/activeUserSlice'

interface PhoneNumberPopupProps {
	visible: boolean
	setVisible?: any
	closeCallback(): void
}

export default function PhoneNumberPopup({
	visible,
	setVisible,
	closeCallback,
}: PhoneNumberPopupProps) {
	const dispatch = useDispatch()
	const [phone, setPhone] = useState('')
	const [error, setError] = useState('')

	const submit = () => {
		setError('')
		if (!phone) {
			return setError('Please enter a valid phone number')
		}
		dispatch(
			updateUserData({
				updates: {
					phone,
				},
				callback: (error?: string) => {
					if (error) {
						return setError(error)
					}
					closeCallback()
				},
			})
		)
	}

	return (
		<Popup
			title="Enter Your Phone Number"
			visible={visible}
			setVisible={setVisible}
			className="PhoneNumberPopup"
			onExit={closeCallback}
		>
			<div className="PhoneNumberContent">
				<div className="story PhoneErrorMessage">{error}</div>

				<div className="PhoneNumberMessage">
					Please enter your phone number in order to complete your account
					registration
				</div>
				<div className="flexY">
					<input
						type="text"
						className="input"
						placeholder="Phone Number"
						data-htmlFor="phone"
						name="phone"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
					<button className="button neutral submit" onClick={submit}>
						Submit
					</button>
				</div>
			</div>
		</Popup>
	)
}
