import { configureStore } from '@reduxjs/toolkit'
import activeUser from './Slices/activeUserSlice'
import cart from './Slices/cartSlice'
import view from './Slices/viewSlice'
import orders from './Slices/ordersSlice'
import options from './Slices/optionsSlice'
import notifications from './Slices/notificationSlice'
import cards from './Slices/cardsSlice'
import presets from './Slices/presetsSlice'
import products from './Slices/productsSlice'
import blades from './Slices/bladesSlice'

export const store = configureStore({
	reducer: {
		activeUser,
		cart,
		view,
		orders,
		options,
		notifications,
		presets,
		cards,
		products,
		blades,
	},
})

export type State = ReturnType<typeof store.getState>
