import React from 'react'
import './Option3.css'
import Option from '../Option/Option'
import { data } from './data'
import TwoImgs from '../Option/Support/TwoImgs/TwoImgs'

export default function Option3() {
	return (
		<Option optionNumber={data.optionNumber} title={data.title}>
			<div className="BladeHolderContent">
				<div className="PBDescriptionText">{data.content.section1.text}</div>
				<div className="PBImgsWrapper">
					<TwoImgs data={data.content.section2} />
				</div>
				<div className="PBDescriptionText PBDescriptionLink">
					<div className="PBDescriptionText alignLeft">
						{data.content.section3.text}
					</div>
					<div className="BHDescription">
						<img
							className="BHRemoveBlade"
							src={data.content.section3.gif.img}
							alt={data.content.section3.gif.altImg}
						/>
					</div>
				</div>
			</div>
		</Option>
	)
}
