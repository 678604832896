import React from 'react'
import auth from '../../../../Handlers/auth'
import { Link, useHistory } from 'react-router-dom'

export default function UserMenu() {
	const history = useHistory()

	return (
		<div className="UserMenu" style={{ width: '200px' }}>
			<Link to="/MyAccount">
				<button className="button wide">My Account</button>
			</Link>

			<Link to="/MyOrders">
				<button className="button wide">My Orders</button>
			</Link>

			<Link to="/MyPresets">
				<button className="button wide">My Presets</button>
			</Link>

			<button
				className="button wide negative"
				onClick={() => {
					auth.logout()
					history.push('/')
				}}
			>
				Sign Out
			</button>
		</div>
	)
}
