import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Conditional from '../../../../Components/Conditional/Conditional'
import PresetPopup, {
	PresetPopupViews,
} from '../../../../Popups/PresetsPopup/PresetPopup'
import { State } from '../../../../Redux/store'
import PresetsList from './PresetsList/PresetsList'
import './PresetsList/PresetsList.css'

function PresetsWidget() {
	const allPresets = useSelector((s: State) => s.presets.list)
	const presetsLoading = useSelector((s: State) => s.presets.loading)
	const history = useHistory()
	const [showPresetPopup, setShowPresetPopup] = useState(false)
	const [showIndPopup, setShowIndPopup] = useState(false)

	return (
		<div className="NotificationWidget widget large">
			<PresetPopup
				visible={showPresetPopup}
				setVisible={setShowPresetPopup}
				currentView={PresetPopupViews.add}
			/>
			<PresetPopup visible={showIndPopup} setVisible={setShowIndPopup} />

			<div className="titleBar">
				<span className="hemi">My Presets</span>
				<button
					className="button neutral plain viewAll"
					onClick={() => history.push('/MyPresets')}
				>
					View All
				</button>
			</div>
			<Conditional
				condition={allPresets.length}
				fallback={
					<div className="emptyNotePresets">
						{presetsLoading ? 'Loading...' : 'No Presets'}
					</div>
				}
			>
				<PresetsList presets={allPresets} setShowIndPopup={setShowIndPopup} />
			</Conditional>
			<div
				className={`AddPresetWrapper ${
					!allPresets.length ? 'NoPresetsBtnWrapper' : ''
				}`}
			>
				<button className="button" onClick={() => setShowPresetPopup(true)}>
					Add Preset
				</button>
			</div>
		</div>
	)
}

export default PresetsWidget
