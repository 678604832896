import React from 'react'
import dayjs from 'dayjs'
import './SpecsHistoryPopup.css'
import { useDispatch, useSelector } from 'react-redux'
import { specsHistorySelector } from '../../Redux/Slices/ordersSlice'
import { updateCartItem } from '../../Redux/Slices/cartSlice'
import Popup from '../../Components/Popup/Popup'

interface SpecsHistoryPopupProps {
	cartItemId: any
	visible: boolean
	setVisible: any
}

export default function SpecsHistoryPopup({
	cartItemId,
	visible,
	setVisible,
}: SpecsHistoryPopupProps) {
	const dispatch = useDispatch()

	const specsHistory = useSelector(specsHistorySelector)

	const exitHandler = () => setVisible(false)

	return (
		<Popup
			title="Specs History"
			className="SpecsHistoryPopup"
			visible={visible}
			onExit={exitHandler}
			setVisible={setVisible}
		>
			{specsHistory.length ? (
				<table>
					<tr className="header hemi">
						<th>Specs</th>
						<th>Last Used</th>
					</tr>
					{specsHistory.map((specs: any, idx: number) => (
						<tr key={idx} className="historyInfo">
							<td
								className="specInfo"
								onClick={() => {
									dispatch(
										updateCartItem({
											id: cartItemId,
											updates: {
												hollowValue: specs.hollowValue,
												radiusValue: specs.radiusValue,
												pitchValue: specs.pitchValue,
												presetID: null,
											},
										})
									)
									exitHandler()
								}}
							>
								<span className="light">
									Hollow: <b>{specs.hollowValue}&quot;</b>
								</span>
								<span className="light">
									Radius: <b>{specs.radiusValue}&apos;</b>
								</span>
								<span className="light">
									Pitch: <b>P{specs.pitchValue}</b>
								</span>
							</td>
							<td>
								<b>{dayjs(specs.placedDate).format('MMMM DD, YYYY')}</b>
							</td>
						</tr>
					))}
				</table>
			) : (
				<span className="emptyNote">No Orders to Show</span>
			)}
		</Popup>
	)
}
