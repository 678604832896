import React from 'react'

import './App.css'
import './Assets/fonts/ef_conv_zip_qlwBN52tL5xq1sc9ZoTtoweF/hemi_head_bd_it.css'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { useScreenSize } from './Hooks/useScreenSize'
import { useSkatescribeAuthState } from './Hooks/useSkatescribeAuthState'
import { useSelector } from 'react-redux'
import { Page } from 'react-ui-scaffold'
import Main from './Pages/Main/Main'

import carousel1 from './Assets/photos/carousel-1.jpg'
import carousel2 from './Assets/photos/carousel-2.jpg'
import carousel3 from './Assets/photos/carousel-3.jpg'
import carousel4 from './Assets/photos/carousel-4.jpg'
import carousel5 from './Assets/photos/carousel-5.jpg'
import { State } from './Redux/store'

export default function App() {
	useScreenSize()

	const { screenSize, hasPopup } = useSelector((s: State) => s.view)
	const authStatus = useSelector((s: State) => s.activeUser.status)

	const sliderImages = [carousel1, carousel2, carousel3, carousel4, carousel5]
	preloadImages(sliderImages)

	useSkatescribeAuthState()

	const contentClass = hasPopup ? ' appBackground ' : ''

	if (authStatus === 'Loading') {
		return <Page loading={true} />
	}

	return (
		<BrowserRouter>
			<div className={'App ' + screenSize}>
				<div
					id="portalDestination-popupContainer"
					className="portalDestination"
				></div>
				{process.env.REACT_APP_ENV &&
					process.env.REACT_APP_ENV !== 'production' && (
						<div className="env hemi">
							This is the {process.env.REACT_APP_ENV} site
						</div>
					)}

				<div className={'appContents ' + contentClass}>
					<Switch>
						<Route path="/" component={Main} />
					</Switch>
				</div>
			</div>
		</BrowserRouter>
	)
}

function preloadImages(imgs: string[]) {
	imgs.forEach((src) => {
		const img = new Image()
		img.src = src
	})
}
