import React, { ChangeEventHandler, useEffect, useState } from 'react'
import './LoginScreen.css'
import { FreeButton } from 'react-ui-scaffold'
import auth from '../../../../Handlers/auth'
import SocialLogin from '../SocialLogin/SocialLogin'
import PhoneNumberPopup from '../../../PhoneNumberPopup/PhoneNumberPopup'
import { State } from '../../../../Redux/store'
import { useSelector } from 'react-redux'
import { MsgI } from '../../SignInPopup'

interface LogInScreenProps {
	changeHandler: ChangeEventHandler<HTMLInputElement>
	data: any
	setActiveTab(value: string): void
	setMessage(msg: MsgI | null): void
	setVisible(value: boolean): void
	setLoading(value: boolean): void
}

export default function LogInScreen({
	changeHandler,
	data,
	setActiveTab,
	setMessage,
	setVisible,
	setLoading,
}: LogInScreenProps) {
	const [phonePopupVisible, setPhonePopupVisible] = useState(false)
	const [isSocialLogin, setIsSocialLogin] = useState(false)
	const userData = useSelector((s: State) => s.activeUser.data)

	function login() {
		setLoading(true)

		auth
			.login(data.email, data.password)
			.then(() => {
				setVisible(false)
			})
			.catch((err) => {
				setMessage({
					text: err,
					color: 'var(--negativeAccentColor)',
				})
			})

		setLoading(false)
	}

	useEffect(() => {
		if (isSocialLogin && 'phone' in userData) {
			if (userData.phone) {
				setVisible(false)
			} else {
				setPhonePopupVisible(true)
			}
		}
	}, [isSocialLogin, userData])

	return (
		<div className="LogIn">
			<form
				onSubmit={(e) => {
					e.preventDefault()
					login()
				}}
			>
				<div>
					<label htmlFor="Email">Email</label>
					<input
						type="text"
						className="input wide"
						placeholder="example@email.com"
						data-htmlFor="email"
						name="email"
						value={data.email}
						onChange={changeHandler}
					/>
				</div>

				<div>
					<label htmlFor="Email">Password</label>
					<input
						type="password"
						className="input wide"
						placeholder="Password"
						data-htmlFor="password"
						name="password"
						value={data.password}
						onChange={changeHandler}
					/>
				</div>
				<button type="submit" className="button wide">
					{' '}
					Log In{' '}
				</button>
			</form>

			<div className="story">
				<button
					className="button plain wide neutral"
					onClick={() => setActiveTab('forgot')}
				>
					<b>Forgot your password?</b>
				</button>
			</div>

			<SocialLogin successHandler={() => setIsSocialLogin(true)} />
			<PhoneNumberPopup
				visible={phonePopupVisible}
				setVisible={setPhonePopupVisible}
				closeCallback={() => setVisible(false)}
			/>

			<div
				className="story"
				style={{ textAlign: 'center', fontWeight: 'bold' }}
			>
				OR
			</div>

			<div className="story">
				<FreeButton
					className="button plain wide neutral"
					onClick={() => {
						setActiveTab('create')
					}}
				>
					<b>Not a member yet? Sign up</b>
				</FreeButton>
			</div>
		</div>
	)
}
