import React from 'react'
import './AccountPerks.css'
import { PromptTab } from '../AccountPromptPopup'
import accountSale from '../../../Assets/graphics/create-acct-sale.svg'
import accountClock from '../../../Assets/graphics/create-acct-clock.svg'
import accountStatus from '../../../Assets/graphics/create-acct-status.svg'

interface AccountPerksPopupProps {
	setVisible(value: boolean): void
	setActiveTab(tab: PromptTab): void
}

export default function AccountPerksPopup({
	setVisible,
	setActiveTab,
}: AccountPerksPopupProps) {
	return (
		<div className="AccountPerks">
			<div className="pretext">
				Creating an account with Skatescribe will allow you to:
			</div>
			<div className="perksList">
				<div className="perkItem">
					<img src={accountSale} />
					<p>Hear about deals and promotions first</p>
				</div>
				<div className="perkItem">
					<img src={accountClock} />
					<p>Place orders more quickly and easily</p>
				</div>
				<div className="perkItem">
					<img src={accountStatus} />
					<p>See your order statuses in one place</p>
				</div>
			</div>
			<div className="buttonContainer">
				<button
					className="button"
					onClick={() => setActiveTab(PromptTab.CreateAccount)}
				>
					Create an Account
				</button>
				<button
					className="button neutral plain"
					onClick={() => setVisible(false)}
				>
					No, thanks
				</button>
			</div>
		</div>
	)
}
