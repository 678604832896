import {
	faBars,
	faShoppingCart,
	faBell,
	faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logoURL from '../../Assets/graphics/logo.png'
import logoMobile from '../../Assets/graphics/logo.svg'
import SignInPopup from '../../Popups/SignInPopup/SignInPopup'
import { MenuButton } from 'react-ui-scaffold'
import Conditional from '../Conditional/Conditional'
import LoggedInOnly from '../LoggedInOnly/LoggedInOnly'
import LoggedOutOnly from '../LoggedOutOnly/LoggedOutOnly'
import './Navbar.css'
import BurgerMenu from './Support/BurgerMenu/BurgerMenu'
import UserMenu from './Support/UserMenu/UserMenu'
import { unreadNotificationsSelector } from '../../Redux/Slices/notificationSlice'
import { defaultImageUrlSelector } from '../../Redux/Slices/optionsSlice'
import { State } from '../../Redux/store'

export default function Navbar() {
	const [showSignIn, setShowSignIn] = useState<boolean>(false)
	const cartCount = useSelector((s: State) => s.cart.contents.length)

	const unreadNotifications = useSelector(unreadNotificationsSelector)
	const unreadCount = unreadNotifications.length

	const { status } = useSelector((s: State) => s.activeUser)
	const profileImage = useSelector((s: State) => s.activeUser.data.imageURL)
	const defaultImageUrl = useSelector(defaultImageUrlSelector)

	const screenSize = useSelector((s: State) => s.view.screenSize)

	return (
		<>
			<SignInPopup visible={showSignIn} setVisible={setShowSignIn} />

			<div className="Navbar flexY">
				<div className="logo desktopOnly">
					<Link to="/">
						<img src={logoURL} alt="logo" draggable="false" />
					</Link>
				</div>

				<div className="flexY noDesktop">
					<div className="burger">
						<MenuButton
							menuContent={BurgerMenu}
							style={{ maxWidth: 'none' }}
							menuProps={{ setShowSignIn }}
						>
							<Conditional
								condition={unreadCount > 0 && screenSize === 'mobile'}
							>
								<span className="badge">{unreadCount}</span>
							</Conditional>
							<FontAwesomeIcon icon={faBars} />
						</MenuButton>
					</div>
					<div className="logo">
						<Link to="/">
							<img src={logoMobile} alt="logo" draggable="false" />
						</Link>
					</div>

					<div className="noDesktop noTablet toRight">
						<Link to="/Shop">
							<button className="button plain neutral">
								<b>Shop</b>
							</button>
						</Link>

						<Link to="/Cart">
							<button className="button plain">
								<FontAwesomeIcon icon={faShoppingCart} />
							</button>
						</Link>
					</div>
				</div>

				<div className="controls flexY noMobile">
					<a
						className="link desktopOnly"
						href="https://skatescribe-site.demo.cionstudio.com/#footer"
						target="_blank"
						rel="noopener noreferrer"
					>
						Contact Us
					</a>

					<LoggedOutOnly>
						<button
							className="link button plain noMobile"
							onClick={() => setShowSignIn(true)}
						>
							Log In
						</button>
					</LoggedOutOnly>

					<span className="link noMobile">
						<Link to="/Shop">
							<button className="button negative">Shop</button>
						</Link>
					</span>

					<Link to="/Cart" className="iconLink">
						<FontAwesomeIcon icon={faShoppingCart} className="icon" />
						<span className="iconText">
							Cart{' '}
							<Conditional condition={cartCount}> ({cartCount})</Conditional>
						</span>
					</Link>

					{status === 'Logged In' && (
						<Link to="/Notifications" className="iconLink">
							<div className="iconContainer">
								<Conditional condition={unreadCount > 0}>
									<span className="badge">{unreadCount}</span>
								</Conditional>
								<FontAwesomeIcon icon={faBell} className="icon" />
							</div>
							<span className="iconText">Notifications</span>
						</Link>
					)}

					<LoggedInOnly>
						<MenuButton
							className="noMobile"
							style={{ padding: 0, fontSize: '23pt' }}
							menuContent={UserMenu}
						>
							<div className="profileImageContainer">
								<img
									className="profileImage"
									src={`${process.env.REACT_APP_SERVER_URL}${
										profileImage || defaultImageUrl
									}`}
								/>
								<span className="userMenuButtonText">
									<span className="iconText">Profile</span>
									<FontAwesomeIcon icon={faCaretDown} />
								</span>
							</div>
						</MenuButton>
					</LoggedInOnly>
				</div>
			</div>
		</>
	)
}
