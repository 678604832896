import React from 'react'
import './PostOrderStep.css'

export interface PostOrderStepProps {
	children: React.ReactNode
	number?: number | string
	title?: string
}

function PostOrderStep({
	children: content,
	number,
	title,
}: PostOrderStepProps) {
	return (
		<div
			className={`PostOrderStepContainer active ${title ? '' : ' NoTopMargin'}`}
		>
			{title && (
				<div className="postOrderStepHeader">
					<h2 className="stepLabel hemi">Step {number}: </h2>
					<h2 className="hemi">{title}</h2>
				</div>
			)}
			<div className="OrderStepContent">{content}</div>
		</div>
	)
}

export default PostOrderStep
