import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import ProfileImageSelector from '../../../../Components/ProfileImageSelector/ProfileImageSelector'
import { defaultPresetImageSelector } from '../../../../Redux/Slices/optionsSlice'
import {
	createUserPreset,
	setCurrentPresetNull,
	updateCurrentPreset,
	UserPreset,
} from '../../../../Redux/Slices/presetsSlice'
import { SpecTab } from '../../../ChooseSpecsPopup/ChooseSpecsPopup'
import pitchSVG from './../../../../Assets/graphics/pitch.svg'
import hollowSVG from './../../../../Assets/graphics/parameter-hollow.svg'
import radiusSVG from './../../../../Assets/graphics/parameter-profile.svg'
import { PresetPopupViews } from '../../PresetPopup'
import { State } from '../../../../Redux/store'

// Interface for props.
interface AddPresetPropsI {
	visible: boolean
	setVisible(value: boolean): void
	setNewlyCreated?(value: boolean): void
	setActiveTab?(tab: SpecTab): void
	setView(view: PresetPopupViews): void
	newPresetSpecs: Partial<UserPreset> | undefined
	setNewPresetSpecs(view?: Partial<UserPreset>): void
}

export default function AddPreset({
	visible,
	setVisible,
	setActiveTab,
	setNewlyCreated,
	newPresetSpecs,
	setNewPresetSpecs,
	setView,
}: AddPresetPropsI) {
	const dispatch = useDispatch()
	const defaultImage = useSelector(defaultPresetImageSelector)

	const currentPreset = useSelector(
		(state: State) => state.presets.currentPreset
	)

	useEffect(() => {
		if (defaultImage) {
			if (!currentPreset?.imageID && !currentPreset?.imagePath) {
				dispatch(
					updateCurrentPreset({
						imageID: defaultImage.profileImageId,
						imagePath: defaultImage.uri,
					})
				)
			}
		}
	}, [visible, defaultImage])

	useEffect(() => {
		if (newPresetSpecs) {
			dispatch(
				updateCurrentPreset({
					hollowValue: newPresetSpecs?.hollowValue,
					pitchValue: newPresetSpecs?.pitchValue,
					radiusValue: newPresetSpecs?.radiusValue,
				})
			)
			setNewPresetSpecs(undefined)
		}
	}, [visible])

	const handleAddNewPreset = () => {
		if (
			!(
				currentPreset?.presetName &&
				currentPreset?.imageID != undefined &&
				currentPreset?.hollowValue &&
				currentPreset?.pitchValue != undefined &&
				currentPreset?.radiusValue != undefined &&
				currentPreset?.imagePath
			)
		) {
			return
		}

		try {
			dispatch(
				createUserPreset({
					presetName: currentPreset?.presetName,
					imageID: currentPreset?.imageID,
					hollowValue: currentPreset?.hollowValue,
					pitchValue: currentPreset?.pitchValue,
					radiusValue: currentPreset?.radiusValue,
					imagePath: currentPreset?.imagePath,
				})
			)
			setNewlyCreated && setNewlyCreated(true)
			setActiveTab && setActiveTab(SpecTab.MyPresets)
			setVisible(false)
			dispatch(setCurrentPresetNull())
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Popup
			title="Add Preset"
			visible={visible}
			setVisible={() => {
				setVisible(false)
				dispatch(setCurrentPresetNull())
			}}
			className="AddPresetPopup"
		>
			<div
				className="flexY EditPresetPopupWrapper"
				style={{ justifyContent: 'center' }}
			>
				<div className="wide NewPresetContentWrapper">
					<div className="NewPresetTitleWrapper">
						<ProfileImageSelector
							image={currentPreset?.imagePath}
							defaultImage={currentPreset?.imageID}
							selectHandler={(img: any) => {
								dispatch(
									updateCurrentPreset({
										imageID: img.profileImageId,
										imagePath: img.uri,
									})
								)
							}}
						/>
						<div className="AddPresetNameWrapper">
							<input
								className="input"
								type="text"
								placeholder="Preset Name*"
								value={currentPreset?.presetName}
								onChange={(e) => {
									dispatch(updateCurrentPreset({ presetName: e.target.value }))
								}}
							/>
						</div>
					</div>

					<div className="NewPresetSpecsWrapper">
						<div className="NewPresetTitle">
							<div>Specs:</div>
							<div
								style={{
									color: 'var(--neutralAccentColor)',
									cursor: 'pointer',
								}}
								onClick={() => {
									setView(PresetPopupViews.newPresetSpecs)
								}}
							>
								Change Specs
							</div>
						</div>
						<div className="NewPresetSpecs">
							{currentPreset?.hollowValue ||
							currentPreset?.radiusValue != undefined ||
							currentPreset?.pitchValue != undefined ? (
								<>
									<div className="NewPresetValuesWrapper">
										<div className="NewPresetValueItem">
											<div className="ValueName">Hollow</div>
											<div className="ValueContentWrapperV">
												<div
													style={{
														fontSize: '1.5rem',
														textAlign: 'center',
													}}
												>
													{' '}
													{currentPreset?.hollowValue
														? `${currentPreset?.hollowValue}"`
														: '-'}
												</div>
												<img src={hollowSVG} />
											</div>
										</div>
										<div className="NewPresetValueItem">
											<div className="ValueName">Radius</div>
											<div className="ValueContentWrapperV">
												<div
													style={{
														fontSize: '1.5rem',
														textAlign: 'center',
													}}
												>
													{' '}
													{currentPreset?.radiusValue
														? `${currentPreset?.radiusValue}'`
														: '-'}
												</div>
												<img src={radiusSVG} />
											</div>
										</div>
										<div className="NewPresetValueItem">
											<div className="ValueName">Pitch</div>
											<div className="ValueContentWrapperV">
												<div
													style={{
														fontSize: '1.5rem',
													}}
												>
													{currentPreset?.pitchValue !== undefined
														? `P${currentPreset?.pitchValue}`
														: '-'}
												</div>
												<img src={pitchSVG} />
											</div>
										</div>
									</div>
								</>
							) : (
								<div
									className="NewPresetSpecsPlaceholder"
									onClick={() => {
										setView(PresetPopupViews.newPresetSpecs)
									}}
								>
									Choose Specs
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="EditPresetPopupBtnWrapper">
					<FreeButton
						className="button plain neutral NeutralOutline"
						onClick={() => {
							setVisible(false)
							dispatch(setCurrentPresetNull())
						}}
					>
						Cancel
					</FreeButton>
					<FreeButton className="button" onClick={handleAddNewPreset}>
						Add Preset
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
