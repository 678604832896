import React, { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import profileSVG from '../../../../Assets/graphics/parameter-profile.svg'
import Slider from '../../../../Components/Slider/Slider'
import { radiusSelector } from '../../../../Redux/Slices/optionsSlice'
import './RadiusValue.css'

interface RadiusValueProps {
	radiusValue: number | undefined
	setRadiusValue(value?: number): void
}

export default function RadiusValue({
	radiusValue,
	setRadiusValue,
}: RadiusValueProps) {
	const radius = useSelector(radiusSelector)

	function slideHandler(newValue: string) {
		setRadiusValue(Number(newValue))
	}

	const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setRadiusValue(Number(e.target.value))
	}

	return (
		<div className="specItem RadiusValue">
			<div className="topContainer">
				<div className="inputContainer">
					Radius:
					<input
						className="input"
						type="number"
						min={radius.range[0]}
						max={radius.range[1]}
						value={radiusValue || radius.range[0]}
						onChange={(e) => changeHandler(e)}
						onKeyDown={(e) => e.preventDefault()}
					/>
				</div>

				<div className="graphicContainer">
					<span className="value">{radiusValue ? `${radiusValue}'` : "'"}</span>
					<img src={profileSVG} />
				</div>
			</div>

			<div className="sliderContainer">
				<Slider
					step={0.5}
					leftTip={'Agility'}
					rightTip={'Speed'}
					max={radius.range[1]}
					min={radius.range[0]}
					value={Number(radiusValue || radius.range[0])}
					onChange={slideHandler}
					minLabel={radius.range[0]}
					maxLabel={radius.range[1]}
				/>
			</div>
		</div>
	)
}
