import React from 'react'
import { InfoTab } from 'react-ui-scaffold'
import purchaseBladeImage from '../../../../Assets/graphics/purchase-placeholder.svg'
import './HolderTab.css'

interface HolderTabProps {
	tabID: string
	value: string
	options?: string[]
	setResponse: (response: string) => void
	error: string
}

export default function HolderTab({
	tabID,
	value,
	options = [],
	setResponse,
	error,
}: HolderTabProps) {
	return (
		<InfoTab className="QuestionTab HolderTab" tabID={tabID}>
			<div className="Question">
				Select your&nbsp;<b>holder type</b>.
			</div>
			<div className="QuestionError">{error}</div>
			<div className="HolderContainer">
				{options.map((option) => {
					const selected = value == option
					return (
						<div key={option} className="HolderWrapper">
							<div
								className={`${selected ? 'Active' : ''} Holder hemi`}
								onClick={() => setResponse(option)}
							>
								<div className="CheckboxName title">
									<input
										type="radio"
										className="input"
										checked={selected}
										style={{ marginRight: 10 }}
										onChange={() => setResponse(option)}
									/>
									{option}
								</div>
								<img
									src={purchaseBladeImage}
									alt="purchase-blade"
									draggable="false"
									className="PurchaseBladeImage"
								/>
							</div>
						</div>
					)
				})}
			</div>
		</InfoTab>
	)
}
