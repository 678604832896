import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../../Components/Popup/Popup'
import ProfileImageSelector from '../../../../Components/ProfileImageSelector/ProfileImageSelector'
import {
	updateCurrentPreset,
	updatePresetImage,
	updateUserPreset,
} from '../../../../Redux/Slices/presetsSlice'
import { State } from '../../../../Redux/store'
import { PresetPopupViews } from '../../PresetPopup'
import HollowValue from '../HollowValue/HollowValue'
import PitchValue from '../PitchValue/PitchValue'
import RadiusValue from '../RadiusValue/RadiusValue'

// Interface for props.
interface EditPresetPropsI {
	visible: boolean
	setVisible(value: boolean): void
	setView(view: PresetPopupViews): void
}

export default function EditPreset({
	visible,
	setVisible,
	setView,
}: EditPresetPropsI) {
	const preset = useSelector((s: State) => s.presets.currentPreset)

	const [hollowValue, setHollowValue] = useState(preset?.hollowValue || '')
	const [radiusValue, setRadiusValue] = useState(preset?.radiusValue)
	const [pitchValue, setPitchValue] = useState(preset?.pitchValue)
	const [imgPath, setimgPath] = useState(preset?.imageID)

	const dispatch = useDispatch()
	const handleUpdatePreset = () => {
		dispatch(
			updateUserPreset({
				presetID: preset?.presetID!,
				updates: {
					hollowValue: hollowValue,
					pitchValue: pitchValue,
					radiusValue: radiusValue,
				},
			})
		)
		dispatch(
			updateCurrentPreset({
				...preset,
				hollowValue: hollowValue,
				pitchValue: pitchValue,
				radiusValue: radiusValue,
				imageID: imgPath,
			})
		)
		setView(PresetPopupViews.view)
	}

	return (
		<Popup
			title="Choose Preset Specs"
			visible={visible}
			setVisible={setVisible}
			className="PresetEditPopup"
		>
			<div
				className="flexY EditPresetPopupWrapper"
				style={{ justifyContent: 'center' }}
			>
				<div className="wide EditPresetContentWrapper">
					<div className="EditPresettPopupTitle">
						<ProfileImageSelector
							image={imgPath}
							selectHandler={(img: any) => {
								dispatch(
									updatePresetImage({
										presetID: preset?.presetID!,
										imageID: img.profileImageId,
										imagePath: img.uri,
									})
								)
								setimgPath(img.uri)
							}}
						/>
						<div className="EditPresettPopupName">{preset?.presetName}</div>
					</div>

					<div className="EditPresettPopupData">
						<div className="DataItemWrapper">
							<HollowValue
								hollowValue={hollowValue}
								setHollowValue={setHollowValue}
							/>
						</div>
					</div>

					<div className="EditPresettPopupData">
						<div className="DataItemWrapper">
							<RadiusValue
								radiusValue={radiusValue}
								setRadiusValue={setRadiusValue}
							/>
						</div>
					</div>

					<div className="EditPresettPopupData">
						<div className="DataItemWrapper">
							<PitchValue
								pitchValue={pitchValue}
								setPitchValue={setPitchValue}
							/>
						</div>
					</div>
				</div>
				<div className="EditPresetPopupBtnWrapper">
					<div
						className="PresetPopupCancelBtn"
						onClick={() => {
							setVisible(false)
						}}
					>
						Cancel
					</div>
					<FreeButton className="button" onClick={handleUpdatePreset}>
						Save Changes
					</FreeButton>
				</div>
			</div>
		</Popup>
	)
}
