import { setAccount, setUserData } from '../Redux/Slices/activeUserSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { fetchOrderByPage } from '../Redux/Slices/ordersSlice'
import beam from '../Handlers/beam'
import {
	fetchCartContents,
	updateCart,
	fetchLastUsedLocation,
} from '../Redux/Slices/cartSlice'
import {
	fetchNotifications,
	clearNotifications,
} from '../Redux/Slices/notificationSlice'
import {
	fetchLocationOptions,
	fetchPlayerImageOptions,
	fetchPresetOptions,
	fetchSettingsOptions,
} from '../Redux/Slices/optionsSlice'
import { fetchUserPresets } from '../Redux/Slices/presetsSlice'
import { fetchAllProducts } from '../Redux/Slices/productsSlice'
import { fetchCards } from '../Redux/Slices/cardsSlice'
import { fetchAllBlades } from '../Redux/Slices/bladesSlice'

//Listens for any changes in auth state and dispateches actions to the redux store
export function useSkatescribeAuthState() {
	const dispatch = useDispatch()

	useEffect(() => {
		const unsubAuth = firebase.auth().onAuthStateChanged(async (user) => {
			const cleanUser = JSON.parse(JSON.stringify(user))

			firebase
				.auth()
				.currentUser?.getIdToken()
				.then(() => {
					// if(process.env.NODE_ENV==='development'){console.log(token)}
				})
				.catch(console.error)

			dispatch(setAccount(cleanUser))

			//fetch the user's data when they sign in
			if (user) {
				const userData = await beam.get('/api/player/' + user.email)

				dispatch(setUserData(userData.response))
				dispatch(fetchNotifications())
				dispatch(fetchOrderByPage())
				dispatch(fetchCartContents(true))
				dispatch(fetchSettingsOptions())
				dispatch(fetchPlayerImageOptions())
				dispatch(fetchLastUsedLocation())
				dispatch(fetchUserPresets())
				//Update contact info of the cart with the info we have
				dispatch(
					updateCart({
						contactEmail: userData.response.playerEmail,
						contactPhone: userData.response.phone,
						contactFirstName: userData.response.playerFirstName,
						contactLastName: userData.response.playerLastName,
						contactPostalCode: userData.response.postalCode,
					})
				)
				dispatch(fetchCards())

				// this ensures that any actions on login
				// don't trigger on page refresh
				localStorage.removeItem('loginActions')
			} else {
				dispatch(setUserData({}))
				dispatch(clearNotifications())
				dispatch(fetchCartContents(false))

				// setting up any actions that happen on login
				localStorage.setItem('loginActions', 'true')
			}

			dispatch(fetchLocationOptions())
			dispatch(fetchPresetOptions())
			dispatch(fetchAllProducts())
			dispatch(fetchAllBlades())
		})

		return unsubAuth
	}, [])
}
