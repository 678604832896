import React, { useContext } from 'react'
import './DisclaimerPopup.css'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '../../Components/Popup/Popup'
import {
	saveReccomendedSpecs,
	updateCartItem,
} from '../../Redux/Slices/cartSlice'
import { hollowSelector, SpecType } from '../../Redux/Slices/optionsSlice'
import { getFraction } from '../../Utils/generalUtils'
import { RecoSpecsContext } from '../ChooseSpecsPopup/chooseSpecsContexts'

interface RecommendedSpecs {
	radiusValue: number
	hollowValue: number
}

interface DisclaimerPopupProps {
	visible: boolean
	setVisible(value: boolean): void
	recoSpecs: RecommendedSpecs
	responses: Object
	recoSpecsExitHandler(): void
}

export default function DisclaimerPopup({
	visible,
	setVisible,
	recoSpecs,
	responses,
	recoSpecsExitHandler,
}: DisclaimerPopupProps) {
	const dispatch = useDispatch()
	const hollowOptions = useSelector(hollowSelector)
	const { chooseSpecsExitHandler, cartItemId } = useContext(RecoSpecsContext)

	const submitSpecs = () => {
		const hollowFractions = hollowOptions.map((option) => getFraction(option))
		const hollowFraction = hollowFractions.find(
			(fracOption) => recoSpecs.hollowValue <= fracOption
		)

		let matchIdx = hollowFraction
			? hollowFractions.indexOf(hollowFraction)
			: hollowOptions.length - 1

		if (matchIdx !== 0) {
			// closest hollow value fraction will either be at matchIdx or (matchIdx - 1)
			const lowerBoundDiff =
				recoSpecs.hollowValue - hollowFractions[matchIdx - 1]
			const upperBoundDiff = hollowFractions[matchIdx] - recoSpecs.hollowValue
			if (lowerBoundDiff < upperBoundDiff) matchIdx -= 1
		}

		const hollowValue = hollowOptions[matchIdx]

		dispatch(
			updateCartItem({
				id: cartItemId,
				updates: {
					...recoSpecs,
					hollowValue,
					specType: SpecType.Advanced,
				},
			})
		)

		dispatch(
			saveReccomendedSpecs({
				responses,
				recoSpecs,
			})
		)

		setVisible(false)
		recoSpecsExitHandler()
		chooseSpecsExitHandler()
	}

	return (
		<Popup
			title="Disclaimer"
			visible={visible}
			setVisible={setVisible}
			className="DisclaimerPopup"
			onExit={() => setVisible(false)}
		>
			<div>
				Please note that although the results of this questionnaire are
				calculated, they are just a starting point. You will be able to further
				refine your specs by consulting with a Skatescribe blade advisor.
			</div>
			<button className="button neutral submit" onClick={submitSpecs}>
				Get Recommended Specs
			</button>
		</Popup>
	)
}
