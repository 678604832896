import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import MainContainer from '../../../../Components/MainContainer/MainContainer'
import {
	addToCart,
	updateCart,
	updateDiscount,
} from '../../../../Redux/Slices/cartSlice'
import './Billing.css'
import BillingContactInfo from '../BillingSupport/BillingContactInfo'
// import BillingShippingInfo from '../BillingSupport/BillingShippingInfo'
import BillingSidebar from '../BillingSupport/BillingSidebar'
import { State } from '../../../../Redux/store'
import { verifyDiscountCode } from '../common/utils'

export default function Billing() {
	const cart = useSelector((s: State) => s.cart)
	const history = useHistory()
	const dispatch = useDispatch()

	const [discountCode, setDiscountCode] = useState<string>('')

	const [message, setMessage] = useState('')

	//if cart is empty return to cart page
	useEffect(() => {
		if (!cart.contents?.length) {
			history.replace('/Cart')
		} else {
			// Way to recalculate prices
			dispatch(
				addToCart({
					items: [],
					replaceCart: false,
				})
			)
		}
	}, [])

	function changeHandler(e: any) {
		dispatch(updateCart({ [e.target.name]: e.target.value }))
	}
	async function checkoutHandler() {
		const errors = []
		if (!cart.contactFirstName) {
			errors.push('First name is required')
		}
		if (!cart.contactLastName) {
			errors.push('Last name is required')
		}
		if (!cart.contactPhone) {
			errors.push('Phone is required')
		} else if (
			!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
				cart.contactPhone
			)
		) {
			errors.push('Phone number is invalid')
		}
		if (!cart.contactEmail) {
			errors.push('Email is required')
		} else if (
			!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				cart.contactEmail
			)
		) {
			errors.push('Email is invalid')
		}
		if (!cart.contactPostalCode) {
			errors.push('Postal code is required')
		} else if (
			!/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
				cart.contactPostalCode
			)
		) {
			errors.push('Postal code is invalid')
		}

		// Verify discount code again.
		if (discountCode) {
			const res = await verifyDiscountCode(discountCode, cart.contents)
			if (!res.isValid) {
				errors.push(res.error)
				dispatch(
					updateDiscount({
						discountData: null,
					})
				)
			}
		}

		if (!errors.length) {
			return history.push('/Checkout/Payment')
		}

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
		setMessage(errors.join('. '))
	}

	return (
		<div className="Billing">
			<MainContainer style={{ maxWidth: '1000px' }}>
				<h1 className="hemi title">Order Info</h1>
				<h2 className="title">
					Please review the billing information for your order
				</h2>
				{message && (
					<div
						style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}
					>
						{message}
					</div>
				)}
				<div className="billingBody flex">
					<div className="billingMain fullOnSmallScreens">
						<BillingContactInfo
							cart={cart}
							changeHandler={changeHandler}
							discountCode={discountCode}
							setDiscountCode={setDiscountCode}
							setMessage={setMessage}
						/>
					</div>

					<BillingSidebar cart={cart} checkoutHandler={checkoutHandler} />
				</div>
			</MainContainer>
		</div>
	)
}
