import {
	faCcAmex,
	faCcDiscover,
	faCcMastercard,
	faCcVisa,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React, { useMemo } from 'react'
import Card from '../../../@types/Card'

export default function SavedCard({
	card,
	handleDelete,
}: {
	card: Card
	handleDelete: { (): void }
}) {
	const ccIcon = useMemo(() => {
		switch (card.card_type) {
			case 'VI':
				return faCcVisa
			case 'MC':
				return faCcMastercard
			case 'NN':
				return faCcDiscover
			case 'AM':
				return faCcAmex
			default:
				return null
		}
	}, [card.card_type])

	return (
		<div key={card.card_id} className="SavedCardCard flexY ">
			<div className="CardDetails">
				{ccIcon && (
					<FontAwesomeIcon size="2x" icon={ccIcon} className="ccIcon" />
				)}
				ending in *{card.number.slice(-4)}
				<div className="DeleteCardButton" onClick={() => handleDelete()}>
					Delete Card
				</div>
			</div>
		</div>
	)
}
