import React, { useEffect, useRef, useState } from 'react'
import { data } from './data'
import SizeTable from '../SizeTable/SizeTable'
import Option from '../Option/Option'
import { useSelector } from 'react-redux'
import { State } from '../../../../Redux/store'
import './Option1.css'

interface Option1PropsI {
	setisO1OnMobile(value: boolean): void
}

enum MobileViews {
	default = 'default',
	CCM = 'CCM',
	Bauer = 'Bauer',
}

export default function Option1({ setisO1OnMobile }: Option1PropsI) {
	const topPageRef = useRef<null | HTMLDivElement>(null)
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const [currentMobileView, setCurrentMobileView] = useState(
		MobileViews.default
	)

	useEffect(() => {
		if (currentMobileView != MobileViews.default) {
			setisO1OnMobile(true)
		} else {
			setisO1OnMobile(false)
			topPageRef.current &&
				topPageRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [currentMobileView])

	const defaultMobileView = (
		<div className="O1MContainer" ref={topPageRef}>
			<div
				className="SizingChartItem"
				onClick={() => setCurrentMobileView(MobileViews.CCM)}
			>
				CCM Sizing Chart
			</div>
			<div
				className="SizingChartItem"
				onClick={() => setCurrentMobileView(MobileViews.Bauer)}
			>
				Bauer Sizing Chart
			</div>
		</div>
	)

	const ccmMobileView = (
		<div className="O1MContainer">
			<SizeTable {...data.charts[0]} />
			<SizeTable {...data.charts[1]} />
			<button
				className="button STButton"
				onClick={() => setCurrentMobileView(MobileViews.default)}
			>
				Back
			</button>
		</div>
	)

	const bauerMobileView = (
		<div className="O1MContainer">
			<SizeTable {...data.charts[2]} />
			<SizeTable {...data.charts[3]} />
			<button
				className="button STButton"
				onClick={() => setCurrentMobileView(MobileViews.default)}
			>
				Back
			</button>
		</div>
	)

	const mobileView = () => {
		switch (currentMobileView) {
			case MobileViews.default:
				return defaultMobileView
			case MobileViews.CCM:
				return ccmMobileView
			case MobileViews.Bauer:
				return bauerMobileView
		}
	}

	const renderer = () => {
		return screenSize == 'mobile' ? mobileView() : defaultView
	}

	const defaultView = (
		<>
			{data.charts.map((row, index) => (
				<SizeTable key={index} {...row} />
			))}
		</>
	)

	return (
		<Option
			optionNumber={data.optionNumber}
			title={
				currentMobileView == MobileViews.default
					? data.title
					: currentMobileView + ' Sizing Chart'
			}
		>
			{renderer()}
		</Option>
	)
}
