import './ServiceCard.css'
import SharpeningIcon from '../../../../Assets/graphics/sharpen-runners.svg'
import PurchaseBladeIcon from '../../../../Assets/graphics/purchase-placeholder.svg'

import React, { useMemo } from 'react'

interface ServiceCardProps {
	mobileView: boolean
	row: any
}

function ServiceCard({ mobileView = false, row }: ServiceCardProps) {
	const errorMessage = useMemo(() => {
		if (row.error?.type) {
			if (row.error.type == 'out_of_stock') {
				return 'Temporarily Out of Stock'
			}
			if (row.error.type == 'no_longer_available') {
				return 'No Longer Available'
			}
		}
		return ''
	}, [row.error])
	return (
		<div className={`ServiceCard ${mobileView ? 'mobile' : ''} `}>
			<img
				className="serviceIcon"
				src={row.type == 'Sharpening' ? SharpeningIcon : PurchaseBladeIcon}
				alt={row.type == 'Sharpening' ? 'sharpening' : 'purchase'}
			/>
			<div className="serviceLabelContainer">
				{row.type == 'Purchase' ? (
					<div className="serviceLabel">Blade Purchase</div>
				) : null}
				<div className="serviceLabel">{row.name}</div>
			</div>
			<div className="ErrorMessage">{errorMessage}</div>
		</div>
	)
}

export default ServiceCard
