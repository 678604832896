import React from 'react'
import './ReviewCard.css'
import { useMemo } from 'react'
import PresetIcon from '../../../../Components/PresetIcon/PresetIcon'
import {
	defaultImageUrlSelector,
	SpecType,
} from '../../../../Redux/Slices/optionsSlice'
import { useSelector } from 'react-redux'
import { formatCurrency, formatSpecType } from '../../../../Utils/formatUtils'
import { State } from '../../../../Redux/store'

interface ReviewCardProps {
	cartItem: any
	timesQuantity?: boolean
	id?: string
	showCode?: boolean
}

export default function ReviewCard({
	cartItem,
	timesQuantity,
	showCode,
}: ReviewCardProps) {
	const defaultImageUrl = useSelector(defaultImageUrlSelector)
	const presets = useSelector((s: State) => s.presets.list)

	const itemCostMemo = useMemo(() => {
		const itemCost = timesQuantity
			? cartItem.price * cartItem.quantity
			: cartItem.price
		return formatCurrency(itemCost)
	}, [cartItem.price, cartItem.quantity])

	return (
		<div className="ReviewCard">
			<div className={`imageContainer ${cartItem.type}`}>
				{cartItem.presetID ? (
					<img
						className="customPresetImg"
						src={
							process.env.REACT_APP_SERVER_URL! +
							(presets.find((p) => p.presetID == cartItem.presetID)?.imageID ||
								defaultImageUrl)
						}
					/>
				) : (
					<PresetIcon iconName={cartItem.specType} type={cartItem.type} />
				)}
			</div>
			<div className="itemInfo">
				<b>{cartItem.type == 'Purchase' ? 'New Blade Purchase' : null}</b>

				<span className="infoHeader">
					<b>
						{cartItem.playerName}{' '}
						{' ' + cartItem.name + ' x ' + cartItem.quantity}
					</b>
				</span>

				{showCode && (
					<div className="infoRow">
						Code: <span className="OrderCode">{cartItem.key}</span>
					</div>
				)}

				<div className="infoRow">
					Specs: {formatSpecType(cartItem.specType)}
				</div>

				{cartItem.specType === SpecType.Custom ? (
					<div className="specValue">
						Custom Notes: <b>{cartItem.advancedNotes}</b>
					</div>
				) : (
					<div className="customSpecsIndent">
						<div className="specValue">
							Hollow: <b>{cartItem.hollowValue}&quot;</b>
						</div>
						<div className="specValue">
							Radius: <b>{cartItem.radiusValue}&apos;</b>
						</div>
						<div className="specValue">
							Pitch: <b>P{cartItem.pitchValue}</b>
						</div>
					</div>
				)}

				<div className="infoRow">
					Price: <span className="price">{itemCostMemo}</span>
				</div>
			</div>
		</div>
	)
}
