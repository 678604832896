export default interface Notification {
	notificationID: number
	playerEmail: string
	title: string
	type: NotificationType
	body?: string
	createdDate: Date
	actionURL?: string
	readDate?: Date
}

export enum NotificationType {
	Caution = 'caution',
	Dropoff = 'dropoff',
	Checkmark = 'checkmark',
	Welcome = 'welcome',
	Complete = 'complete',
}

export enum NotificationOptionType {
	Method = 'notificationMethod',
	Preview = 'notificationPreviews',
}
