import { useDispatch, useSelector } from 'react-redux'
import { addToCart } from '../Redux/Slices/cartSlice'
import { State } from '../Redux/store'

export default function useAddSharpening() {
	const dispatch = useDispatch()
	const orders = useSelector((s: State) => s.orders.list)
	const { status } = useSelector((s: State) => s.activeUser)
	const defaultProductId = useSelector(
		(s: State) => s.products.defaultProductId
	)

	return function () {
		const cartItems = [
			{
				type: 'Sharpening',
				productID: defaultProductId,
				quantity: 1,
			},
		]
		dispatch(
			addToCart({
				items: cartItems,
				useLastConfig: orders.length > 0 && status === 'Logged In',
			})
		)
	}
}
