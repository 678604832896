import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'
import { Blade } from './../../../../commonTypes/blade'

export const fetchAllBlades = createAsyncThunk('blades/fetch', async () => {
	const bladesRes = await beam.get('/api/fetchAllBlades')
	return { blades: bladesRes.response.data }
})

interface BladesState {
	blades: Blade[]
	notSupportedBlades: Blade[]
	loading: boolean
}

const bladesInitialState: BladesState = {
	blades: [],
	notSupportedBlades: [],
	loading: false,
}

const bladesSlice = createSlice({
	name: 'blades',
	reducers: {},
	initialState: bladesInitialState,
	extraReducers: (builder) => {
		builder.addCase(fetchAllBlades.pending, (state: BladesState) => {
			state.loading = true
		})
		builder.addCase(fetchAllBlades.rejected, (state: BladesState) => {
			state.loading = false
		})
		// Add fulfilled case with payload type of BladeState.
		builder.addCase(
			fetchAllBlades.fulfilled,
			(state: BladesState, { payload }: PayloadAction<{ blades: Blade[] }>) => {
				state.blades = payload.blades
				state.notSupportedBlades = payload.blades.filter(
					(blade: Blade) => !blade.isSupported
				)
				state.loading = false
			}
		)
	},
})

export default bladesSlice.reducer
