import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BeamResponse } from '../../../../commonTypes/response'
import beam from '../../Handlers/beam'

export const fetchCards = createAsyncThunk('cards/fetch', async () => {
	const cardRes = await beam.get('/api/fetchCards')
	return { cards: cardRes.response.data }
})

export const deleteCard = createAsyncThunk(
	'cards/delete',
	async ({ cardID, callback }: { cardID: number; callback: { (): void } }) => {
		await beam.post('/api/deleteCard', { cardID })
		callback()
		return { cardID, callback }
	}
)

export const saveCard = createAsyncThunk(
	'cards/save',
	async ({
		tokenizedCC,
		callback,
	}: {
		tokenizedCC: any
		callback: { (res: BeamResponse): void }
	}) => {
		const { response: saveCardRes } = await beam
			.post('/api/saveCard', { tokenizedCC })
			.catch((e) => {
				callback(e.response)
				throw e
			})

		callback(saveCardRes)

		if (!saveCardRes.error) {
			const card = saveCardRes
			return card
		}
	}
)

const cardsSlice = createSlice({
	name: 'cards',
	reducers: {},
	initialState: {
		cards: [],
		loading: false,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCards.pending, (state: any) => {
			state.loading = true
		})
		builder.addCase(fetchCards.rejected, (state: any) => {
			state.loading = false
		})
		builder.addCase(fetchCards.fulfilled, (state: any, { payload }: any) => {
			state.cards = payload.cards
			state.loading = false
		})
		builder.addCase(deleteCard.fulfilled, (state: any, { payload }: any) => {
			const { cardID } = payload
			state.cards = [...state.cards].filter((card) => card.card_id != cardID)
		})
		builder.addCase(saveCard.fulfilled, (state: any, { payload }: any) => {
			state.cards = [...state.cards, payload]
		})
	},
})

export default cardsSlice.reducer
