import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../Redux/store'

interface LoggedInOnlyProps {
	children: React.ReactNode
}

export default function LoggedInOnly({ children }: LoggedInOnlyProps) {
	const { status } = useSelector((s: State) => s.activeUser)

	if (status !== 'Logged In') {
		return null
	}

	return <>{children}</>
}
