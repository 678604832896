import React from 'react'
import {
	Page,
	Text,
	View,
	Image,
	Document,
	StyleSheet,
	Font,
} from '@react-pdf/renderer'
import Order from '../../@types/Order'
import logoMobile from '../../Assets/photos/mobile-logo.png'
import hemiFont from '../../Assets/fonts/hemi_head_rg/Hemi_Head_Rg/hemi_head_bd_it.ttf'
import poppinsRegular from '../../Assets/fonts/poppins/Poppins-Regular.ttf'
import poppinsMedium from '../../Assets/fonts/poppins/Poppins-Medium.ttf'
import poppinsLight from '../../Assets/fonts/poppins/Poppins-Light.ttf'
import poppinsBold from '../../Assets/fonts/poppins/Poppins-Bold.ttf'
import poppinsThin from '../../Assets/fonts/poppins/Poppins-Bold.ttf'
import User from '../../@types/User'
import { formatSpecType } from '../../Utils/formatUtils'
import { countCartContents } from '../../Utils/generalUtils'

Font.register({
	family: 'hemi',
	format: 'truetype',
	src: hemiFont,
})

Font.register({
	family: 'Poppins',
	format: 'truetype',
	fontWeight: 200,
	src: poppinsRegular,
	fonts: [
		{
			src: poppinsMedium,
			fontWeight: '500',
		},
		{
			src: poppinsLight,
			fontWeight: '300',
		},
		{
			src: poppinsBold,
			fontWeight: '700',
		},
		{
			src: poppinsThin,
			fontWeight: '100',
		},
	],
})

// Create styles
const styles = StyleSheet.create({
	page: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff',
		padding: '30px',
		fontFamily: 'Poppins',
		fontSize: '12px',
		color: '#8a8f93',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
	},
	logo: {
		width: '40px',
		height: '50px',
	},
	titleWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		width: '100%',
		textAlign: 'center',
		flexGrow: 1,
		fontSize: '20px',
	},
	title: {
		fontFamily: 'hemi',
		color: 'black',
	},
	section: {
		marginTop: 10,
		marginBottom: 10,
		paddingTop: 10,
		paddingBottom: 10,
	},
	normal: {
		fontSize: '14px',
	},
	tenMTop: {
		marginTop: 10,
	},
	twoTenMBottom: {
		marginBottom: 20,
	},
	bold: {
		fontWeight: 300,
		color: '#606060',
	},
	borderTopBottom: {
		borderTop: '2px solid #C8C8C8',
		borderBottom: '2px solid #C8C8C8',
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 30,
	},
	itemDetails: {
		marginLeft: 20,
		fontWeight: 100,
		marginTop: 5,
	},
})

export default function OrderPrint({
	order,
	user,
}: {
	order: Order
	user: User
}) {
	const orderItemCount = countCartContents(order.contents)

	return (
		<Document title="Order Confirmation" author="Skatescribe">
			<Page size="A4" style={styles.page}>
				<View style={styles.header}>
					<Image src={logoMobile} style={styles.logo} />
					<View style={styles.titleWrapper}>
						<Text style={styles.title}>Skatescribe Order Confirmation</Text>
						<Text style={[styles.normal, styles.tenMTop, styles.bold]}>
							Order #{order.shortID} ({order.contents.length} items)
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.section,
						styles.borderTopBottom,
						styles.normal,
						styles.bold,
					]}
				>
					<Text> Name: {user.account.displayName} </Text>
					<Text style={styles.tenMTop}> Phone: {user.data.phone} </Text>
					<Text style={styles.tenMTop}> Email: {user.data.playerEmail} </Text>
				</View>
				<View style={[styles.section, styles.normal]}>
					<Text style={[styles.bold]}> Order Items ({orderItemCount}) </Text>
					{order.contents.map((item, index) => (
						<View key={index} style={styles.item}>
							<Text style={[styles.tenMTop, styles.bold]}>
								{formatSpecType(item.specType)} (x {item.quantity})
							</Text>
							{item.specType == 'Custom' ? (
								<Text style={styles.itemDetails}> {item.advancedNotes} </Text>
							) : (
								<>
									<Text style={styles.itemDetails}>
										Hollow:{' '}
										<Text style={[styles.bold]}>
											{item.hollowValue}
											{'"'}
										</Text>{' '}
									</Text>
									<Text style={styles.itemDetails}>
										Radius:{' '}
										<Text style={[styles.bold]}>
											{item.radiusValue}
											{"'"}
										</Text>{' '}
									</Text>
									<Text style={styles.itemDetails}>
										Hollow:{' '}
										<Text style={[styles.bold]}>P{item.pitchValue}</Text>{' '}
									</Text>
								</>
							)}
							<Text style={[styles.tenMTop, styles.twoTenMBottom]}>
								Order Code: <Text style={[styles.bold]}>{item.key}</Text>{' '}
							</Text>
						</View>
					))}
				</View>
			</Page>
		</Document>
	)
}
