import React, { useEffect, useState } from 'react'
import './PresetPopup.css'
import { SpecTab } from '../ChooseSpecsPopup/ChooseSpecsPopup'
import ViewPreset from './Support/Views/ViewPreset'
import AddPreset from './Support/Views/AddPreset'
import EditPreset from './Support/Views/EditPreset'
import PresetSpecs from './Support/Views/PresetSpecs'
import DeletePreset from './Support/Views/DeletePreset'
import DeleteConfirmation from './Support/Views/DeleteConfirmation'
import AddToCartConfirm from './Support/Views/AddToCartConfirm'
import {
	setCurrentPresetNull,
	UserPreset,
} from '../../Redux/Slices/presetsSlice'
import { useDispatch } from 'react-redux'

interface PresetPopupProps {
	visible: boolean
	setVisible(value: boolean): void
	currentView?: PresetPopupViews
	newPreset?: any
	setNewlyCreated?(value: boolean): void
	setActiveTab?(tab: SpecTab): void
}

export enum PresetPopupViews {
	view = 'view',
	edit = 'edit',
	add = 'add',
	newPresetSpecs = 'newPresetSpecs',
	confirmDelete = 'confirmDelete',
	deleteSuccess = 'deleteSuccess',
	addToCart = 'addToCart',
}

export default function PresetPopup({
	visible,
	setVisible,
	currentView,
	newPreset,
	setNewlyCreated,
	setActiveTab,
}: PresetPopupProps) {
	const [view, setView] = useState<PresetPopupViews>(
		currentView || PresetPopupViews.view
	)
	const [newPresetSpecs, setNewPresetSpecs] = useState<
		Partial<UserPreset> | undefined
	>()
	const dispatch = useDispatch()
	useEffect(() => {
		if (currentView) {
			setView(currentView)
		} else {
			setView(PresetPopupViews.view)
		}
	}, [visible])

	useEffect(() => {
		if (!visible && !newPreset) {
			dispatch(setCurrentPresetNull())
		}
	}, [visible])

	useEffect(() => {
		setNewPresetSpecs({
			hollowValue: newPreset?.hollowValue,
			pitchValue: newPreset?.pitchValue,
			radiusValue: newPreset?.radiusValue,
		})
	}, [newPreset])

	const renderCurrentView = () => {
		switch (view) {
			case PresetPopupViews.view: {
				return (
					<ViewPreset
						visible={visible}
						setVisible={setVisible}
						setView={setView}
					/>
				)
			}
			case PresetPopupViews.add: {
				return (
					<AddPreset
						visible={visible}
						setVisible={setVisible}
						setActiveTab={setActiveTab}
						setNewlyCreated={setNewlyCreated}
						newPresetSpecs={newPresetSpecs}
						setNewPresetSpecs={setNewPresetSpecs}
						setView={setView}
					/>
				)
			}
			case PresetPopupViews.edit: {
				return (
					<EditPreset
						visible={visible}
						setVisible={setVisible}
						setView={setView}
					/>
				)
			}
			case PresetPopupViews.newPresetSpecs: {
				return (
					<PresetSpecs
						visible={visible}
						setVisible={setVisible}
						setView={setView}
					/>
				)
			}
			case PresetPopupViews.confirmDelete: {
				return (
					<DeletePreset
						visible={visible}
						setVisible={setVisible}
						setView={setView}
					/>
				)
			}
			case PresetPopupViews.deleteSuccess: {
				return <DeleteConfirmation visible={visible} setVisible={setVisible} />
			}
			case PresetPopupViews.addToCart: {
				return (
					<AddToCartConfirm
						visible={visible}
						setVisible={setVisible}
						setView={setView}
					/>
				)
			}
		}
	}
	return <div>{renderCurrentView()}</div>
}
