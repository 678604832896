import debounce from 'lodash.debounce'

export const countCartContents = (contents: any): number => {
	return contents.reduce((a: any, b: any) => {
		return a + b.quantity
	}, 0)
}

export const LOCAL_STORAGE = {
	BLADE_WARNING_POPUP: 'showedBladeWarningPopup',
}

export const debouncedBambora = debounce((func: { (): void }) => {
	func()
}, 500)

export const getFraction = (fracStr: string): number => {
	const [num, den] = fracStr.split('/')
	return parseInt(num) / parseInt(den) || parseInt(fracStr)
}
