import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './Checkbox.css'

interface CheckboxProps {
	checked: boolean
	setChecked(value: boolean): void
}

export default function Checkbox({ checked, setChecked }: CheckboxProps) {
	return (
		<label className="Checkbox">
			<input
				className="checkbox"
				type="checkbox"
				checked={checked}
				onChange={() => setChecked(!checked)}
			/>
			<span>
				{checked && <FontAwesomeIcon className="checkIcon" icon={faCheck} />}
			</span>
		</label>
	)
}
