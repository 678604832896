export function formatCurrency(amount: number) {
	const num = Math.round(amount) / 100
	if (isNaN(num)) {
		return ' - '
	}

	let displayText = `$${num}`

	const cents = displayText.split('.')[1]

	if (cents && cents.length < 2) {
		displayText = displayText + '0'
	} else if (!cents) {
		displayText = displayText + '.00'
	}

	return displayText
}

export function formatSpecType(specType: string): string {
	if (!specType) return ''
	if (specType === 'custom' || specType === 'Custom') {
		return 'Custom'
	}
	if (specType === 'advanced' || specType === 'Advanced') {
		return 'Advanced'
	}
	return (
		specType.slice(0, 1).toUpperCase() +
		specType.slice(1).toLowerCase() +
		' Preset'
	)
}
