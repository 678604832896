import React from 'react'
import './TwoImgs.css'

interface ImgStructure {
	img: string
	altImg: string
	title?: string
}

interface TwoImgsPropsI {
	data: ImgStructure[]
}

export default function TwoImgs({ data }: TwoImgsPropsI) {
	return (
		<div className="twoImgsContainer">
			{data.map((item, index) => {
				return (
					<div className="twoImgsItem" key={index}>
						{item.title && (
							<div className="hemi twoImgsItemTitle">{item.title}</div>
						)}
						<img src={item.img} alt={item.altImg} className="twoImgsItemImg" />
					</div>
				)
			})}
		</div>
	)
}
