import React from 'react'
import { InfoTab } from 'react-ui-scaffold'
import './SizeTab.css'

interface SizeTabProps {
	tabID: string
	value: string
	options?: string[]
	setResponse: (response: string) => void
	error: string
}

export default function SizeTab({
	tabID,
	value,
	options = [],
	setResponse,
	error,
}: SizeTabProps) {
	const dontKnowSizeClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()
		window.open('/FindBladeSize', '_blank')?.focus()
	}

	return (
		<InfoTab className="QuestionTab SizeTab" tabID={tabID}>
			<div className="Question">
				Select your&nbsp;<b>blade size</b>.
			</div>
			<div className="QuestionError">{error}</div>
			<div className="SizesContainer">
				{options.map((option) => {
					const selected = value == option
					return (
						<div
							key={option}
							className={`${selected ? 'Active' : ''} SizeContainer hemi title`}
							onClick={() => setResponse(option)}
						>
							<div className="Size">
								<input
									type="radio"
									className="input"
									checked={selected}
									style={{ marginRight: 10 }}
									onChange={() => setResponse(option)}
								/>
								{option}
							</div>
						</div>
					)
				})}
			</div>
			<a href="" className="BSPageLink" onClick={dontKnowSizeClick}>
				I don&apos;t know my blade size
			</a>
		</InfoTab>
	)
}
