import React from 'react'
import { useDispatch } from 'react-redux'
import Counter from '../../../../Components/Counter/Counter'
import { updateCartItem } from '../../../../Redux/Slices/cartSlice'

interface QuantityCellProps {
	cell: any
	row: any
}

export default function QuantityCell({ cell, row }: QuantityCellProps) {
	const dispatch = useDispatch()

	function setValue(n: any) {
		dispatch(
			updateCartItem({
				id: row.id,
				updates: { quantity: n },
			})
		)
	}

	return (
		<div className="QuantityCell">
			<Counter value={cell} setValue={setValue} min={1} max={10} />
		</div>
	)
}
