import React from 'react'
import './PitchOptions.css'
import { useDispatch, useSelector } from 'react-redux'
import pitchSVG from '../../../../Assets/graphics/parameter-pitch.svg'
import Slider from '../../../../Components/Slider/Slider'
import { CartItem, updateCartItem } from '../../../../Redux/Slices/cartSlice'
import { pitchSelector, SpecType } from '../../../../Redux/Slices/optionsSlice'
import useValidateCartItem from '../../../../Hooks/useValidateCartItem'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

interface PitchOptionsProps {
	cartItem: CartItem
}

export default function PitchOptions({ cartItem }: PitchOptionsProps) {
	const pitch = useSelector(pitchSelector)

	const dispatch = useDispatch()

	const tooltipText =
		'Using a positive pitch will move your<br/>main balance point closer to your toes,<br/>while using a negative pitch will move your<br/>main balance point closer to your heel.'

	const validateCartItem = useValidateCartItem(cartItem.id)

	function updateHandler(newValue: any) {
		if (newValue.toString().replace('.', '').length > 4) {
			return
		}

		dispatch(
			updateCartItem({
				id: cartItem.id,
				updates: {
					specType: SpecType.Advanced,
					pitchValue: newValue,
					presetID: null,
				},
			})
		)
	}

	return (
		<div className="specItem PitchOptions">
			<div className="topContainer">
				<div className="inputContainer">
					<span
						data-for="title"
						data-tip={tooltipText}
						data-iscapture="true"
						className="titleIcon"
					>
						Pitch:
						<FontAwesomeIcon className="questionIcon" icon={faQuestionCircle} />
					</span>
					<ReactTooltip
						id="title"
						effect="solid"
						place="bottom"
						multiline={true}
					/>
					<input
						className="input"
						type="number"
						min={pitch.range[0]}
						max={pitch.range[1]}
						value={cartItem.pitchValue}
						onChange={(e) => updateHandler(e.target.value)}
						step={0.5}
						onBlur={validateCartItem}
					/>
				</div>

				<div
					className="graphicContainer"
					data-for="graphic"
					data-tip={tooltipText}
					data-iscapture="true"
				>
					<span className="value">P{cartItem.pitchValue}</span>
					<img src={pitchSVG} />
				</div>
				<ReactTooltip
					id="graphic"
					place="right"
					effect="solid"
					multiline={true}
				/>
			</div>

			<div className="sliderContainer">
				<Slider
					step={0.5}
					leftTip={'Backwards'}
					rightTip={'Forwards'}
					max={pitch.range[1]}
					min={pitch.range[0]}
					value={cartItem.pitchValue}
					onChange={updateHandler}
					minLabel={pitch.range[0]}
					maxLabel={pitch.range[1]}
				/>
			</div>
		</div>
	)
}
