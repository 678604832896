import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Order, { OrderSortType, OrderStatus } from '../../@types/Order'
import Conditional from '../../Components/Conditional/Conditional'
import MainContainer from '../../Components/MainContainer/MainContainer'
import { updateUserSettingsData } from '../../Redux/Slices/activeUserSlice'
import { fetchOrderByPage } from '../../Redux/Slices/ordersSlice'
import { State } from '../../Redux/store'
import './MyOrders.css'
import OrderList from './OrderList/OrderList'

export default function MyOrders() {
	const dispatch = useDispatch()
	const orderSortType = useSelector(
		(s: State) => s.activeUser.data.orderSortType
	)
	const screenSize = useSelector((s: State) => s.view.screenSize)
	const orders = useSelector((s: State) => s.orders.list) as Order[]
	const moreOrders = useSelector((s: State) => s.orders.moreOrders)
	const isLoading = useSelector((s: State) => s.orders.loading)
	const [ordersPage, setOrdersPage] = useState<number>(1)

	const statusSequences = Object.values(OrderStatus)
	const sortMethods = {
		[OrderSortType.DateFirst]: (a: Order, b: Order) =>
			a?.placedDate > b?.placedDate ? 1 : -1,
		[OrderSortType.DateLast]: (a: Order, b: Order) =>
			a?.placedDate > b?.placedDate ? -1 : 1,
		[OrderSortType.PriceLow]: (a: Order, b: Order) =>
			a?.finalTotal > b?.finalTotal ? 1 : -1,
		[OrderSortType.PriceHigh]: (a: Order, b: Order) =>
			a?.finalTotal > b?.finalTotal ? -1 : 1,
		[OrderSortType.OrderStatus]: (a: Order, b: Order) =>
			statusSequences.indexOf(a?.status) - statusSequences.indexOf(b?.status),
	}
	const sortedOrders = [...orders].sort(
		sortMethods[orderSortType as OrderSortType]
	)

	const handleLoadMoreBtn = () => {
		dispatch(fetchOrderByPage(ordersPage + 1))
		setOrdersPage((prev) => prev + 1)
	}

	return (
		<MainContainer>
			<div className="MyOrders">
				<h1 className="hemi title">My Orders</h1>
				<div className="subheader">
					<span className="hemi orderCount">
						{`${orders.length} Order${orders.length !== 1 ? 's' : ''}`}
					</span>
					<span>
						{screenSize !== 'mobile' && 'Sort by: '}
						<select
							className="input sortOptions"
							value={orderSortType}
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
								dispatch(
									updateUserSettingsData({
										orderSortType: e.target.value,
									})
								)
							}}
						>
							{Object.values(OrderSortType).map((sortType, idx) => (
								<option key={idx} value={sortType}>
									{sortType}
								</option>
							))}
						</select>
					</span>
				</div>
				<div className="orderListContainer">
					<OrderList orders={sortedOrders} />
					<Conditional condition={moreOrders}>
						{
							<button
								className="button hollow btnLoad"
								onClick={handleLoadMoreBtn}
							>
								{isLoading ? 'Loading...' : 'Load More'}
							</button>
						}
					</Conditional>
				</div>
			</div>
		</MainContainer>
	)
}
