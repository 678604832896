import React from 'react'
import './UpcomingOrdersWidget.css'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import Conditional from '../../../../Components/Conditional/Conditional'
import { openOrdersSelector } from '../../../../Redux/Slices/ordersSlice'
import OrderCards from '../../../MyOrders/OrderCards/OrderCards'
import { State } from '../../../../Redux/store'

export default function UpcomingOrdersWidget() {
	const openOrders = useSelector(openOrdersSelector)
	const ordersLoading = useSelector((s: State) => s.orders.loading)
	const history = useHistory()

	return (
		<div className="UpcomingOrders widget large">
			<div className="titleBar">
				<span className="hemi">Upcoming Orders</span>

				<button
					className="button neutral plain viewAll"
					onClick={() => history.push('/MyOrders')}
				>
					View All
				</button>
			</div>

			<Conditional
				condition={openOrders.length}
				fallback={
					<div className="shopPromptContainer">
						<span className="emptyNote">
							{ordersLoading ? 'Loading...' : 'No Upcoming Orders'}
						</span>
						<Link to="/Shop">
							<button className="button shopButton">Shop</button>
						</Link>
					</div>
				}
			>
				<OrderCards orders={openOrders.slice(0, 3)} />
			</Conditional>
		</div>
	)
}
