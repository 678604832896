import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import './Checkout.css'
import Billing from './Support/Billing/Billing'
import ReviewOrder from './Support/ReviewOrder/ReviewOrder'
import PaymentInfo from './Support/PaymentInfo/PaymentInfo'

import { useSelector } from 'react-redux'
import Footer from '../../Components/Footer/Footer'
import { State } from '../../Redux/store'
import ChooseLocation from '../ChooseLocation/ChooseLocation'

export default function Checkout() {
	const cart = useSelector((s: State) => s.cart)
	const history = useHistory()

	//if cart is empty return to cart page
	useEffect(() => {
		if (!cart.contents?.length) history.replace('/Cart')
	}, [])

	return (
		<div className="Checkout">
			<Switch>
				<Route path="/Checkout/ChooseLocation" component={ChooseLocation} />
				<Route path="/Checkout/Review" component={ReviewOrder} />
				<Route path="/Checkout/Billing" component={Billing} />
				<Route path="/Checkout/Details" component={ReviewOrder} />
				<Route path="/Checkout/Payment" component={PaymentInfo} />
				<Route
					path="/Checkout"
					component={() => <Redirect to="/Checkout/ChooseLocation" />}
				/>
			</Switch>

			<Footer />
		</div>
	)
}
