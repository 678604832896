import { useDispatch, useSelector } from 'react-redux'
import { OrderItem } from '../@types/Order'
import { updateCartItem } from '../Redux/Slices/cartSlice'
import { pitchSelector, radiusSelector } from '../Redux/Slices/optionsSlice'
import { State } from '../Redux/store'

export default function useValidateCartItem(id: string) {
	const dispatch = useDispatch()
	const foundItem = useSelector((s: State) => s.cart.contents).find(
		(item: OrderItem) => item.id === id
	) as unknown
	const radiusRange = useSelector(radiusSelector).range
	const pitchRange = useSelector(pitchSelector).range

	const checkValue = (value: number, [min, max]: number[]) => {
		if (value < min || isNaN(value) || !value) return min
		if (value > max) return max
		if (value % 1 !== 0) return parseFloat(value as any).toFixed(2)
		return value
	}

	return function () {
		if (foundItem !== undefined) {
			const cartItem = foundItem as OrderItem
			const { radiusValue, pitchValue } = cartItem

			dispatch(
				updateCartItem({
					id,
					updates: {
						radiusValue: Number(checkValue(radiusValue, radiusRange)),
						pitchValue: Number(checkValue(pitchValue, pitchRange)),
					},
				})
			)
		}
	}
}
