import React from 'react'
import { CartItem } from '../../../../Redux/Slices/cartSlice'
import { UserPreset } from '../../../../Redux/Slices/presetsSlice'
import TabPresetCard from './TabPresetCard'
import './TabPresetsList.css'

interface TabPresetsListProps {
	presets: UserPreset[]
	selectedPreset?: number
	setSelectedPreset?: (preset: number) => void
	cartItem: CartItem
}

export default function TabPresetsList({
	presets,
	selectedPreset,
	setSelectedPreset,
	cartItem,
}: TabPresetsListProps) {
	return (
		<div className="PresetsListContainer">
			{presets.map((preset) => (
				<TabPresetCard
					preset={preset}
					selectedPreset={selectedPreset}
					key={preset.presetID}
					setSelectedPreset={setSelectedPreset}
					cartItem={cartItem}
				/>
			))}
		</div>
	)
}
