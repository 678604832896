import React, { useEffect, useRef, useState } from 'react'
import './Banner.css'

import banner1 from '../../../../Assets/photos/bg-banner.png'

import LoggedOutOnly from '../../../../Components/LoggedOutOnly/LoggedOutOnly'
import SignInPopup from '../../../../Popups/SignInPopup/SignInPopup'
import { useSelector } from 'react-redux'
import { State } from '../../../../Redux/store'
import { Link } from 'react-router-dom'

export default function Banner() {
	const [showSignIn, setShowSignIn] = useState(false)
	const [sliderPosition, setSliderPosition] = useState(0)
	const sliderImages = [banner1]
	const { status } = useSelector((s: State) => s.activeUser)

	const timerRef = useRef<any>(null)
	const resetTimer = () => timerRef.current && clearTimeout(timerRef.current)

	useEffect(() => {
		resetTimer()
		timerRef.current = setTimeout(
			() => setSliderPosition((sliderPosition + 1) % sliderImages.length),
			2000
		)
		return resetTimer
	}, [sliderPosition])

	return (
		<>
			<SignInPopup visible={showSignIn} setVisible={setShowSignIn} />

			<div
				className={`Banner ${status === 'Logged In' && 'loggedIn'}`}
				style={{ backgroundImage: `url(${sliderImages[sliderPosition]})` }}
			>
				<div className="contentContainer flexCenter">
					<div className="titleContainer">
						<div className="title hemi">
							Introducing the Next Revolution in Hockey Technology
						</div>

						<div className="subtitle">
							<i>Integrated Sharpening and Profiling</i>
						</div>
					</div>

					<div className="controls">
						<LoggedOutOnly>
							<button className="button" onClick={() => setShowSignIn(true)}>
								Log In or Sign Up
							</button>
						</LoggedOutOnly>

						<Link to="/Shop">
							<button className="button hollow ghost">Shop Now</button>
						</Link>
					</div>
				</div>
				<div className="sliderContainer">
					{sliderImages.length > 1 &&
						sliderImages.map((_img, idx) => (
							<span
								key={idx}
								className={`sliderDot ${idx === sliderPosition && 'activeDot'}`}
								onClick={() => setSliderPosition(idx)}
							></span>
						))}
				</div>
			</div>
		</>
	)
}
