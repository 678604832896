import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Popup from '../../../Components/Popup/Popup'
import { updateUserData } from '../../../Redux/Slices/activeUserSlice'
import { FreeButton } from 'react-ui-scaffold'
import './AccountSettingPopup.css'

interface AccountSettingPopupProps {
	visible: boolean
	setVisible?: any
	field?: any
	user: any
	title: string
}

export default function AccountSettingPopup({
	visible,
	setVisible,
	field,
	user,
	title,
}: AccountSettingPopupProps) {
	const [text, setText] = useState(user.data[field])
	const [message, setMessage] = useState('')

	useEffect(() => {
		setText(user.data[field])
	}, [user.data[field]])

	useEffect(() => {
		setMessage('')
	}, [visible])

	const dispatch = useDispatch()

	function changeHandler(e: any) {
		setText(e.target.value)
	}

	async function submitHandler() {
		dispatch(
			updateUserData({
				updates: {
					[field]: text,
				},
				callback: (error?: string) => {
					if (error) {
						return setMessage(error)
					}
					setVisible(false)
				},
			})
		)
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={title}
			className={'AccountSettingPopup'}
		>
			<div
				className="story"
				style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}
			>
				{message}
			</div>
			<input
				type="text"
				className="input wide"
				value={text}
				onChange={changeHandler}
			/>
			<div className="flexCenter controls">
				<FreeButton className="button" onClick={submitHandler}>
					Save
				</FreeButton>
			</div>
		</Popup>
	)
}
