import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router'
import MainContainer from '../../../../Components/MainContainer/MainContainer'
import Popup from '../../../../Components/Popup/Popup'
import beam from '../../../../Handlers/beam'
import { clearCart, updateDiscount } from '../../../../Redux/Slices/cartSlice'
import { FreeButton } from 'react-ui-scaffold'
import CollectPaymentInfo from '../PaymentInfoSupport/CollectPaymentInfo'
import debounce from 'lodash.debounce'
import './PaymentInfo.css'
import { State } from '../../../../Redux/store'
import { fetchOrderByPage } from '../../../../Redux/Slices/ordersSlice'
import { fetchUserPresets } from '../../../../Redux/Slices/presetsSlice'
import { fetchCards } from '../../../../Redux/Slices/cardsSlice'

interface ProcessPaymentArgs {
	tokenizedCC?: string
	saveCC?: Boolean
	cardID?: string
}
export default function Billing() {
	const cart = useSelector((s: State) => s.cart)
	const user = useSelector((s: State) => s.activeUser)
	const cards = useSelector((s: State) => s.cards.cards)
	const loggedIn = user.status === 'Logged In'
	const history = useHistory()
	const dispatch = useDispatch()
	const [message, setMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [showCardExists, setShowCardExists] = useState(false)

	//if cart is empty return to cart page
	if (!cart.contents?.length) {
		return <Redirect to="/Cart" />
	}

	const processPayment = async (
		args: ProcessPaymentArgs,
		callback: { (): void }
	) => {
		try {
			if (isLoading || cart.loading) {
				return
			}

			const isPresetinCart = cart.contents.some(
				(item: any) => item.presetID != null
			)

			// Did that because ts was giving error.
			const discountData = cart.discountData as any

			setIsLoading(true)
			await beam
				.post('/api/checkout', {
					cart,
					discountID: discountData ? discountData.discountID : null,
					...args,
				})
				.then(({ response }: any) => {
					history.push(`/Orders/${response.data.longID}`)
					dispatch(fetchOrderByPage())
					dispatch(clearCart())
					if (isPresetinCart) {
						dispatch(fetchUserPresets())
					}
					if (args.saveCC) {
						dispatch(fetchCards())
					}
				})
			localStorage.setItem('showAccountPrompt', 'true')
			dispatch(updateDiscount({ discountData: null }))
		} catch (err: any) {
			if (err.response?.code == 'card_already_added') {
				setShowCardExists(true)
			} else if (err.response?.code == 'try_again') {
				setMessage('Please try again')
			} else if (err.response?.code == 'maximum_cards_reached') {
				setMessage('Maximum number of cards reached')
			} else if (err.response?.code == 'card_validation_failed') {
				setMessage('Card validation failed')
			} else {
				setMessage(err.response?.error)
			}
		}
		callback()
		setIsLoading(false)
	}

	const debouncedProcessPayment = useMemo(() => {
		return debounce(
			(args: ProcessPaymentArgs, callback: { (): void }) =>
				processPayment(args, callback),
			550
		)
	}, [])

	return (
		<div className="PaymentInfo">
			<Popup
				visible={showCardExists}
				setVisible={setShowCardExists}
				title="You've already saved this card"
				className="ExistingCardPopup"
			>
				<div className="line"></div>

				<div className="story" style={{ textAlign: 'center' }}>
					{
						"Since you've already saved this card, you cannot save it again. Please Select the card and try again."
					}
				</div>
				<div className="BackButtonWrapper">
					<FreeButton
						className="button"
						onClick={() => setShowCardExists(false)}
					>
						Back
					</FreeButton>
				</div>
			</Popup>
			<MainContainer style={{ maxWidth: '1000px' }}>
				<h1 className="hemi title">Payment Info</h1>
				{message && (
					<div
						style={{ color: 'var(--negativeAccentColor)', textAlign: 'center' }}
					>
						{message}
					</div>
				)}

				<CollectPaymentInfo
					processPayment={debouncedProcessPayment}
					loggedIn={loggedIn}
					cards={cards}
					setMessage={setMessage}
				/>
			</MainContainer>
		</div>
	)
}
