import React from 'react'
import './../MyPresets.css'
import {
	updateCurrentPreset,
	updatePresetImage,
	UserPreset,
} from '../../../Redux/Slices/presetsSlice'
import { useDispatch } from 'react-redux'
import useAddPresetToCart from '../../../Hooks/useAddPresetToCart'
import ProfileImageSelector from '../../../Components/ProfileImageSelector/ProfileImageSelector'

export default function MyPresetItem({
	preset,
	setShowIndPopup,
}: {
	preset: UserPreset
	setShowIndPopup(value: boolean): void
}) {
	const addPresetToCart = useAddPresetToCart()
	const dispatch = useDispatch()

	return (
		<div className="MyPresetsItem">
			<ProfileImageSelector
				image={preset.imageID}
				selectHandler={(img: any) => {
					dispatch(
						updatePresetImage({
							presetID: preset.presetID!,
							imageID: img.profileImageId,
							imagePath: img.uri,
						})
					)
				}}
			/>
			<div className="MyPresetInfo">
				<div className="MyPresetsItemContent">
					<div
						className="MypresetName Pointer"
						onClick={() => {
							dispatch(updateCurrentPreset(preset))
							setShowIndPopup(true)
						}}
					>
						{preset.presetName}
					</div>
					<div className="PresetDetails">
						<div className="PresetValue">
							Hollow:{' '}
							<span>
								{preset.hollowValue}
								{'"'}
							</span>
						</div>
						<div className="PresetValue">
							Radius:{' '}
							<span>
								{preset.radiusValue}
								{"'"}
							</span>
						</div>
						<div className="PresetValue">
							Pitch: <span>P{preset.pitchValue}</span>
						</div>
					</div>
				</div>

				<div className="MyPresetAddToCart">
					<button
						className="button neutral plain"
						onClick={() => addPresetToCart(preset)}
					>
						Add to Cart
					</button>
				</div>
			</div>
		</div>
	)
}
